import React, { useEffect, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import isObject from "lodash/isObject";
import Navigation from "../../common/classes/Navigation";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { ROLE_TYPE, STANDARD_DATE_FORMAT } from "../../common/utilities/const";
import {
    TOAST_TYPE,
    assignDefaultToBase,
    createToast,
    isObjectEqualWithBase,
    sanitizeDateWithLocalDateToOtherTimezoneFromObject,
    sanitizeDatesWithProperTimeConversionFromObject
} from "../../common/utilities/helper";
import { useLoadGlobalMutation, useLogoutMutation, useRefreshMutation } from "./api";
import {
    reset,
    selectAuth,
    selectBanks,
    selectCountries,
    selectCountry,
    selectIndustries,
    selectRole,
    selectUser,
    selectUserSetting,
    setGlobal,
    setUser
} from "./slice";
import { LOCAL_STORAGE_KEYS } from "../company/companyPayment/const";
import useLocalStorage from "../../common/hooks/useLocalStorage";
import { createMissingRequiredFormMessage } from "../company/companyForms/helper";

const { SUPER_ADMIN, COMPANY } = ROLE_TYPE;

export const AUTH_RENDER_TYPE = {
    DEFAULT: 0x0,
    UNVERIFIED_EMAIL: 0x1,
    WAITING_APPROVAL: 0x2,
    DEACTIVATED_ACCOUNT: 0x3,
    EXPIRED_SUBSCRIPTION: 0x4,
    SETTING_SETUP: 0x5
};

const { DEFAULT, UNVERIFIED_EMAIL, WAITING_APPROVAL, DEACTIVATED_ACCOUNT, EXPIRED_SUBSCRIPTION, SETTING_SETUP } = AUTH_RENDER_TYPE;

const checkIfUpgrading = (upgradePaths, path) => {
    let isUpgrading = upgradePaths.includes(path);
    if (!isUpgrading) {
        let paths = path.split("/");
        paths.pop(); // remove last path which is a param;
        paths = paths.join("/") + "/:token";
        isUpgrading = upgradePaths.includes(paths);
    }
    return isUpgrading;
};

const createSidebarRoutes = (dataroutes = [], { compactSidebar, limitReached, missingRequiredFormClasses } = {}) => {
    let arr = dataroutes;

    const createRender = (item, style) => {
        const image = (item.image && item.image) || null;
        const label = <span className="text-ellipsis">{item.label}</span>;
        return (
            <div className="tk-auth-layout__sidebar-item overflow-hidden" style={style || {}}>
                {!compactSidebar ? (
                    <>
                        <div style={{ maxWidth: "23px", width: "23px", minWidth: "23px" }}>{image}</div>
                        {label}
                    </>
                ) : (
                    image
                )}
            </div>
        );
    };

    const createItem = (item, id) => {
        const isBulkAdd = item.path === Navigation.Routes.EMPLOYEES.subpaths.BULK_UPLOAD.path;
        const isEmployeePath = item.path === Navigation.Routes.EMPLOYEES.subpaths.LIST.path;
        const isCompanyFormPath = item.path === Navigation.Routes.COMPANY_FORM.path;
        const temp = {
            id,
            isActive: location.pathname === item.path,
            path: item.path,
            label: item.label,
            render: createRender(item, {}, id)
        };
        if (isBulkAdd && limitReached) {
            temp.isDisabled = true;
            temp.warningMessage = "Limit Reached!";
        }
        if (isEmployeePath && limitReached) {
            temp.warningMessage = "Limit Reached!";
        }
        if (isCompanyFormPath && missingRequiredFormClasses?.length) {
            temp.warningMessage = createMissingRequiredFormMessage(missingRequiredFormClasses);
            temp.color = "orange";
        }
        return temp;
    };

    const sidebarItems = arr
        .filter((item) => !item.notSupported)
        .map((item, idx) => {
            if (item.isGroup && item.subpaths && item.subpaths.length)
                return {
                    id: item.id,
                    render: createRender(item, { padding: ".5rem 1rem" }, item.id),
                    label: item.label,
                    subItems: item.subpaths.filter((item) => !item.notSupported).map((subpath, sidx) => createItem(subpath, `${item.id}${sidx}`))
                };
            return createItem(item, item.id || idx);
        });

    sidebarItems.sort((a, b) => a.id - b.id);

    return sidebarItems;
};

export const useAuthSwitcher = ({ compactSidebar } = {}) => {
    const [isMounted, setMounted] = useState(false);
    const [showPromotion, setShowPromotion] = useState(false);

    const params = useParams();
    const location = useLocation();

    const auth = useAppSelector(selectAuth);
    const user = useAppSelector(selectUser);
    const setting = useAppSelector(selectUserSetting);
    const role = useAppSelector(selectRole);

    const [storage, setStorage] = useLocalStorage(LOCAL_STORAGE_KEYS.DAILY_PROMOTION.key, LOCAL_STORAGE_KEYS.DAILY_PROMOTION.default);

    const hasUser = !!user;
    const token = params.token;
    const currentPath = location.pathname;
    const prevPath = location.state?.from?.pathname;
    const routes = Navigation.Routes;

    const isSuperAdmin = role?.type === SUPER_ADMIN;
    const isCompanyAdmin = role?.type === COMPANY;

    const status = user?.status;
    const isPendingPayment = status?.isPendingPayment;
    const isUnverifiedEmail = status?.isUnverifiedEmail;
    const isWaitingForApproval = status?.isWaitingForApproval;
    const isDeactivatedAccount = status?.isDeactivatedAccount;

    const isTrial = user?.is_trial;
    const isExpired = user?.validity?.subscription.isExpired;
    const isSettingsVerified = user?.isSettingsVerified;

    const settingPath = routes.SETTINGS_SETUP.path;
    const dashboardPath = routes.DASHBOARD.path;
    const pendingPaymentPath = routes.PENDING_PAYMENT.path;
    const upgradingPaths = Navigation.CompanySubscriptionUpgradeRoutes.map((r) => r.path);

    const isDashboardRoute = dashboardPath == currentPath;
    const isSettingSetupRoute = settingPath === currentPath;
    const isPendingPaymentRoute = pendingPaymentPath === currentPath;
    const isUpgradingRoute = checkIfUpgrading(upgradingPaths, currentPath);
    const isPrevpathUpgradingRoute = prevPath && checkIfUpgrading(upgradingPaths, prevPath);
    const isPrevPathPendingPaymentRoute = prevPath && pendingPaymentPath == prevPath;
    const isPrevPathDashboardRoute = prevPath && dashboardPath == prevPath;

    const remainingSubscriptionTime = user?.validity?.subscription?.remainingTime;
    const isCloseToExpiring = remainingSubscriptionTime && !remainingSubscriptionTime.day;

    const toCheckRoutes = [
        routes.UNVERIFIED_EMAIL.path,
        routes.WAITING_APPROVAL.path,
        routes.DEACTIVATED_ACCOUNT.path,
        routes.EXPIRED_SUBSCRIPTION.path,
        routes.SETTINGS_SETUP.path
    ];

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isCompanyAdmin && setting?.timezone && isTrial) {
            const today = moment().tz(setting.timezone).format("MM DD YYYY");
            if (storage.date != today) {
                setStorage({ date: today });
                setShowPromotion(true);
            }
        }
    }, [isMounted]);

    const getCompanySubscription = () => {
        if (isCompanyAdmin && user && user?.CompanySubscription) {
            const companySubscription = user?.CompanySubscription?.Subscription;
            const employeeLimit = companySubscription?.employee_limit || 0;
            const limitReached = user?.totalEmployees >= employeeLimit;
            return {
                employeeLimit,
                limitReached
            };
        }
        return {
            employeeLimit: null,
            limitReached: false
        };
    };

    const getRenderType = () => {
        const isSettingsSetup = !isSettingsVerified;
        const proceedToCompanyChecking = isCompanyAdmin && !isUpgradingRoute && !isPendingPaymentRoute;

        if (proceedToCompanyChecking) {
            if (isUnverifiedEmail) {
                return UNVERIFIED_EMAIL;
            } else if (isDeactivatedAccount) {
                return DEACTIVATED_ACCOUNT;
            } else if (isWaitingForApproval) {
                return WAITING_APPROVAL;
            } else if (isExpired) {
                return EXPIRED_SUBSCRIPTION;
            } else if (isSettingsSetup) {
                return SETTING_SETUP;
            } else {
                return DEFAULT;
            }
        } else {
            return DEFAULT;
        }
    };

    const getSidebarData = () => {
        const companySidebarRoutes = Navigation.SidebarCompanyAdminRoutes;
        const adminSidebarRoutes = Navigation.SidebarAdminRoutes;
        const routes = isCompanyAdmin ? companySidebarRoutes : adminSidebarRoutes;

        const top = createSidebarRoutes(
            routes.filter((r) => !r.isBottom),
            { compactSidebar, limitReached: getCompanySubscription().limitReached, missingRequiredFormClasses: user?.missingRequiredFormClass || [] }
        );
        const bottom = createSidebarRoutes(
            routes.filter((r) => r.isBottom),
            { compactSidebar, limitReached: getCompanySubscription().limitReached, missingRequiredFormClasses: user?.missingRequiredFormClass || [] }
        );

        return { top, bottom, routes };
    };

    const getDefaultLayoutFlags = () => {
        const fullPagePaths = [
            routes.UNVERIFIED_EMAIL.path,
            routes.WAITING_APPROVAL.path,
            routes.DEACTIVATED_ACCOUNT.path,
            routes.EXPIRED_SUBSCRIPTION.path,
            routes.SETTINGS_SETUP.path,
            routes.PENDING_PAYMENT.path,
            routes.DASHBOARD.path
        ];

        const fullPageWithHeaderPaths = [settingPath, dashboardPath];
        const fullPageWithSidebarPaths = [dashboardPath];

        const isFullPage = fullPagePaths.includes(currentPath) || isUpgradingRoute;
        const isFullPageWithHeader = fullPageWithHeaderPaths.includes(currentPath);
        const isFullPageWithSidebar = fullPageWithSidebarPaths.includes(currentPath);

        if (!isFullPage && !isFullPageWithHeader && !isFullPageWithSidebar) {
            return {
                isFullPage: false,
                isFullPageWithHeader: true,
                isFullPageWithSidebar: true
            };
        }

        return {
            isFullPage,
            isFullPageWithHeader,
            isFullPageWithSidebar
        };
    };

    return {
        hasUser,
        auth,
        user,
        role,
        currentPath,
        isSuperAdmin,
        isCompanyAdmin,
        isPendingPayment,
        isSettingSetupRoute,
        isPendingPaymentRoute,
        isUpgradingRoute,
        isSettingsVerified,
        isExpired,
        renderType: getRenderType(),
        sidebarData: getSidebarData(),
        showDailyPromotionUpgrade: isCompanyAdmin && isTrial && showPromotion && !isExpired && !isUpgradingRoute,
        toCheckRoutes,
        routes,
        prevPath,
        token,
        isPrevpathUpgradingRoute,
        isPrevPathPendingPaymentRoute,
        isCloseToExpiring,
        isDashboardRoute,
        isPrevPathDashboardRoute,
        ...getDefaultLayoutFlags()
    };
};

export const useFetchSupportedGlobal = () => {
    const [fetching, setFetching] = useState(true);

    const [loadGlobal] = useLoadGlobalMutation();

    const dispatch = useAppDispatch();

    const supportedIndustries = useAppSelector(selectIndustries);
    const supportedCountries = useAppSelector(selectCountries);
    const supportedBanks = useAppSelector(selectBanks);
    const currentCountry = useAppSelector(selectCountry);

    const defaultSupportedGlobal = {
        industries: [],
        countries: [],
        banks: [],
        country: ""
    };

    const fetch = async () => {
        const response = await loadGlobal();
        if (response.error) {
            return defaultSupportedGlobal;
        }
        const datares = (response && response.data) || {};
        const supportedGlobal = {
            industries: datares.data.industries || defaultSupportedGlobal.industries,
            countries: datares.data.countries || defaultSupportedGlobal.countries,
            banks: datares.data.banks || defaultSupportedGlobal.banks,
            country: datares.data.country || defaultSupportedGlobal.country
        };
        dispatch(setGlobal(supportedGlobal));
        setFetching(false);
        return supportedGlobal;
    };

    useEffect(() => {
        if (!supportedIndustries.length || !supportedCountries.length) {
            fetch();
        } else {
            setFetching(false);
        }
    }, []);

    return [
        {
            supportedIndustries,
            supportedCountries,
            supportedBanks,
            currentCountry
        },
        fetching
    ];
};

export const useLogout = () => {
    const [logout, { isLoading }] = useLogoutMutation();
    const handleLogout = async () => {
        try {
            const response = await logout();
            if (response.error) {
                throw new Error("Failed to logout, something went wrong with the server. Please try again later.");
            }
        } catch (error) {
            createToast("Failed to logout, something went wrong with the server. Please try again later.", TOAST_TYPE.ERROR);
        }
    };
    return [handleLogout, isLoading];
};

export const useRefresh = () => {
    const dispatch = useAppDispatch();
    const user = useAppSelector(selectUser);

    const [refresh, { isLoading }] = useRefreshMutation();

    const handleRefresh = async () => {
        try {
            const response = await refresh();
            if (response.error) {
                throw new Error("Failed to refresh");
            }
            dispatch(setUser({ user, ...response.data.data }));
        } catch (error) {
            dispatch(reset());
        }
    };
    return [handleRefresh, isLoading];
};

export const useFetchRecord = (
    { id, rtk, dateFields = [], dateFormat = STANDARD_DATE_FORMAT, asPostQuery } = {},
    { runOnMount = true, onMount, onAfterFetch, noCache, transformResultAfterFetch } = {}
) => {
    const { useGetMutation, selectData, selectCurrent, setCurrent, setState } = rtk || {};

    const [isMounted, setMounted] = useState(true);
    const [getDetails, { status, isLoading, data: raw }] = useGetMutation();
    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const data = useAppSelector(selectData);

    let current = useAppSelector(selectCurrent);

    current = current && id && current.id == id ? current : null;

    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1 && "id" in current;
    const timezone = setting.timezone;

    const fetch = async (newId, { force } = {}) => {
        newId = newId || id;
        if (!newId) {
            return current;
        }
        let result = null;
        try {
            if (!noCache && !force && !isInitial && current && current.id === newId) {
                return current;
            }

            const qry = asPostQuery ? { body: { id: newId } } : { extraPath: newId };

            result = await getDetails(qry);

            if (result.error) {
                throw new Error("Failed to fetch data. Please try again later");
            }

            const newResult = await onAfterFetch?.(result);

            if (newResult) {
                if (newResult.error) {
                    throw new Error(""); // just throw to use the default error message
                }
                result = newResult;
            }

            // make sure the timezone of the selected dates is inline with the company timezone
            let temp = dateFields.length
                ? sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, dateFields, dateFormat)
                : result.data.data;
            const transformedResult = transformResultAfterFetch?.(temp);
            if (transformedResult && isObject(transformedResult)) {
                temp = transformedResult;
            }
            dispatch(setCurrent(temp));
            return temp;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const reset = (newId = id) => fetch(newId, { force: true });

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: data.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    const clearCurrent = () => {
        dispatch(setCurrent(null));
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMounted) {
                fetch().then(onMount);
            }
        }
    }, [isMounted, id]);

    useEffect(() => {
        !isMounted && setMounted(true);
    }, []);

    return [
        current,
        {
            isInitial,
            isLoading: id && ((runOnMount && status == "uninitialized" && !current) || (status == "pending" && isLoading)),
            updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch: () => fetch(id, { force: true }),
            raw: raw?.data
        }
    ];
};

export const useUpsertRecord = (
    { updateId, defaultForm = {}, current, isGettingRecord, rtk, dateFields = [], dateFormat = STANDARD_DATE_FORMAT },
    { onBeforeUpsert, onAfterUpsert }
) => {
    const { useCreateMutation, useUpdateMutation, setCurrent } = rtk || {};

    const isCreate = !updateId;
    const isUpdate = !!updateId;

    const [error, setError] = useState(null);
    const [old, setOld] = useState(null);
    const [form, setForm] = useState(defaultForm);

    const [create, { isLoading: isCreating }] = useCreateMutation();
    const [update, { isLoading: isUpdating }] = useUpdateMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const hasError = !!(error && Object.values(error).filter(Boolean).length);
    const timezone = setting.timezone;

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    const updateError = (obj = {}) =>
        setError({
            ...(error || {}),
            ...obj
        });

    const clearError = () => setError(null);

    const upsert = async () => {
        if (isCreating || isUpdating || (isUpdate && !updateId)) {
            return current;
        }
        let result = null;
        try {
            let clonedform = cloneDeep(form);
            // we just create an original copy in case if we delete some fields on the cloned form and mutate it on the result.
            const originalForm = cloneDeep(clonedform);
            const newForm = await onBeforeUpsert?.(clonedform);

            if (newForm && isObject(newForm)) {
                clonedform = newForm;
            }

            // make sure the timezone of the selected dates is inline with the company timezone
            clonedform = dateFields.length
                ? sanitizeDateWithLocalDateToOtherTimezoneFromObject(clonedform, timezone, dateFields, dateFormat)
                : clonedform;

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            const newResult = await onAfterUpsert?.(result, originalForm);

            if (newResult) {
                if (newResult.error) {
                    throw new Error(""); // just throw to use the default error message
                }
                result = newResult;
            }
            createToast(`Record ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);

            const res = dateFields.length
                ? sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, dateFields, dateFormat)
                : result.data.data;

            dispatch(setCurrent(res));
            clearError();
            return res;
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} record. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            updateError({ all: error.message });
            return { error };
        }
    };

    useEffect(() => {
        if (!isGettingRecord) {
            const temp = assignDefaultToBase(form, current, true, { isFallbackToDefault: true, nullFields: dateFields });
            setForm(temp);
            setOld(temp);
        }
    }, [isGettingRecord]);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting: isCreating || isUpdating,
            isCreating,
            isUpdating,
            old,
            hasChanges: !!(old && !isCreate && !isObjectEqualWithBase(form, old)),
            error,
            updateError,
            clearError,
            hasError
        }
    ];
};
