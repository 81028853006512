import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass, createConfirmAlert, createGroup, sanitizeWords } from "../../../common/utilities/helper";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import Input from "../../../common/components/extra/form/Input";
import SectionCollapseError from "../../../common/components/extra/section/SectionCollapseError";
import SiteSelectLazy from "../companySites/SiteSelectLazy";
import { BASE_CLASS, FIELDS } from "./const";
import { useUpsertWorkDetails } from "./hooks";
import BaseUpdateModal from "../../../common/components/layout/modalViewUpdateLayout/BaseUpdateModal";
import { WORK_TYPE } from "../../../common/utilities/const";
import SelectConstant from "../../../common/components/extra/select/SelectConstant";

const { NAME, CODE, TYPE, COMPANY_SITES } = FIELDS;

function UpdateModal({ open, onChange, onClose, onBack, id, onFinish, nosite, readOnly, transparentOverlay }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [form, updateForm, { upsert, isUpserting, isGettingRecord, config, hasChanges, isEditAllowed }] = useUpsertWorkDetails(id);

    const handleViewChange = (newObject = {}) => {
        setViewObject({ ...viewObject, ...newObject });
    };

    const isCreate = !id;
    const { invalidSiteCount } = config || {};
    const isProjectType = form[TYPE.name] == WORK_TYPE.PROJECT;

    const handleChange = (e) => {
        const name = e.target.name;
        const value = e.target.value;
        const config = { ...form };
        config[name] = value;
        updateForm(config);
    };

    const handleSave = async () => {
        const result = await upsert();
        result && typeof onFinish === "function" && onFinish(result, isCreate);
    };

    return (
        <BaseUpdateModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onBack={(!isCreate && onBack) || null}
            onSave={
                readOnly
                    ? null
                    : () =>
                          createConfirmAlert({
                              title: !isCreate ? "Update Work Type" : "Create Work Type",
                              content: `Are you sure you want to ${isCreate ? "create" : "update"} this work type? This cannot be undone.`,
                              onConfirm: async (close) => {
                                  close();
                                  await handleSave();
                              }
                          })
            }
            disableSave={invalidSiteCount || (!isCreate && !hasChanges)}
            styles={{
                content: {
                    width: "50vw",
                    maxWidth: "45rem",
                    minWidth: "35rem",
                    height: "max-content"
                }
            }}
            isLoading={isUpserting}
            isForm={!readOnly}
            transparentOverlay={transparentOverlay}
        >
            <div className={createClass(" flex column gap-05", BASE_CLASS)}>
                {!readOnly && !isGettingRecord && invalidSiteCount && (
                    <div className="flex column gap-05" style={{ marginBottom: "2rem" }}>
                        <SectionCollapseError show={!!invalidSiteCount}>Office and Branch can only have one site.</SectionCollapseError>
                    </div>
                )}
                <div className={createClass("__inner", BASE_CLASS)}>
                    <div className={createClass("__inner-row", BASE_CLASS)}>
                        {createGroup({
                            base: BASE_CLASS,
                            title: `${isCreate ? "Create" : "Update"} Work Type`,
                            body: (
                                <>
                                    <Input
                                        label={NAME.label}
                                        name={NAME.name}
                                        onChange={handleChange}
                                        value={form[NAME.name] || ""}
                                        required={NAME.required}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[NAME.name])}
                                        isLoading={isGettingRecord}
                                    />
                                    <Input
                                        label={CODE.label}
                                        name={CODE.name}
                                        onChange={handleChange}
                                        value={form[CODE.name] || ""}
                                        required={CODE.required}
                                        readOnly={readOnly}
                                        renderValue={readOnly && sanitizeWords(form[CODE.name])}
                                        isLoading={isGettingRecord}
                                    />
                                    <SelectConstant
                                        label={TYPE.label}
                                        name={TYPE.name}
                                        base={WORK_TYPE}
                                        value={form[TYPE.name]}
                                        onChange={(val) =>
                                            handleChange({
                                                target: {
                                                    name: TYPE.name,
                                                    value: val.value
                                                }
                                            })
                                        }
                                        menuPlacement="bottom"
                                        required={TYPE.required}
                                        readOnly={readOnly}
                                        menuPortalTarget={document.body}
                                        isLoading={isGettingRecord}
                                        subtext={{
                                            message: isProjectType
                                                ? "Notice: Project type can have multiple sites"
                                                : "Notice: Office & Branch type can only have one site."
                                        }}
                                        useSubTextStyle
                                        isOutlined
                                        disabledOutline
                                    />
                                    {!nosite && (
                                        <SiteSelectLazy
                                            label={COMPANY_SITES.label}
                                            name={COMPANY_SITES.name}
                                            onChange={(val) =>
                                                handleChange({
                                                    target: {
                                                        name: COMPANY_SITES.name,
                                                        value: val
                                                    }
                                                })
                                            }
                                            value={form[COMPANY_SITES.name]}
                                            isDisabled={!isEditAllowed || readOnly}
                                            isLoading={isGettingRecord}
                                            allowIncompleteSiteSelection
                                            isMulti
                                            isPortal
                                            isClearable
                                        />
                                    )}
                                </>
                            )
                        })}
                    </div>
                </div>
            </div>
            {!!viewObject.type && (
                <ViewGeozonesModal
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                />
            )}
        </BaseUpdateModal>
    );
}

UpdateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onFinish: PropTypes.func,
    onBack: PropTypes.func,
    nosite: PropTypes.bool,
    onClose: PropTypes.func,
    readOnly: PropTypes.bool,
    transparentOverlay: PropTypes.bool
};

export default UpdateModal;
