import React from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetWorkShiftDetails } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { createClass, sanitizeTimestamp, sanitizeWords, toProperTimezoneConversion, toReadableFromDate } from "../../../common/utilities/helper";
import { BASE_CLASS, FIELDS } from "./const";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import Tag from "../../../common/components/extra/Tag";
import { createTitle } from "./helper";

function ViewModal({ open, onClose, onEdit, id }) {
    const [current, { isInitial, isLoading, allowEditInfo, config }] = useGetWorkShiftDetails(id);

    const allowedTime = allowEditInfo.allowedTime;
    const allowEdit = !!allowEditInfo.isAllowed;
    const isSplitShift = config.isSplit;

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const disableEdit = isInitial || isLoading || !current;

    const COMMON_PROPS = { readOnly: true, isLoading };

    return (
        <BaseViewModal
            styles={{
                content: {
                    width: "50vw",
                    maxWidth: "45rem",
                    minWidth: "35rem",
                    height: "min-content"
                }
            }}
            open={open}
            onClose={onClose}
            onEdit={(allowEdit && onEdit) || null}
            disableEdit={disableEdit}
        >
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                {onEdit && !allowEdit && allowedTime && !isLoading && (
                    <SectionCollapseWarning show={!allowEdit}>
                        Editing capabilities are limited while the work shift is active. Editing will be available before {allowedTime?.before} or
                        after {allowedTime?.after}.
                    </SectionCollapseWarning>
                )}
                <FormSection header="Work Shift Details">
                    <Input
                        label="Title"
                        renderValue={
                            <div className="flex gap-05 align-center">
                                {sanitizeWords(createTitle(current))}
                                {current?.[FIELDS.IS_HOLIDAY.name] && (
                                    <Tag className="red" matchCapsule>
                                        Holiday
                                    </Tag>
                                )}
                            </div>
                        }
                        {...COMMON_PROPS}
                    />
                    <Input label="Type" renderValue={sanitizeWords(current?.[FIELDS.SHIFT_TYPE.name])} {...COMMON_PROPS} />
                    <Input
                        label="Max Break Duration"
                        renderValue={sanitizeWords(current?.[FIELDS.MAX_BREAK_DURATION.name])}
                        afterExtra={<span className="fade">Hour(s)</span>}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Max Overtime Duration"
                        renderValue={current?.[FIELDS.MAX_OVERTIME.name] || <span className="fade">No Max OT</span>}
                        afterExtra={current?.[FIELDS.MAX_OVERTIME.name] && <span className="fade">Hour(s)</span>}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Required Shift Time"
                        renderValue={current?.[FIELDS.REQ_SHIFT_TIME.name]}
                        afterExtra={<span className="fade">Hour(s)</span>}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                <FormSection header="Shift One">
                    <Input
                        label="Start Time"
                        renderValue={toProperTimezoneConversion(current?.[FIELDS.START_TIME.name])?.format?.("hh:mm A")}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="End Time"
                        renderValue={toProperTimezoneConversion(current?.[FIELDS.END_TIME.name])?.format?.("hh:mm A")}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Break Start"
                        renderValue={toProperTimezoneConversion(current?.[FIELDS.BREAK_TIME.name])?.format?.("hh:mm A")}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Break End"
                        renderValue={toProperTimezoneConversion(current?.[FIELDS.BREAK_END_TIME.name])?.format?.("hh:mm A")}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                {isSplitShift && (
                    <FormSection header="Shift Two">
                        <Input
                            label="Start Time"
                            renderValue={toProperTimezoneConversion(current?.[FIELDS.START_TIME_2.name])?.format?.("hh:mm A")}
                            {...COMMON_PROPS}
                        />
                        <Input
                            label="End Time"
                            renderValue={toProperTimezoneConversion(current?.[FIELDS.END_TIME_2.name])?.format?.("hh:mm A")}
                            {...COMMON_PROPS}
                        />
                        <Input
                            label="Break Start"
                            renderValue={toProperTimezoneConversion(current?.[FIELDS.BREAK_TIME_2.name])?.format?.("hh:mm A")}
                            {...COMMON_PROPS}
                        />
                        <Input
                            label="Break End"
                            renderValue={toProperTimezoneConversion(current?.[FIELDS.BREAK_END_TIME_2.name])?.format?.("hh:mm A")}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                )}
                <FormSection header="Record Details">
                    <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                </FormSection>
            </div>
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
