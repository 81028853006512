import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { VIEW_TYPES } from "../../../common/utilities/const";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import { TOAST_TYPE, createToast } from "../../../common/utilities/helper";
import { selectUser } from "../../common/slice";
import { setClearCache as setClearCachedEmployees } from "../employees/slice";
import { setClearCache as setClearCachedSites } from "../companySites/slice";
import CreateTableHeaders from "./CreateTableHeaders";
import UpdateModal from "./UpdateModal";
import { useDeleteWorkDetailsMutation } from "./api";
import { selectTableConfig, selectView } from "./slice";
import { usePaginateWorkDetails } from "./hooks";
import ViewModal from "./ViewModal";

function WorkDetails({ openAddModal, onAddModalClose, parentIsDoingActions }) {
    const [object, setObject] = useState({
        viewSites: false,
        openViewModal: false,
        openEditModal: openAddModal,
        selected: null
    });

    const [data, { initializing, fetch, isDoingActions }] = usePaginateWorkDetails();
    const [remove] = useDeleteWorkDetailsMutation();

    const dispatch = useAppDispatch();
    const tableConfig = useAppSelector(selectTableConfig);
    const view = useAppSelector(selectView);
    const user = useAppSelector(selectUser);
    const showViewModal = !!object.openViewModal;
    const showUpdateModal = object.openEditModal;
    const isPaginating = initializing || parentIsDoingActions || isDoingActions;

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));
    const departments = (user.Setting && user.Setting.departments) || [];

    useEffect(() => {
        if (openAddModal && openAddModal !== object.openEditModal) {
            updateObject({ selected: null, openEditModal: openAddModal });
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        updateObject({
            openEditModal: false,
            openViewModal: true
        });
    };

    const handleModalEdit = () => {
        updateObject({
            openEditModal: true,
            openViewModal: false
        });
    };

    const handleFinish = async (res) => {
        await fetch();
        updateObject({ selected: res, openViewModal: true, viewSites: false, openEditModal: false });
        dispatch(setClearCachedEmployees());
        dispatch(setClearCachedSites());
        typeof onAddModalClose == "function" && onAddModalClose();
    };

    const handleDelete = async (row) => {
        const response = await remove({ extraPath: row.id });
        if (response.error) {
            createToast(response.error?.data?.message || "Failed to delete work type.", TOAST_TYPE.ERROR);
        } else {
            await fetch();
        }
    };

    const handleViewSite = (row) => {
        updateObject({ viewSites: true, selected: row });
    };

    const handleViewModalClose = () =>
        updateObject({
            openViewModal: false
        });

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        updateObject({
            openEditModal: false
        });
    };

    return (
        <>
            {(() => {
                switch (view) {
                    default:
                    case VIEW_TYPES.TABLE:
                        return (
                            <TableView
                                data={data}
                                headers={CreateTableHeaders({
                                    departments,
                                    onView: (row) => updateObject({ openViewModal: true, selected: row }),
                                    onDelete: handleDelete,
                                    onViewSites: handleViewSite
                                })}
                                refetch={fetch}
                                isLoading={isPaginating}
                                tableConfig={tableConfig}
                            />
                        );
                }
            })()}
            {!!object.viewSites && (
                <ViewGeozonesModal
                    open={object.viewSites}
                    onChange={(bool) => updateObject({ viewSites: bool })}
                    data={object.selected?.CompanySites.map((cs) => cs.id) || []}
                />
            )}
            {showViewModal && <ViewModal id={object?.selected?.id} open={showViewModal} onClose={handleViewModalClose} onEdit={handleModalEdit} />}
            {showUpdateModal && (
                <UpdateModal
                    onBack={handleModalBack}
                    open={showUpdateModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                    id={object?.selected?.id}
                />
            )}
        </>
    );
}

WorkDetails.propTypes = {
    parentIsDoingActions: PropTypes.bool,
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default WorkDetails;
