import React, { useState } from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectUser } from "../../features/common/slice";
import { selectLoading } from "../../features/company/companyHolidays/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import CompanyHolidays from "../../features/company/companyHolidays/CompanyHolidays";
import Filter from "../../features/company/companyHolidays/Filter";
import { usePaginateCompanyHolidays } from "../../features/company/companyHolidays/hooks";
import { BASE_CLASS } from "../../features/company/companyHolidays/const";
import { getFilterCount } from "../../common/utilities/helper";

function CompanyHolidaysPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);

    const [, { isLoading, onFilter, onSearch, tableConfig, isDoingActions }] = usePaginateCompanyHolidays({ readOnly: true });

    return (
        <PageLayout
            className={BASE_CLASS}
            title="Manage Holidays"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search, placeholder: "Search name..." }}
            filter={<Filter onFilter={onFilter} isLoading={isLoading} />}
            onAdd={() => setOpenAddModal(true)}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(tableConfig.filter)}
            hasFilter
        >
            <CompanyHolidays
                user={user}
                openAddModal={openAddModal}
                onAddModalClose={() => setOpenAddModal(false)}
                parentIsDoingActions={isDoingActions}
            />
        </PageLayout>
    );
}

export default CompanyHolidaysPage;
