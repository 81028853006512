import React, { useState } from "react";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import {
    selectLoading,
    selectTableConfig,
    selectEmployeeWorkShiftData,
    setEmployeeWorkShiftData
} from "../../features/company/employeeWorkShift/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import Filter from "../../features/company/employeeWorkShift/Filter";
import { usePaginateWorkShift } from "../../features/company/employeeWorkShift/hooks";
import Tabs from "../../common/components/extra/tabs/TabsV2";
import { selectSidebarCollapse } from "../../features/common/slice";
import { getFilterCount } from "../../common/utilities/helper";
import { TABS } from "../../features/company/employeeWorkShift/const";
import { TAB_ACTION } from "../../common/components/extra/tabs/const";
import WorkShift from "../../features/company/employeeWorkShift/WorkShift";

function EmployeeWorkshiftsPage() {
    const [activeHash, setActiveHash] = useState(null);
    const [openAddModal, setOpenAddModal] = useState(false);

    const loading = useAppSelector(selectLoading);
    const config = useAppSelector(selectTableConfig);
    const data = useAppSelector(selectEmployeeWorkShiftData);
    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);

    const dispatch = useAppDispatch();

    const [, { isLoading, onFilter, onSearch, isDoingActions }] = usePaginateWorkShift({
        readOnly: true,
        isHoliday: activeHash == TABS.HOLIDAY_WORKSHIFT.id
    });

    const handleSearch = (value) => {
        return onSearch(value, { isHoliday: activeHash == TABS.HOLIDAY_WORKSHIFT.id }, { includeBodyKeysOnReset: ["isHoliday"] });
    };

    const handleFilter = (filter) => {
        return onFilter(filter, { isHoliday: activeHash == TABS.HOLIDAY_WORKSHIFT.id }, { includeBodyKeysOnReset: ["isHoliday"] });
    };

    const handleAdd = () => {
        setOpenAddModal(true);
    };

    const handleTabChange = (_, hash, action) => {
        setActiveHash(hash);
        if (action === TAB_ACTION.CHANGING) {
            dispatch(setEmployeeWorkShiftData([]));
        }
    };

    return (
        <PageLayout
            className="tk-employees-work-shift"
            title="Manage Work Shifts"
            isLoading={loading}
            search={{ onSearch: handleSearch, value: config.search, placeholder: "Search title..." }}
            filter={<Filter onFilter={handleFilter} isLoading={isLoading} />}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(config.filter)}
            hasFilter
        >
            <Tabs
                id="workshifts"
                styles={{
                    parent: {
                        width: data?.length ? "min-content" : "100%"
                    },
                    head: {
                        width: isSidebarCollapse ? "calc(100vw - 12rem)" : "calc(100vw - 24rem)",
                        minWidth: "15rem"
                    },
                    body: {
                        padding: 0,
                        overflow: "unset",
                        flexGrow: "1",
                        height: "calc(100vh - 27rem)"
                    },
                    content: {
                        padding: 0
                    }
                }}
                onChange={handleTabChange}
                hasBackground={!data.length}
            >
                <WorkShift
                    label={TABS.REGULAR_WORKSHIFT.label}
                    hash={TABS.REGULAR_WORKSHIFT.id}
                    openAddModal={openAddModal}
                    onAddModalClose={() => setOpenAddModal(false)}
                    parentIsDoingActions={isDoingActions}
                />
                <WorkShift
                    label={TABS.HOLIDAY_WORKSHIFT.label}
                    hash={TABS.HOLIDAY_WORKSHIFT.id}
                    openAddModal={openAddModal}
                    onAddModalClose={() => setOpenAddModal(false)}
                    parentIsDoingActions={isDoingActions}
                    isHoliday
                />
            </Tabs>
        </PageLayout>
    );
}

export default EmployeeWorkshiftsPage;
