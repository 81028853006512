import React from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetWorkDetails } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { createClass, renderNA, sanitizeTimestamp, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { BASE_CLASS, FIELDS } from "./const";
import SectionCollapseWarning from "../../../common/components/extra/section/SectionCollapseWarning";
import Tag from "../../../common/components/extra/Tag";

function ViewModal({ open, onClose, onEdit, id }) {
    const [current, { isInitial, isLoading, allowEditInfo, config }] = useGetWorkDetails(id);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const isIncomplete = config.isIncomplete;
    const disableEdit = isInitial || isLoading || !current;
    const allowedTime = allowEditInfo.allowedTime;
    const allowEdit = !!allowEditInfo.isAllowed;
    const COMMON_PROPS = { readOnly: true, isLoading };
    const sites = current?.result?.[FIELDS.COMPANY_SITES.name] || [];

    return (
        <BaseViewModal
            styles={{
                content: {
                    width: "50vw",
                    maxWidth: "45rem",
                    minWidth: "35rem",
                    height: "min-content"
                }
            }}
            open={open}
            onClose={onClose}
            onEdit={(allowEdit && onEdit) || null}
            disableEdit={disableEdit}
        >
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                {onEdit && ((!allowEdit && allowedTime) || isIncomplete) && !isLoading && (
                    <SectionCollapseWarning show>
                        {isIncomplete ? (
                            <span>Incomplete fields, must have at least one site to be able to use.</span>
                        ) : (
                            <span>
                                Editing capabilities are limited while the work type is active. Editing will be available before {allowedTime?.before}
                                &nbsp;or after {allowedTime?.after}.
                            </span>
                        )}
                    </SectionCollapseWarning>
                )}
                <FormSection header="Work Type Details">
                    <Input
                        label="Name"
                        renderValue={<div className="flex gap-05 align-center">{sanitizeWords(current?.result?.[FIELDS.NAME.name])}</div>}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Code"
                        renderValue={
                            current?.result?.[FIELDS.CODE.name] ? (
                                <Tag className="dark-gray">
                                    <span className="text-ellipsis semi-bold">{current?.result?.[FIELDS.CODE.name]}</span>
                                </Tag>
                            ) : (
                                renderNA()
                            )
                        }
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Type"
                        renderValue={<div className="flex gap-05 align-center">{sanitizeWords(current?.result?.[FIELDS.TYPE.name])}</div>}
                        {...COMMON_PROPS}
                    />
                    <Input
                        label="Sites"
                        renderValue={
                            <div className="flex gap-05 align-center gap-03">
                                {sites.length ? sites.map((site) => <Tag key={site.id}>{sanitizeWords(site.title)}</Tag>) : renderNA("None")}
                            </div>
                        }
                        {...COMMON_PROPS}
                    />
                </FormSection>
                <FormSection header="Record Details">
                    <Input label="Created" renderValue={current && toReadableFromDate(current?.result?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input
                        label="Updated"
                        renderValue={sanitizeTimestamp(current?.result?.updatedAt, current?.result?.createdAt, timezone)}
                        {...COMMON_PROPS}
                    />
                </FormSection>
            </div>
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
