import React, { useState } from "react";
import PropTypes from "prop-types";
import { createClass } from "../../../common/utilities/helper";
import { BASE_CLASS_CREATE_MODAL, ROUTE_DATA_TYPE_PARAM } from "./const";
import Button from "../../../common/components/extra/Button";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import CreateOtherSettlementsModal from "./CreateOtherSettlementsModal";
import CreateFinalSettlementsModal from "./CreateFinalSettlementsModal";

function CreateModal({ open, onClose, onFinish }) {
    const [selected, setSelected] = useState(null);

    const openCreateOtherSettlement = selected == ROUTE_DATA_TYPE_PARAM.OTHERS;
    const openCreateFinalSettlement = selected == ROUTE_DATA_TYPE_PARAM.FINAL;

    return (
        <>
            <BaseViewModal
                open={open}
                onClose={onClose}
                styles={{
                    content: {
                        height: "max-content",
                        width: "max-content",
                        minWidth: "25rem"
                    },
                    body: { overflow: "auto" }
                }}
                className={createClass(BASE_CLASS_CREATE_MODAL)}
            >
                <div className="flex column gap-1 align-center">
                    <Button onClick={() => !selected && setSelected(ROUTE_DATA_TYPE_PARAM.OTHERS)} disabled={!!selected}>
                        Create Other Settlement
                    </Button>
                    <Button className="primary" onClick={() => !selected && setSelected(ROUTE_DATA_TYPE_PARAM.FINAL)} disabled={!!selected}>
                        Create Final Settlement
                    </Button>
                </div>
            </BaseViewModal>
            {openCreateOtherSettlement && (
                <CreateOtherSettlementsModal
                    open={openCreateOtherSettlement}
                    onBack={() => setSelected(null)}
                    onClose={onClose}
                    onFinish={onFinish}
                />
            )}
            {openCreateFinalSettlement && (
                <CreateFinalSettlementsModal
                    open={openCreateFinalSettlement}
                    onBack={() => setSelected(null)}
                    onClose={onClose}
                    onFinish={onFinish}
                />
            )}
        </>
    );
}

CreateModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onFinish: PropTypes.func
};

export default CreateModal;
