import React, { useState } from "react";
import PropTypes from "prop-types";
import { createFullName } from "../../../common/utilities/helper";
// import Tag from "../../../common/components/extra/Tag";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import { cloneDeep } from "lodash";

const TYPES = {
    SUPERVISOR: "supervisor",
    MANAGER: "manager"
};

const options = {
    KEEP_ON_SITE: "KEEP_ON_SITE",
    REMOVE_FROM_SITE: "REMOVE_FROM_SITE"
};

const EmployeesKeepOrRemove = ({ removedSupervisors, removedManagers, onChange, fallback = <></>, header }) => {
    const [items, setItems] = useState(() => {
        const removedSupervisorsWithRoles = removedSupervisors.map((supervisor) => ({
            type: TYPES.SUPERVISOR,
            employee: supervisor
        }));
        const removedManagersWithRoles = removedManagers.map((manager) => ({
            type: TYPES.MANAGER,
            employee: manager
        }));

        const combinedEmployees = [...removedSupervisorsWithRoles, ...removedManagersWithRoles];
        const employeeMap = new Map();

        combinedEmployees.forEach(({ type, employee }) => {
            if (employeeMap.has(employee.id)) {
                const existingEntry = employeeMap.get(employee.id);
                existingEntry.type = `${existingEntry.type} / ${type}`;
            } else {
                employeeMap.set(employee.id, { type, employee });
            }
        });
        const uniqueEmployees = Array.from(employeeMap.values());
        return uniqueEmployees;
    });

    const handleChange = (value, type, employee) => {
        const clone = cloneDeep(items);
        const index = clone.findIndex((item) => item.employee.id === employee.id);
        clone[index].employee.removeFromSite = value === options.REMOVE_FROM_SITE;

        onChange({
            toRemove: clone.filter((item) => item.employee.removeFromSite),
            toKeep: clone.filter((item) => !item.employee.removeFromSite)
        });
        setItems(clone);
    };

    if (!items.length) {
        return fallback;
    }

    return (
        <div className="small-font flex column gap-1">
            {header}
            <div
                className="employees-keep-remove"
                style={{
                    overflow: "auto",
                    maxHeight: "calc(100vh - 20rem)",
                    minHeight: "4rem"
                }}
            >
                <ul className="employees-keep-remove__list flex column gap-03">
                    {items.map(({ type, employee }, index) => (
                        <li key={index} className="flex column indent-1">
                            <div className="flex gap-05 align-center">
                                {/* <Tag matchCapsule>{type}</Tag> */}
                                <span className="bold">{createFullName(employee.first_name, employee.last_name)}</span>
                            </div>
                            <Input
                                name={`remove-${employee.id}-${type}`}
                                type={INPUT_TYPE.RADIO}
                                value={employee.removeFromSite ? options.REMOVE_FROM_SITE : options.KEEP_ON_SITE}
                                options={[
                                    {
                                        value: options.KEEP_ON_SITE,
                                        label: <span className="xs-font fade">Keep on Site</span>
                                    },
                                    {
                                        value: options.REMOVE_FROM_SITE,
                                        label: <span className="xs-font fade">Remove From Site</span>
                                    }
                                ]}
                                onChange={(e) => handleChange(e.target.value, type, employee)}
                                noBorder
                            />
                        </li>
                    ))}
                </ul>
            </div>
        </div>
    );
};

export default EmployeesKeepOrRemove;

EmployeesKeepOrRemove.propTypes = {
    removedSupervisors: PropTypes.array,
    removedManagers: PropTypes.array,
    onChange: PropTypes.func,
    fallback: PropTypes.any,
    header: PropTypes.any
};
