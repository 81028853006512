import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import PlaceIcon from "@mui/icons-material/Place";
import { ROLE_LEVEL, ROLE_TYPE } from "../../../common/utilities/const";
import { renderNA, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { ASSIGN_TYPE } from "./const";
import Button from "../../../common/components/extra/Button";
import MyTooltip from "../../../common/components/extra/Tooltip";
import SeeMore from "../../../common/components/extra/SeeMore";
import UpdateFiles from "./UpdateFiles";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCountry, selectUserSetting } from "../../common/slice";
import Text from "../../../common/components/extra/typography/Text";

const MAX_SITES_TO_SHOW = 5;

const LazyTableHeaders = ({
    onViewRecord,
    onViewSites,
    onViewFile,
    readOnly,
    defaultCheckedId,
    enableUncheckedOnshift,
    type,
    title,
    workShiftId,
    disableSupervisors,
    showSites,
    selected,
    isExpiringWideUI
}) => {
    const isWorkShift = type == ASSIGN_TYPE.WORK_SHIFT;
    const isSite = type == ASSIGN_TYPE.SITE;
    const isExpiring = type == ASSIGN_TYPE.EXPIRING;

    const setting = useAppSelector(selectUserSetting);
    const currentCountry = useAppSelector(selectCountry);
    const isInUAE = currentCountry === "ae";

    const isDisabledSelection = (row) => {
        const onShiftSiteId = row?.onShiftSiteId;
        const isSVorMGinSelectedSite =
            disableSupervisors && row.CompanySites.some((site) => site.id == selected.id && (site.isManager || site.isSupervisor));
        return (
            (defaultCheckedId.includes(row.id) && row.isOnShift) ||
            (!enableUncheckedOnshift && selected?.id && onShiftSiteId != selected.id && row.isOnShift && !defaultCheckedId.includes(row.id)) ||
            isSVorMGinSelectedSite
        );
    };

    const headers = {
        CHECKBOX: {
            key: "id",
            width: "10%",
            style: { justifyContent: "flex-start" },
            render: () => "",
            isCheckbox: !readOnly,
            disableSelection: isDisabledSelection
        },
        EMPLOYEE: {
            key: "index1",
            sortKey: "index1",
            label: title || "Employee",
            width: "70%",
            render: (row) => {
                const fullName = `${row.first_name} ${row.last_name}`;
                const department = row.CompanyDepartment;
                const designation = row.CompanyDesignation;
                const isSuperVisor = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;
                const contract = row.contract;
                const residenceID = row.residenceID;
                const visaID = row.visaID;
                const passportID = row.passportID;
                const laborCardNumber = row.labor_card;
                const hideValidityRow = !contract?.date && !residenceID?.date && !visaID?.date && !passportID?.date && !laborCardNumber?.date;
                const selectionCannotBeRemoved = isDisabledSelection(row);

                const showFileTag = (validity = {}) => {
                    return validity.isExpired || validity.isWarning;
                };

                const createFileTooltip = (validity = {}) => {
                    const isExpired = validity.isExpired;
                    const expiryDate = validity.expiryDate;
                    const expiredMessage = `Already expired at ${toReadableFromDate(expiryDate, setting.timezone, "MMM DD, YYYY")}`;
                    const warningMessage = `Will expire in ${toReadableFromDate(expiryDate, setting.timezone, "MMM DD, YYYY")}`;
                    const message = isExpired ? expiredMessage : warningMessage;
                    return { message };
                };

                const createTagClass = (validity = {}) => (validity.isExpired ? "red-inverse" : "yellow");

                return (
                    <div className="flex gap-1">
                        <div className="flex column gap-05">
                            <div className="flex column gap-05" style={{ justifyContent: "center" }}>
                                <div
                                    className="link-hover"
                                    style={{ fontWeight: "bold", width: "max-content" }}
                                    onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                                >
                                    {sanitizeWords(fullName)}
                                </div>

                                <div className="flex gap-05 wrap">
                                    {isWorkShift &&
                                        !defaultCheckedId.includes(row.id) &&
                                        row?.EmployeeWorkShift &&
                                        workShiftId == row?.EmployeeWorkShift?.id && <Tag className="red">{row?.EmployeeWorkShift?.title}</Tag>}
                                    {row.isOnShift && <Tag type={TAG_TYPE.ON_SHIFT} />}
                                    {isSuperVisor && <Tag type={TAG_TYPE.APP_SUPERVISOR} />}
                                </div>
                            </div>
                            {!hideValidityRow && isExpiringWideUI && (
                                <div className="flex gap-05 column small-font">
                                    <span className="small-font fade">Expiring/Expired:</span>
                                    <div className="flex wrap gap-05">
                                        {showFileTag(contract) && (
                                            <Tag className={createTagClass(contract)} tooltip={createFileTooltip(contract)}>
                                                <span className="text-ellipsis">Contract</span>
                                            </Tag>
                                        )}
                                        {showFileTag(residenceID) && (
                                            <Tag className={createTagClass(residenceID)} tooltip={createFileTooltip(residenceID)}>
                                                <span className="text-ellipsis">Residence ID</span>
                                            </Tag>
                                        )}
                                        {showFileTag(visaID) && (
                                            <Tag className={createTagClass(visaID)} tooltip={createFileTooltip(visaID)}>
                                                <span className="text-ellipsis">Visa ID</span>
                                            </Tag>
                                        )}
                                        {showFileTag(passportID) && (
                                            <Tag className={createTagClass(passportID)} tooltip={createFileTooltip(passportID)}>
                                                <span className="text-ellipsis">Passport ID</span>
                                            </Tag>
                                        )}
                                        {isInUAE && showFileTag(laborCardNumber) && (
                                            <Tag className={createTagClass(laborCardNumber)} tooltip={createFileTooltip(laborCardNumber)}>
                                                <span className="text-ellipsis">Labor Card</span>
                                            </Tag>
                                        )}
                                    </div>
                                </div>
                            )}
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Department:</span>
                                <span className="semi-bold">{sanitizeWords(department?.title) || renderNA("No Department.")}</span>
                            </div>
                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                <span className="small-font fade">Designation:</span>
                                <span className="semi-bold">{sanitizeWords(designation?.title) || renderNA("No Designation.")}</span>
                            </div>
                            {isSite && showSites && !!row?.CompanySites?.length && (
                                <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                    <span className="small-font fade">Sites:</span>
                                    <SeeMore max={MAX_SITES_TO_SHOW}>
                                        {row?.CompanySites.map((site, idx) => (
                                            <MyTooltip key={idx} message={site.title}>
                                                <Tag className={site.id == selected.id ? "green" : ""} style={{ maxWidth: "7rem" }}>
                                                    <span className="text-ellipsis">{site.title}</span>
                                                </Tag>
                                            </MyTooltip>
                                        ))}
                                    </SeeMore>
                                </div>
                            )}
                            {selectionCannotBeRemoved && (
                                <Text useSubTextStyle>Notice: You cannot remove an employee who is a supervisor/manager for this site.</Text>
                            )}
                        </div>
                    </div>
                );
            }
        },
        ACTION: {
            key: "action",
            width: readOnly ? "30%" : "20%",
            style: { display: "flex", justifyContent: "flex-end" },
            render: (row) => {
                return (
                    <div className="flex gap-05">
                        <Button
                            onClick={() => typeof onViewRecord === "function" && onViewRecord(row)}
                            options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                            transparent
                        >
                            <div className="fade">
                                <PreviewIcon style={{ width: "1.5rem" }} />
                            </div>
                        </Button>
                        {isExpiring && (
                            <UpdateFiles
                                id={row.id}
                                onClick={() => typeof onViewFile === "function" && onViewFile(row)}
                                transparentOverlay
                                isIcon
                                noInternalModal
                            />
                        )}
                        {!isExpiring && (
                            <Button
                                onClick={() => typeof onViewSites === "function" && onViewSites(row)}
                                options={{ style: { padding: 0, boxShadow: "unset", marginLeft: "auto", maxWidth: "max-content" } }}
                                transparent
                            >
                                <div className="fade">
                                    <PlaceIcon style={{ width: "1.5rem" }} />
                                </div>
                            </Button>
                        )}
                    </div>
                );
            }
        }
    };
    if (readOnly) {
        delete headers.CHECKBOX;
    }
    return { data: Object.values(headers), original: headers };
};

export default LazyTableHeaders;
