import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import CreateTableHeaders from "./CreateTableHeaders";
import ViewModal from "./ViewModal";
import UpdateModal from "./UpdateModal";
import { setCurrent, setState } from "./slice";
import UpdateFilesModal from "./UpdateFilesModal";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { CUSTOM_ACTION } from "./const";
import { usePaginateEmployees } from "./hooks";
import UpdateDepartmentModal from "./UpdateDepartmentModal";
import { resetAll } from "../../../app/store";

function Employees({ openAddModal, onAddModalClose, parentIsDoingActions }) {
    const [deletingIds, setDeletingIds] = useState([]);

    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [openViewFileModal, setOpenViewFileModal] = useState(false);
    const [openViewDeptModal, setOpenViewDeptModal] = useState(false);
    const [selected, setSelected] = useState(null);

    const [data, { initializing, fetch, update, reset, isFilterOnDefault, tableConfig, isDoingActions }] = usePaginateEmployees();

    const dispatch = useAppDispatch();
    const isPaginating = initializing || parentIsDoingActions || isDoingActions;

    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
        setOpenViewFileModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (row) => {
        setOpenViewModal(true);
        setOpenEditModal(false);
        setSelected(row);
        reset();
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleAction = (row, actionType, eventType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                setOpenViewModal(true);
                setSelected(row);
                break;
            case TABLE_ACTION_TYPE_COMMON.REMOVE:
                if (eventType === "on-start") {
                    setDeletingIds((prev) => [...prev, row.id]);
                }
                if (eventType == "on-finish") {
                    reset().then(() => {
                        setDeletingIds((prev) => prev.filter((id) => id !== row.id));
                        dispatch(setState({ current: null }));
                        resetAll(["auth", "employees"]);
                    });
                }
                break;
            case CUSTOM_ACTION.VIEW_FILES:
                setSelected(row);
                setOpenViewFileModal(true);
                break;
            case CUSTOM_ACTION.VIEW_DEPARTMENT:
                setSelected(row);
                setOpenViewDeptModal(true);
                break;
            case CUSTOM_ACTION.RESET_DEVICE:
                if (!isFilterOnDefault) {
                    reset();
                }
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => {
        setSelected(null);
        setOpenViewModal(false);
    };

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    const handleUpdateFileFinish = (result) => {
        if (isFilterOnDefault) {
            update(result.id, {
                passportID_expiration: result.passportID_expiration,
                visaID_expiration: result.visaID_expiration,
                residenceID_expiration: result.residenceID_expiration,
                contract_expiration: result.contract_expiration,
                labor_card_expiration: result.labor_card_expiration
            });
        } else {
            reset();
        }
        handleModalBack();
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction, deletingIds })}
                refetch={fetch}
                isLoading={isPaginating}
                tableConfig={tableConfig}
                disabledIds={deletingIds}
            />
            {openViewFileModal && (
                <UpdateFilesModal
                    id={selected?.id}
                    open={openViewFileModal}
                    onClose={() => setOpenViewFileModal(false)}
                    onFinish={handleUpdateFileFinish}
                />
            )}
            {openViewDeptModal && (
                <UpdateDepartmentModal
                    id={selected?.id}
                    open={openViewDeptModal}
                    onClose={() => setOpenViewDeptModal(false)}
                    onFinish={() => setOpenViewDeptModal(false)}
                />
            )}
            {openViewModal && <ViewModal open={openViewModal} onClose={handleViewModalClose} id={selected?.id} onEdit={handleModalEdit} />}
            {openEditModal && (
                <UpdateModal
                    id={selected?.id}
                    open={openEditModal}
                    onClose={handleUpdateModalClose}
                    onBack={handleModalBack}
                    onFinish={handleFinish}
                />
            )}
        </>
    );
}

Employees.propTypes = {
    parentIsDoingActions: PropTypes.bool,
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default Employees;
