import React, { useState } from "react";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import WorkDetails from "../../features/company/employeeWorkDetails/WorkDetails";
import PageLayout from "../../common/components/layout/PageLayout";
import { usePaginateWorkDetails } from "../../features/company/employeeWorkDetails/hooks";
import { selectUser } from "../../features/common/slice";
import { selectLoading } from "../../features/company/employeeWorkDetails/slice";

function EmployeeWorkTypesPage() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [, { onSearch, tableConfig, isDoingActions }] = usePaginateWorkDetails({ readOnly: true });

    const user = useAppSelector(selectUser);
    const loading = useAppSelector(selectLoading);

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees-work-type"
            title="Manage Work Types"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search, placeholder: "Search title..." }}
            // filter={<Filter onFilter={onFilter} isLoading={isLoading} />}
            onAdd={handleAdd}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            // activeFilterCount={getFilterCount(tableConfig.filter)}
            // hasFilter
        >
            <WorkDetails
                user={user}
                openAddModal={openAddModal}
                onAddModalClose={() => setOpenAddModal(false)}
                parentIsDoingActions={isDoingActions}
            />
        </PageLayout>
    );
}

export default EmployeeWorkTypesPage;
