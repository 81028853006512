import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import FormSection from "../../../common/components/extra/FormSection";
import { VIEW_MODAL_TYPE } from "./const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { renderNA, addCommasToMoney } from "../../../common/utilities/helper";
import { computeTotalSalaryPackage } from "./helper";

const { SALARY } = VIEW_MODAL_TYPE;

function ViewModalSub({ open, onChange, data, type }) {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const contract = data?.EmployeeContract || {};
    const allowance = contract.allowance || {};

    const totalSalaryPackage = useMemo(() => computeTotalSalaryPackage(contract.basic_amount, allowance), [contract]);

    return (() => {
        let children,
            title,
            style = {};
        switch (type) {
            case SALARY: {
                const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, readOnly: true };
                title = "Air Ticket & Salary Details";
                children = (
                    <FormSection style={{ minWidth: "25rem" }} nohead>
                        <div className="flex column" style={{ margin: ".5rem 0" }}>
                            <span className="fade" style={{ margin: ".5rem" }}>
                                Air Ticket
                            </span>
                            <ul className="indent-1">
                                <li className="indent-1">
                                    <Input
                                        label="Amount"
                                        renderValue={addCommasToMoney(allowance.air_ticket)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                            </ul>
                        </div>
                        <div className="flex column" style={{ margin: ".5rem 0" }}>
                            <span className="fade" style={{ margin: ".5rem" }}>
                                Salary Package
                            </span>
                            <ul className="indent-1">
                                <li className="indent-1">
                                    <Input
                                        label="Basic Salary"
                                        renderValue={addCommasToMoney(contract.basic_amount)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Accommodation Allowance"
                                        renderValue={addCommasToMoney(allowance.accommodation)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Food Allowance"
                                        renderValue={addCommasToMoney(allowance.food)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Transportation Allowance"
                                        renderValue={addCommasToMoney(allowance.transportation)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Communication Allowance"
                                        renderValue={addCommasToMoney(allowance.communication)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Education Allowance"
                                        renderValue={addCommasToMoney(allowance.education)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        label="Other Allowance"
                                        renderValue={addCommasToMoney(allowance.other)}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                    />
                                </li>
                                <li className="indent-1">
                                    <Input
                                        parentStyle={{ marginTop: ".5rem" }}
                                        label="Total Salary Package"
                                        renderValue={<h3 className="primary-color">{addCommasToMoney(totalSalaryPackage)}</h3>}
                                        afterExtra={renderNA(setting.currency)}
                                        {...COMMON_INPUT_PROPS}
                                        noBorder
                                    />
                                </li>
                            </ul>
                        </div>
                    </FormSection>
                );
                break;
            }
            default:
                break;
        }
        return (
            <Modal title={title} open={open} onChange={onChange} styles={{ title: { textAlign: "center" }, content: style }} small>
                <div className="tk-employees__modal-content-view" style={{ padding: "1rem" }}>
                    {children}
                </div>
            </Modal>
        );
    })();
}

ViewModalSub.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.object,
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(VIEW_MODAL_TYPE))
};

export default ViewModalSub;
