import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import Modal from "../../extra/Modal";
import BaseUpdateModalWrapper from "./BaseUpdateModalWrapper";

const BaseUpdateModal = forwardRef(function BaseUpdateModal(
    { open, onChange, onClose, children, onBack, onSave, isLoading, disableSave, ...props },
    ref
) {
    return (
        <Modal
            {...props}
            ref={ref}
            open={open}
            onClose={onClose}
            onChange={onChange}
            onBack={onBack}
            onSave={onSave}
            isLoading={isLoading}
            disableSave={disableSave}
            styles={{
                ...(props.styles || {}),
                content: {
                    width: "50vw",
                    minWidth: "30rem",
                    ...(props?.styles?.content || {})
                }
            }}
            isForm
        >
            <BaseUpdateModalWrapper styles={{ inner: props?.styles?.updateStyleInner || {} }}>{children}</BaseUpdateModalWrapper>
        </Modal>
    );
});

BaseUpdateModal.propTypes = {
    open: PropTypes.bool,
    onChange: PropTypes.func,
    onClose: PropTypes.func,
    onBack: PropTypes.func,
    onSave: PropTypes.func,
    isLoading: PropTypes.bool,
    disableSave: PropTypes.bool,
    children: PropTypes.any,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object,
        body: PropTypes.object,
        title: PropTypes.object,
        form: PropTypes.object,
        footer: PropTypes.object,
        inner: PropTypes.object,
        updateStyleInner: PropTypes.object
    })
};

export default BaseUpdateModal;
