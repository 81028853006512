import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CreateTableHeaders from "./CreateTableHeaders";
import TableView from "../../../common/components/extra/table/TableView";
import { usePaginateLeaves } from "./hooks";
import ViewModal from "./ViewModal";
import { TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import { setCurrent } from "./slice";
import { useAppDispatch } from "../../../common/hooks/reduxHooks";
import UpdateModal from "./UpdateModal";

function EmployeeLeaves({ openAddModal, onAddModalClose }) {
    const [openViewModal, setOpenViewModal] = useState(false);
    const [openEditModal, setOpenEditModal] = useState(openAddModal);
    const [selected, setSelected] = useState(null);

    const [data, { initializing: isPaginating, fetch, tableConfig }] = usePaginateLeaves();

    const dispatch = useAppDispatch();

    const updateSelected = (row) => {
        setSelected(row);
        setOpenViewModal(!!row);
    };
    useEffect(() => {
        if (openAddModal && openAddModal !== openEditModal) {
            setOpenEditModal(openAddModal);
            setSelected(null);
            dispatch(setCurrent(null));
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        setOpenViewModal(true);
        setOpenEditModal(false);
    };

    const handleModalEdit = () => {
        setOpenViewModal(false);
        setOpenEditModal(true);
    };

    const handleFinish = (newData) => {
        fetch();
        setSelected(newData);
        typeof onAddModalClose === "function" && onAddModalClose();
    };

    const handleAction = (row, actionType) => {
        switch (actionType) {
            case TABLE_ACTION_TYPE_COMMON.PREVIEW:
                updateSelected(row);
                break;
            default:
                break;
        }
    };

    const handleViewModalClose = () => updateSelected(null);

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        setOpenEditModal(false);
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({ onAction: handleAction })}
                refetch={fetch}
                tableConfig={tableConfig}
                isLoading={isPaginating}
            />
            {openViewModal && (
                <ViewModal
                    id={selected?.id}
                    open={openViewModal}
                    onClose={handleViewModalClose}
                    onEdit={handleModalEdit}
                    onUpdateFinish={handleFinish}
                />
            )}
            {openEditModal && (
                <UpdateModal
                    id={selected?.id}
                    onBack={handleModalBack}
                    open={openEditModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                />
            )}
        </>
    );
}

EmployeeLeaves.propTypes = {
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func
};

export default EmployeeLeaves;
