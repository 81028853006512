import React from "react";
import PropTypes from "prop-types";
import { sanitizeWords } from "../../../../../common/utilities/helper";

const MAX_LEN_BEFORE_RESIZING = 21;

function FinalScoreRating({ finalRating }) {
    const labellen = (finalRating?.label || "").length;
    const doResize = labellen > MAX_LEN_BEFORE_RESIZING;

    return (
        <div className="final-score-rating">
            <div className="final-score-rating__inner">
                <div className="final-score-rating__label fade semi-bold" style={doResize ? { fontSize: "1.3em" } : {}}>
                    {(finalRating?.label && sanitizeWords(finalRating?.label)) || "Score"}
                </div>
                <div className="final-score-rating__value">
                    <h1>{finalRating?.score || "N/A"}</h1>
                </div>
            </div>
        </div>
    );
}

export default FinalScoreRating;

FinalScoreRating.propTypes = {
    value: PropTypes.arrayOf(
        PropTypes.shape({
            title: PropTypes.string,
            description: PropTypes.string,
            items: PropTypes.arrayOf(
                PropTypes.shape({
                    title: PropTypes.string,
                    description: PropTypes.string
                })
            )
        })
    ),
    ratings: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.string,
            level: PropTypes.number
        })
    ),
    finalRating: PropTypes.shape({
        score: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        label: PropTypes.string
    })
};
