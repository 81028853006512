import { configureStore } from "@reduxjs/toolkit";
import { setupListeners } from "@reduxjs/toolkit/query/react";
import { slices, apis } from "../features";

const reducer = {};
const middleware = [];

for (const key in slices) {
    if (Object.hasOwnProperty.call(slices, key)) {
        const slice = slices[key];
        reducer[slice.name] = slice.reducer;
    }
}
for (const key in apis) {
    if (Object.hasOwnProperty.call(apis, key)) {
        const api = apis[key];
        reducer[api.reducerPath] = api.reducer;
        middleware.push(api.middleware);
    }
}

export const store = configureStore({
    reducer,
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware({
            immutableCheck: false,
            serializableCheck: false
        }).concat(middleware)
});

export const AppDispatch = store.dispatch;
export const RootState = store.getState;

export const resetAll = (excludeSliceNames = []) => {
    Object.values(slices)
        .filter((slice) => !excludeSliceNames.includes(slice.name))
        .map((sli) => sli.actions)
        .forEach((actions) => typeof actions.reset === "function" && AppDispatch(actions.reset()));
};

setupListeners(store.dispatch);
