import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import ArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import ArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import Checkbox from "../Checkbox";
import Empty from "../Empty";
import { TABLE_ORDER } from "./const";
import useViewport from "../../../hooks/useViewport";
import { isScrollAtBottom } from "../../../utilities/helper";
import Loader from "../Loader";

const Table = forwardRef(function Table(
    {
        headers,
        data = [],
        onSort,
        activeSort = { sortBy: "createdAt", order: "DESC" },
        isFixedCheckBox,
        hasCheckbox,
        styles: tableStyles,
        isLoading,
        onScrollEnd,
        title,
        isRelativeEmpty,
        emptyProps,
        isLoadingMore,
        disabledIds = []
    },
    ref
) {
    const viewport = useViewport();
    const styles = { parent: {}, head: {}, body: {}, table: {}, ...(tableStyles || {}) };

    if (!data.length || isLoading) {
        if (isRelativeEmpty) {
            return (
                <div className="tk-table--empty" style={emptyProps.style}>
                    <Empty {...emptyProps} isLoading={isLoading} style={{}} />
                </div>
            );
        }
        return <Empty {...emptyProps} className="abs-center" style={{ margin: "auto", marginTop: "3rem" }} isLoading={isLoading} />;
    }

    const createRowStyle = (idx, column) => {
        const newheaders = overideWidthForFixedColMobile();
        const isFixed = column.fixed;
        const style = { ...(newheaders[idx].style || {}) };
        if (isFixed) {
            const newWidth = newheaders.reduce((prev, curr, headIdx) => {
                return (headIdx < idx && curr?.style?.width ? Number(curr.style.width.split("rem").shift()) : 0) + prev;
            }, 0);
            style.left = idx == 0 ? 0 : newWidth + "rem";
        }
        return style;
    };

    const overideWidthForFixedColMobile = () => {
        if (viewport.isMobile) {
            return headers.map((col) =>
                col.fixed && !col.noResponsive
                    ? {
                          ...col,
                          style: { ...(col.style || {}), width: "13rem" }
                      }
                    : col
            );
        }
        return headers;
    };

    const handleScroll = (e) => {
        if (isScrollAtBottom(e.target, 100) && !isLoadingMore) {
            typeof onScrollEnd === "function" && onScrollEnd();
        }
    };

    return (
        <>
            {title && <div className="tk-table-caption">{title}</div>}
            <div ref={ref} className="tk-table-container flex column" style={styles?.parent} onScroll={handleScroll}>
                <table className="tk-table" style={styles?.table}>
                    <thead style={styles?.head}>
                        <tr>
                            {hasCheckbox && (
                                <th className={`table-checkbox ${isFixedCheckBox ? "fixed-col" : ""}`}>
                                    <div className="item-wrapper">
                                        <Checkbox />
                                    </div>
                                </th>
                            )}
                            {headers.map((column, idx) => {
                                const currentActiveSort = activeSort.sortBy === column.sortKey;
                                const isAscActive = (currentActiveSort && activeSort.order === "ASC" && "tk-table__sort--active") || "";
                                const isDescActive = (currentActiveSort && activeSort.order === "DESC" && "tk-table__sort--active") || "";
                                return (
                                    <th
                                        key={column.key}
                                        style={createRowStyle(idx, column)}
                                        className={`${currentActiveSort ? "active-sort" : ""} ${column.fixed ? " fixed-col" : ""}`.trim()}
                                    >
                                        <div className="item-wrapper" style={column.wrapperStyle}>
                                            {column.label && (
                                                <div
                                                    style={
                                                        (column.center && { display: "flex", justifyContent: "center", width: "100%" }) ||
                                                        column.childStyle ||
                                                        {}
                                                    }
                                                >
                                                    <div className="head-item">
                                                        <span>{column.label}</span>
                                                        {column.sortKey && (
                                                            <div className="tk-table__sort">
                                                                {currentActiveSort && activeSort.order !== "ASC" ? (
                                                                    <ArrowUpIcon
                                                                        className={isAscActive}
                                                                        onClick={() => onSort(column.sortKey, "ASC")}
                                                                    />
                                                                ) : (
                                                                    <ArrowDownIcon
                                                                        className={isDescActive}
                                                                        onClick={() => onSort(column.sortKey, "DESC")}
                                                                    />
                                                                )}
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                        <div className="head-top-border"></div>
                                        <div className="head-right-border"></div>
                                        <div className="head-border"></div>
                                    </th>
                                );
                            })}
                        </tr>
                    </thead>
                    <tbody>
                        {data.map((row, idx) => (
                            <tr key={row.id || idx} className={`${disabledIds.includes(row.id) ? "disabled-row" : ""}`.trim()}>
                                {hasCheckbox && !disabledIds.includes(row.id) && (
                                    <td className={`table-checkbox ${isFixedCheckBox ? "fixed-col" : ""}`}>
                                        <div className="item-wrapper">
                                            <Checkbox />
                                        </div>
                                    </td>
                                )}
                                {headers.map((column, idx) => (
                                    <td key={column.key} style={createRowStyle(idx, column)} className={column.fixed ? "fixed-col" : ""}>
                                        <div className="item-wrapper" style={column.wrapperStyle}>
                                            <div className="flex" style={(column.center && { justifyContent: "center" }) || column.childStyle || {}}>
                                                {typeof column.render === "function" ? column.render(row[column.key], row) : row[column.key]}
                                            </div>
                                        </div>
                                        <div className="table-border"></div>
                                    </td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                </table>
                {isLoadingMore && (
                    <div style={{ position: "relative" }}>
                        <Loader style={{ width: "3rem", right: 0, display: "flex" }} centered absolute>
                            <span className="fade" style={{ marginTop: "3rem" }}>
                                Fetching More...
                            </span>
                        </Loader>
                    </div>
                )}
            </div>
        </>
    );
});

export default Table;

Table.propTypes = {
    disabledIds: PropTypes.arrayOf(PropTypes.number),
    data: PropTypes.array,
    headers: PropTypes.array,
    onSort: PropTypes.func,
    hasCheckbox: PropTypes.bool,
    isLoading: PropTypes.bool,
    isFixedCheckBox: PropTypes.bool,
    parentElClass: PropTypes.string,
    styles: PropTypes.shape({
        head: PropTypes.object,
        body: PropTypes.object,
        parent: PropTypes.object,
        table: PropTypes.object
    }),
    activeSort: PropTypes.shape({
        sortBy: PropTypes.string,
        order: PropTypes.oneOf(Object.values(TABLE_ORDER))
    }),
    onScrollEnd: PropTypes.func,
    isRelativeEmpty: PropTypes.bool,
    isLoadingMore: PropTypes.bool,
    title: PropTypes.any,
    emptyProps: PropTypes.shape({
        icon: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        message: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
        noicon: PropTypes.bool,
        isLoading: PropTypes.bool,
        className: PropTypes.string,
        loadingMessage: PropTypes.string,
        style: PropTypes.object,
        iconStyle: PropTypes.object,
        loaderStyle: PropTypes.object
    })
};
