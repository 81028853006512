import React from "react";
import PreviewIcon from "@mui/icons-material/Visibility";
import PersonIcon from "@mui/icons-material/SupervisedUserCircle";
import {
    isTimePassed,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toReadableFromDate,
    toReadablePhone,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import LetteredAvatar from "../../../common/components/extra/avatar/LetteredAvatar";
import { selectUser } from "../../common/slice";
import { ROLE_LEVEL, ROLE_TYPE, TABLE_ACTION_TYPE_COMMON } from "../../../common/utilities/const";
import FileWarning from "./FileWarning";
import MyTooltip from "../../../common/components/extra/Tooltip";
import DeptPositionCapsule from "./DeptPositionCapsule";
import SitePositionCapsule from "./SitePositionCapsule";
import MoreActions from "./MoreActions";
import ResetDeviceButton from "./ResetDeviceButton";
import { CUSTOM_ACTION } from "./const";

const HEADERS = {
    NAME: "name",
    DEPARTMENT: "department",
    DESIGNATION: "designation",
    DEVICE: "device",
    UPDATED_AT: "updatedAt",
    CREATED_AT: "createdAt",
    ACTIONS: "actions"
};

const HEADERS_TO_ARRAY = Object.values(HEADERS);

const CreateTableHeaders = ({ onAction, deletingIds = [] }) => {
    const user = useAppSelector(selectUser);
    const setting = user.Setting;

    const isDeleting = (row) => deletingIds.includes(row.id);

    return HEADERS_TO_ARRAY.map((head) => {
        const temp = { key: head, style: {} };
        switch (head) {
            case HEADERS.NAME: {
                temp.childStyle = {
                    alignItems: "center",
                    gap: "1rem",
                    margin: ".5rem 0"
                };
                temp.sortKey = "index1";
                temp.label = "Name";
                temp.style.width = "20rem";
                temp.fixed = true;
                temp.render = (_, row) => {
                    const fullName = row.first_name + " " + row.last_name;
                    const isAppSV = row.Role && row.Role?.type == ROLE_TYPE.EMPLOYEE && row.Role?.level == ROLE_LEVEL.HIGH;

                    const supervisingDepts = (row?.supervisingDepts || []).map((dept) => dept.title);
                    const managingDepts = (row?.managingDepts || []).map((dept) => dept.title);
                    const supervisingSites = (row?.CompanySites || []).filter((site) => site.isSupervisor).map((site) => site.title);
                    const managingSites = (row?.CompanySites || []).filter((site) => site.isManager).map((site) => site.title);

                    const hasDeptPos = !!row.supervisingDepts.length || !!row.managingDepts.length;
                    const hasSitePos = !!supervisingSites.length || !!managingSites.length;

                    return (
                        <>
                            <div className="flex gap-1" style={{ alignItems: "center", width: "100%" }}>
                                <LetteredAvatar name={fullName} src={row.photo} size={30} small />
                                <div className="flex column gap-05" style={{ flex: 1 }}>
                                    <div
                                        className="link-hover"
                                        style={{ fontWeight: "bold", width: "max-content" }}
                                        onClick={() => !isDeleting(row) && onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)}
                                    >
                                        {sanitizeWords(`${row.first_name} ${row.last_name}`)}
                                    </div>
                                    <div className="flex gap-05 wrap">
                                        {row.isOnShift && <Tag type={TAG_TYPE.ON_SHIFT} matchCapsule />}
                                        {isAppSV && <Tag type={TAG_TYPE.APP_SUPERVISOR} matchCapsule />}
                                        {hasDeptPos && (
                                            <DeptPositionCapsule
                                                supervising={supervisingDepts}
                                                managing={managingDepts}
                                                width={8.8}
                                                small
                                                noBorder
                                                noShadow
                                            />
                                        )}
                                        {hasSitePos && (
                                            <SitePositionCapsule
                                                supervising={supervisingSites}
                                                managing={managingSites}
                                                width={8.8}
                                                small
                                                noBorder
                                                noShadow
                                            />
                                        )}
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Email:</span>
                                        <span className="semi-bold">{row.email_address || renderNA("None")}</span>
                                    </div>
                                    <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                        <span className="small-font fade">Contact:</span>
                                        <span className="semi-bold">{toReadablePhone(row.mobile_number) || renderNA("None")}</span>
                                    </div>
                                </div>
                                <div className="flex column" style={{ marginBottom: "auto" }}>
                                    <FileWarning data={row} style={{ marginLeft: "auto" }} />
                                    {(row.directSupervisor || row.directManager) && (
                                        <MyTooltip
                                            className="primary-color"
                                            message={
                                                <div className="flex column gap-05">
                                                    <span>Reporting To:</span>
                                                    <div className="flex column">
                                                        {!!row.directSupervisor && (
                                                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                                                <span className="small-font">Direct Supervisor:</span>
                                                                <span className="semi-bold warning-color">
                                                                    {sanitizeWords(
                                                                        `${row.directSupervisor.first_name} ${row.directSupervisor.last_name}`
                                                                    )}
                                                                </span>
                                                            </div>
                                                        )}
                                                        {!!row.directManager && (
                                                            <div className="flex gap-05 wrap small-font" style={{ alignItems: "center" }}>
                                                                <span className="small-font">Direct Manager:</span>
                                                                <span className="semi-bold warning-color">
                                                                    {sanitizeWords(`${row.directManager.first_name} ${row.directManager.last_name}`)}
                                                                </span>
                                                            </div>
                                                        )}
                                                    </div>
                                                </div>
                                            }
                                        >
                                            <PersonIcon style={{ width: "2rem", height: "auto" }} />
                                        </MyTooltip>
                                    )}
                                </div>
                            </div>
                            {!isTimePassed(toTimeWithTimeZone(row.createdAt, setting.timezone), 1) && (
                                <div className="new-tag">
                                    <Tag className="green">NEW</Tag>
                                </div>
                            )}
                        </>
                    );
                };
                break;
            }
            case HEADERS.DEPARTMENT: {
                temp.label = "Department";
                temp.render = (_, row) => {
                    const department = row.CompanyDepartment;
                    return <div className="flex gap-05 semi-bold">{department ? sanitizeWords(department.title) : renderNA()}</div>;
                };
                break;
            }
            case HEADERS.DESIGNATION: {
                temp.label = "Designation";
                temp.render = (_, row) => {
                    const designation = row.CompanyDesignation;
                    return <div className="flex gap-05 semi-bold">{designation ? sanitizeWords(designation.title) : renderNA()}</div>;
                };
                break;
            }
            case HEADERS.DEVICE: {
                temp.sortKey = "index2";
                temp.label = "Device";
                temp.render = (_, row) => {
                    const device = row.device;
                    return (
                        <div className="flex gap-05">
                            {device ? (
                                <div className="flex column gap-05">
                                    <div className="">
                                        <span className="fade">Name:</span>&nbsp;
                                        <span className="semi-bold">{device?.device_name}</span>
                                    </div>
                                    <div className="">
                                        <span className="fade">Model:</span>&nbsp;
                                        <span className="semi-bold">{device?.model}</span>
                                    </div>
                                </div>
                            ) : (
                                <span className="small-font">{renderNA("No Registered Device")}</span>
                            )}
                        </div>
                    );
                };
                break;
            }
            case HEADERS.UPDATED_AT: {
                temp.sortKey = "updatedAt";
                temp.label = "Updated";
                temp.render = (_, row) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">
                            {sanitizeTimestamp(row.updatedAt, row.createdAt, setting.timezone) || renderNA()}
                        </span>
                    </div>
                );
                break;
            }
            case HEADERS.CREATED_AT: {
                temp.sortKey = "createdAt";
                temp.label = "Created";
                temp.render = (val) => (
                    <div className="flex column gap-05 overflow-hidden fade">
                        <span className="text-ellipsis small-font">{toReadableFromDate(val, setting.timezone)}</span>
                    </div>
                );
                break;
            }
            case HEADERS.ACTIONS: {
                temp.childStyle = {
                    display: "flex",
                    gap: ".5rem",
                    justifyContent: "flex-end",
                    alignItems: "center",
                    paddingRight: "1rem",
                    overflow: "unset"
                };
                temp.render = (_, row) => {
                    return (
                        <>
                            <PreviewIcon onClick={() => !isDeleting(row) && onAction(row, TABLE_ACTION_TYPE_COMMON.PREVIEW)} />
                            <ResetDeviceButton
                                id={row.id}
                                device={{
                                    name: row?.device?.device_name,
                                    model: row?.device?.model
                                }}
                                onResetDevice={() => row.device && !isDeleting(row) && onAction(row, CUSTOM_ACTION.RESET_DEVICE)}
                                disabled={!row.device}
                                isIcon
                            />
                            <MoreActions row={row} onAction={(!isDeleting(row) && onAction) || null} isOnShift={row.isOnShift} />
                        </>
                    );
                };
                break;
            }
            default:
                break;
        }
        return temp;
    });
};

export default CreateTableHeaders;
