import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import {
    TOAST_TYPE,
    createToast,
    isObjectEqualWithBase,
    sanitizeDateWithLocalDateToOtherTimezoneFromObject,
    sanitizeDatesWithProperTimeConversionFromObject
} from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, setCurrent, setState, selectLoading, setLoading, selectCompanyHolidaysData, defaultConfig } from "./slice";
import {
    useCreateCompanyHolidaysMutation,
    useDeleteCompanyHolidaysMutation,
    useGetCompanyHolidaysMutation,
    useLoadAllCompanyHolidaysMutation,
    useUpdateCompanyHolidaysMutation
} from "./api";
import { COMPANY_HOLIDAY_LEVEL, DATE_FIELDS } from "./const";
import { FIELD } from "./const";
import { selectUserSetting } from "../../common/slice";
import { createWorkShiftInfo } from "../employeeWorkShift/helper";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";

const { NAME, TYPE, LEVEL, CATEGORY, START_DATE, END_DATE, IS_YEARLY, NOTES, WORK_SHIFT, WORK_SITES, WORK_SHIFTS, WORK_TYPES } = FIELD;

export const useGetCompanyHolidays = (id, callback) => {
    const [isMounted, setMounted] = useState(false);
    const [fetching, setFetching] = useState(!!id);
    const [getDetails] = useGetCompanyHolidaysMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const companyHolidaysData = useAppSelector(selectCompanyHolidaysData);
    // this means it only have the id key inside means we have to fetch more
    const isInitial = current && Object.keys(current).length == 1;
    const timezone = setting.timezone;

    const createVars = (data) => {
        if (!data) return {};

        return {
            isWorkTypeAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_TYPE,
            isWorkSiteAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_SITE,
            isWorkShiftAffected: data?.level == COMPANY_HOLIDAY_LEVEL.BY_WORK_SHIFT,
            workShift: data[WORK_SHIFT] && createWorkShiftInfo(data[WORK_SHIFT], timezone)
        };
    };

    const fetch = async () => {
        if (!id) return;

        try {
            if (!isInitial && current.id === id) {
                setFetching(false);
                return Promise.resolve();
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch holiday. Please try again later");
            }
            // make sure the timezone of the selected dates is inline with the company timezone
            const temp = sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            dispatch(setCurrent(temp));
            callback?.(temp);
            return temp;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: companyHolidaysData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    return [current, { isLoading: fetching, config: createVars(current), update: updateCurrent, fetch }];
};

export const usePaginateCompanyHolidays = ({ readOnly } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, tableConfig, onSort, onUpdate, ...rest }] = usePaginateFetch(
        useLoadAllCompanyHolidaysMutation,
        {
            redux: {
                dataSelector: selectCompanyHolidaysData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config, option) => {
        try {
            const response = await load(config, option);
            if (response.error) {
                throw new Error("Failed to fetch holidays. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { initializing, isLoading, fetch, update: onUpdate, onSearch, onFilter, onSort, tableConfig, ...rest }];
};

export const useUpsertCompanyHolidays = (updateId, callback) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(null);
    const [form, setForm] = useState({
        [NAME]: "",
        [TYPE]: "",
        [LEVEL]: "",
        [CATEGORY]: "",
        [START_DATE]: "",
        [END_DATE]: "",
        [IS_YEARLY]: "",
        [NOTES]: "",
        [WORK_SHIFT]: "",
        [WORK_SITES]: "",
        [WORK_SHIFTS]: "",
        [WORK_TYPES]: ""
    });

    const [data, { isLoading }] = useGetCompanyHolidays(updateId, callback);

    const [create, { isLoading: createIsLoading }] = useCreateCompanyHolidaysMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateCompanyHolidaysMutation();
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const createVars = () => {
        const workshift = (data && { id: data?.workshift || "" }) || "";
        return { workshift };
    };

    const upsert = async () => {
        let result = null;
        try {
            let clonedform = cloneDeep(form);

            clonedform[WORK_SHIFT] = clonedform[WORK_SHIFT]?.id;
            clonedform[WORK_SITES] = clonedform[WORK_SITES].map((rec) => rec.id);
            clonedform[WORK_SHIFTS] = clonedform[WORK_SHIFTS].map((rec) => rec.id);
            clonedform[WORK_TYPES] = clonedform[WORK_TYPES].map((rec) => rec.id);

            if (!clonedform[END_DATE]) {
                clonedform[END_DATE] = null;
            }

            // make sure the timezone of the selected dates is inline with the company timezone
            clonedform = sanitizeDateWithLocalDateToOtherTimezoneFromObject(clonedform, timezone, DATE_FIELDS);

            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (result.data) {
                if (result.data?.data) {
                    createToast(`Company holiday ${isCreate ? "created" : "updated"} succesfully.`, TOAST_TYPE.SUCCESS);
                } else {
                    createToast(result.data.message, TOAST_TYPE.SUCCESS);
                }
            }
            return sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(
                `Failed to ${!isCreate ? "update" : "create"} Company holiday. ${error?.message || "Please try again later or contact support."} `,
                TOAST_TYPE.ERROR
            );
            return { error };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    useEffect(() => {
        const temp = {
            [NAME]: current?.[NAME] || form[NAME],
            [TYPE]: current?.[TYPE] || form[TYPE],
            [LEVEL]: current?.[LEVEL] || form[LEVEL],
            [CATEGORY]: current?.[CATEGORY] || form[CATEGORY],
            [START_DATE]: current?.[START_DATE] || form[START_DATE],
            [END_DATE]: current?.[END_DATE] || form[END_DATE],
            [IS_YEARLY]: current ? current?.[IS_YEARLY] : false,
            [NOTES]: current?.[NOTES] || form[NOTES],
            [WORK_SHIFT]: current?.[WORK_SHIFT]?.id ? { ...current?.[WORK_SHIFT], value: current?.[WORK_SHIFT]?.id } : form[WORK_SHIFT],
            [WORK_SITES]: (current?.[WORK_SITES] || form[WORK_SITES] || []).map((record) => ({ ...record, value: record.id })),
            [WORK_SHIFTS]: (current?.[WORK_SHIFTS] || form[WORK_SHIFTS] || []).map((record) => ({ ...record, value: record.id })),
            [WORK_TYPES]: (current?.[WORK_TYPES] || form[WORK_TYPES] || []).map((record) => ({ ...record, value: record.id }))
        };
        setForm(temp);
        setOld(temp);
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingHoliday: isLoading,
            isUpserting: createIsLoading || updateIsLoading,
            config: createVars(),
            old,
            hasChanges: !!(old && !isCreate && !isObjectEqualWithBase(form, old))
        }
    ];
};

export const useDeleteCompanyHolidays = () => {
    const dispatch = useAppDispatch();

    const [deleteHoliday] = useDeleteCompanyHolidaysMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteHoliday({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete holiday.");
            }
            createToast("Holiday successfully deleted.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};
