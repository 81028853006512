import React, { useCallback, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import isFinite from "lodash/isFinite";
import isEqual from "lodash/isEqual";
import isNaN from "lodash/isNaN";

function InputNumber({ value, constraint, onChange, ...inputProps }) {
    const ref = useRef(null);
    const [, setFocused] = useState(false);
    const [tempVal, setTempVal] = useState(value ?? "");

    useEffect(() => {
        if (!isEqual(value, tempVal)) {
            setTempVal(value ?? "");
        }
    }, [value]);

    const validateConstraint = useCallback(
        (current) => {
            const min = constraint?.min;
            const max = constraint?.max;

            const parsedCurrent = parseFloat(current);
            const hasMin = isFinite(min);
            const hasMax = isFinite(max);

            const validMin = hasMin ? parsedCurrent >= min : true;
            const validMax = hasMax ? parsedCurrent <= max : true;

            if (current.startsWith("0") && current.length > 1 && current[1] !== ".") {
                return current.replace(/^0+/, "");
            }

            if (validMin && validMax) {
                return current;
            } else {
                return value ?? "";
            }
        },
        [constraint, value]
    );

    const handleChange = (e) => {
        const inputValue = e.target.value;
        const isNumber = !isNaN(inputValue) && !isNaN(parseFloat(inputValue));
        if (isNumber || inputValue === "" || inputValue == "-") {
            setTempVal(inputValue);
        }
        const validatedValue = validateConstraint(e.target.value);
        if (!isEqual(validatedValue, tempVal)) {
            onChange?.({ target: { name: inputProps.name, value: validatedValue } });
        }
    };

    const handleBlur = (e) => {
        const validatedValue = validateConstraint(e.target.value);
        setTempVal(validatedValue);
        onChange?.({ target: { name: inputProps.name, value: validatedValue } });
    };

    const handleFocus = () => {
        setFocused(true);
    };

    return (
        <input
            ref={ref}
            {...inputProps}
            type="text"
            onChange={handleChange}
            value={isNaN(tempVal) ? "" : tempVal}
            onBlur={handleBlur}
            onFocus={handleFocus}
        />
    );
}

InputNumber.propTypes = {
    onChange: PropTypes.func,
    constraint: PropTypes.shape({
        max: PropTypes.number,
        min: PropTypes.number
    }),
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default InputNumber;
