import React, { useState } from "react";
import WorkHistories from "../../features/company/employeeWorkHistories/WorkHistories";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { selectLoading } from "../../features/company/employeeWorkHistories/slice";
import { selectUser } from "../../features/common/slice";
import PageLayout from "../../common/components/layout/PageLayout";
import Filter from "../../features/company/employeeWorkHistories/Filter";
import { usePaginateWorkHistories } from "../../features/company/employeeWorkHistories/hooks";
import { getFilterCount } from "../../common/utilities/helper";

function EmployeesWorkHistories() {
    const [openAddModal, setOpenAddModal] = useState(false);

    const [, { isLoading, onSearch, onFilter, tableConfig, isDoingActions }] = usePaginateWorkHistories({ readOnly: true });

    const loading = useAppSelector(selectLoading);
    const user = useAppSelector(selectUser);

    const handleAdd = () => setOpenAddModal(true);

    return (
        <PageLayout
            className="tk-employees-work-history"
            title="Manage Work History"
            isLoading={loading}
            search={{ onSearch, value: tableConfig.search, placeholder: "Search employee..." }}
            onAdd={handleAdd}
            filter={<Filter onFilter={onFilter} isLoading={isLoading} />}
            styles={{
                body: {
                    height: "calc(100vh - 20.5rem)"
                }
            }}
            activeFilterCount={getFilterCount(tableConfig.filter)}
            hasFilter
        >
            <WorkHistories
                user={user}
                openAddModal={openAddModal}
                onAddModalClose={() => setOpenAddModal(false)}
                parentIsDoingActions={isDoingActions}
            />
        </PageLayout>
    );
}

export default EmployeesWorkHistories;
