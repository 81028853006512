import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import Button from "../../../common/components/extra/Button";
import { createClass, createToast, TOAST_TYPE } from "../../../common/utilities/helper";
import { BASE_CLASS, FINAL_STATUSES, SUBMITTED_FORM_ACTION_TYPE, SUBMITTED_FORM_CLASS, SUBMITTED_FORM_STATUS } from "./const";
import { FORM_CATEGORY, USER_LEVEL } from "../companyForms/const";
import { useUpdateSubmittedFormStatus } from "./hooks";
import UpdateStatusModal from "./UpdateStatusModal";
import UpsertHistoryModal from "../employeeWorkHistories/UpdateModal";
import UpsertLeaveModal from "../employeeLeaves/UpdateModal";
import { useGetWorkHistory } from "../employeeWorkHistories/hooks";
import { FIELD } from "../employeeLeaves/const";

function AttemptCompleteButton({
    id,
    siteId,
    disabled,
    targetApprovalUserLevel,
    formClass,
    currentFormStatus,
    onComplete,
    config,
    employee,
    fields
}) {
    const [openModal, setOpenModal] = useState(false);
    const [toProcessModal, setToProcessModal] = useState(null);

    const [updateStatus, isUpdating] = useUpdateSubmittedFormStatus();
    const [existingWHRecord, { isLoading, fetch }] = useGetWorkHistory(config.toUpdateHistoryId, { disableMountFetch: true });

    const isCompleted = currentFormStatus === SUBMITTED_FORM_STATUS.COMPLETED;
    const disableAction = disabled || isUpdating || isCompleted;

    const handleUpdateStatus = async (form = {}) => {
        try {
            const result = await updateStatus(id, {
                status: SUBMITTED_FORM_STATUS.COMPLETED,
                ...form
            });
            if (result.error) {
                throw new Error(result.error);
            }
            onComplete?.();
            return result;
        } catch (error) {
            createToast("Failed to update status, please try again later", TOAST_TYPE.ERROR);
        } finally {
            setToProcessModal(null);
        }
    };

    const processInfo = useMemo(() => {
        return {
            isNormal: formClass === SUBMITTED_FORM_CLASS.NORMAL,
            isWorkHistory: formClass === SUBMITTED_FORM_CLASS.WORK_HISTORY,
            isLeave: formClass === SUBMITTED_FORM_CLASS.LEAVE
        };
    }, [targetApprovalUserLevel, formClass]);

    const leaveInfo = useMemo(() => {
        if (!processInfo.isLeave) {
            return null;
        }

        const getFieldName = (dbName) => {
            const field = fields.find((field) => field.dbName == dbName);
            if (!field) {
                return null;
            }

            return field.value;
        };

        const date = getFieldName("date");

        return {
            [FIELD.REASON]: getFieldName(FIELD.REASON),
            [FIELD.START_DATE]: date && date[0],
            [FIELD.END_DATE]: date && date[1],
            [FIELD.LEAVE_TYPE]: getFieldName(FIELD.LEAVE_TYPE)?.value
        };
    }, [processInfo.isLeave]);

    if (targetApprovalUserLevel !== USER_LEVEL.THREE || FINAL_STATUSES.includes(currentFormStatus)) {
        return <></>;
    }

    return (
        <div className={createClass("__cancel flex gap-03 align-center", BASE_CLASS)}>
            <Button
                className="primary"
                onClick={() => !disableAction && (processInfo.isNormal ? setOpenModal(true) : setToProcessModal({ class: formClass }))}
                disabled={disableAction}
                small
                mini
            >
                {isCompleted ? "Completed" : processInfo.isNormal ? "Set as Complete" : "Finalize Process"}
            </Button>
            {openModal && (
                <UpdateStatusModal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    actionType={SUBMITTED_FORM_ACTION_TYPE.COMPLETE}
                    onConfirm={(form) => !disableAction && handleUpdateStatus(form)}
                />
            )}
            {toProcessModal && processInfo.isWorkHistory && (
                <UpsertHistoryModal
                    siteId={siteId}
                    key={isLoading}
                    open={toProcessModal.class == SUBMITTED_FORM_CLASS.WORK_HISTORY}
                    onClose={() => setToProcessModal(null)}
                    data={existingWHRecord || config.history}
                    newDate={!config.toUpdateHistoryId && config.historyDate}
                    editType={config.historyEditType}
                    employee={employee}
                    saveLabel="Save & Complete"
                    onExistingRecordClick={(record) => fetch(record.id)}
                    onSubmittedFormUpdateStatus={(form) => !disableAction && handleUpdateStatus(form)}
                    submittedFormId={id}
                    noSuccessMessage
                />
            )}
            {toProcessModal && processInfo.isLeave && (
                <UpsertLeaveModal
                    open={toProcessModal.class == SUBMITTED_FORM_CLASS.LEAVE}
                    onClose={() => setToProcessModal(null)}
                    saveLabel="Save & Complete"
                    onSubmittedFormUpdateStatus={(form) => !disableAction && handleUpdateStatus(form)}
                    fromSubmittedForm={{
                        [FIELD.EMPLOYEE]: employee,
                        [FIELD.REASON]: leaveInfo[FIELD.REASON],
                        [FIELD.START_DATE]: leaveInfo[FIELD.START_DATE],
                        [FIELD.END_DATE]: leaveInfo[FIELD.END_DATE],
                        [FIELD.LEAVE_TYPE]: leaveInfo[FIELD.LEAVE_TYPE]
                    }}
                    optionals={[FIELD.PROOF_FILE]}
                    readonlyFields={[FIELD.EMPLOYEE, FIELD.LEAVE_TYPE, FIELD.REASON, FIELD.START_DATE, FIELD.END_DATE]}
                />
            )}
        </div>
    );
}

export default AttemptCompleteButton;

AttemptCompleteButton.propTypes = {
    id: PropTypes.number,
    currentFormStatus: PropTypes.oneOf(Object.values(SUBMITTED_FORM_STATUS)),
    targetApprovalUserLevel: PropTypes.oneOf(Object.values(USER_LEVEL)),
    formCategory: PropTypes.oneOf(Object.values(FORM_CATEGORY)),
    formClass: PropTypes.oneOf(Object.values(SUBMITTED_FORM_CLASS)),
    disabled: PropTypes.bool,
    onComplete: PropTypes.func,
    config: PropTypes.object,
    employee: PropTypes.object,
    siteId: PropTypes.number,
    fields: PropTypes.array
};
