import React, { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import isEqual from "lodash/isEqual";
import isNull from "lodash/isNull";
import { TOAST_TYPE, createEditingInfo, createToast, isObjectEqualWithBase, sanitizeWords } from "../../../common/utilities/helper";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { SORT_ORDER, WORK_TYPE } from "../../../common/utilities/const";
import { defaultConfig, selectCurrent, selectLoading, selectTableConfig, selectWorkDetailsData, setCurrent, setState } from "./slice";
import { FIELDS, FORM_FIELDS } from "./const";
import {
    useCreateWorkDetailsMutation,
    useDeleteWorkDetailsMutation,
    useGetWorkDetailsMutation,
    useLoadAllLazyWorkDetailsMutation,
    useLoadAllWorkDetailsMutation,
    useUpdateWorkDetailsMutation
} from "../employeeWorkDetails/api";
import Tag from "../../../common/components/extra/Tag";
import Tooltip from "../../../common/components/extra/Tooltip";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";

const { NAME, TYPE, COMPANY_SITES } = FIELDS;

export const useGetWorkDetails = (id, callback) => {
    const [current, { isInitial, isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetWorkDetailsMutation,
                selectData: selectWorkDetailsData,
                selectCurrent,
                setCurrent,
                setState
            },
            asPostQuery: true
        },
        { runOnMount: true, onMount: callback }
    );

    const setting = useAppSelector(selectUserSetting);

    const createVars = () => {
        if (!current) {
            return {};
        }
        return {
            isIncomplete: !current?.result?.[COMPANY_SITES.name]?.length
        };
    };

    const checkAllowEditTime = () => {
        if (!current) {
            return {};
        }

        const editInfo = createEditingInfo({
            start: current.before,
            end: current.after,
            timezone: setting.timezone
        });

        return {
            isAllowed: !current.hasSites || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [
        current,
        {
            isInitial,
            isLoading,
            update: updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch,
            config: createVars(),
            allowEditInfo: checkAllowEditTime()
        }
    ];
};

export const useUpsertWorkDetails = (updateId, callback) => {
    const isCreate = !updateId;

    const [current, { isLoading: isGettingRecord, allowEditInfo }] = useGetWorkDetails(updateId, callback);
    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old }] = useUpsertRecord(
        {
            updateId,
            defaultForm: FORM_FIELDS,
            current: current?.result,
            isGettingRecord,
            rtk: {
                useCreateMutation: useCreateWorkDetailsMutation,
                useUpdateMutation: useUpdateWorkDetailsMutation,
                setCurrent
            }
        },
        {
            onBeforeUpsert: async (obj = {}) => {
                let clonedform = cloneDeep(obj);
                const siteIds = clonedform.CompanySites.map((cs) => cs.id);
                const dataSiteIds = (old && old.CompanySites.map((cs) => cs.id)) || [];
                clonedform.CompanySites && delete clonedform.CompanySites;
                if (!isEqual(siteIds, dataSiteIds)) {
                    clonedform.siteIds = siteIds;
                } else {
                    clonedform.siteIds = dataSiteIds;
                }
                return clonedform;
            }
        }
    );

    const createVars = () => {
        return {
            isGettingRecord,
            isLoading: isCreating || isUpdating,
            invalidSiteCount:
                form?.[TYPE.name] && form?.[TYPE.name] !== WORK_TYPE.PROJECT && form?.[COMPANY_SITES.name] && form?.[COMPANY_SITES.name].length > 1,
            isIncomplete: !isCreate && !current?.result?.[COMPANY_SITES.name]?.length
        };
    };

    const getHasChanges = () => {
        const newForm = cloneDeep(form);
        const newOld = cloneDeep(old);
        newForm?.[FIELDS.COMPANY_SITES.name] && (newForm[FIELDS.COMPANY_SITES.name] = newForm[FIELDS.COMPANY_SITES.name].map((site) => site.id));
        newOld?.[FIELDS.COMPANY_SITES.name] && (newOld[FIELDS.COMPANY_SITES.name] = newOld[FIELDS.COMPANY_SITES.name].map((site) => site.id));
        return !!(old && !isCreate && !isObjectEqualWithBase(JSON.stringify(newForm), JSON.stringify(newOld)));
    };

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting,
            isCreating,
            isUpdating,
            config: createVars(),
            old,
            hasChanges: getHasChanges(),
            isEditAllowed: isCreate || allowEditInfo.isAllowed,
            allowedTime: allowEditInfo.allowedTime
        }
    ];
};

export const useGetWorkDetailsByIds = (ids = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getDetails, { isLoading }] = useGetWorkDetailsMutation();

    const updateObject = (newObj = {}) =>
        setObject((prev) => ({
            ...prev,
            ...newObj
        }));

    const fetch = async () => {
        try {
            const response = await getDetails({
                body: { ids }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata, mounted: true });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [], mounted: true });
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return [object.data, isLoading, { isMounted: object.mounted }];
};

export const usePaginateWorkDetails = ({ readOnly } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, onSort, onUpdate, tableConfig, ...rest }] = usePaginateFetch(
        useLoadAllWorkDetailsMutation,
        {
            redux: {
                dataSelector: selectWorkDetailsData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config = {}, option) => {
        try {
            const response = await load(config, option);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { initializing, isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch, ...rest }];
};

export const useLazyWorkDetails = ({
    defaultValue,
    initializing,
    isReadableSelected,
    onMount = () => {},
    isFilter,
    allowInUse,
    byEmployeeId
} = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: NAME.name, order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllLazyWorkDetailsMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter(() => {
                      return isReadableSelected;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const title = sanitizeWords(temp[NAME.name]);
        temp.value = temp.id;

        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis bold" message={title}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            const inUse = !allowInUse && temp.type !== WORK_TYPE.PROJECT && !!temp?.CompanySites?.length;
            temp.isDisabled = inUse;
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title}>
                        {title}
                    </Tooltip>
                    {temp[TYPE.name] && <Tag className="yellow">{temp[TYPE.name]}</Tag>}
                    {inUse && (
                        <Tag
                            className="red"
                            tooltip={{
                                message: "This work type is already in use by another site. Only the 'Project' work type can be used multiple times."
                            }}
                        >
                            NOT AVAILABLE
                        </Tag>
                    )}
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    byEmployeeId,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            sort,
            createRowItem
        }
    ];
};

export const useDeleteWorkDetails = () => {
    const [deleteWorkDetails] = useDeleteWorkDetailsMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        try {
            const response = await deleteWorkDetails({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work shift.");
            }
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [remove, isLoading];
};
