import React from "react";
import PropTypes from "prop-types";
import ReactSelect from "react-select";
import { FixedSizeList as List } from "react-window";

const DEF_ITEM_HEIGHT = 40;

function SelectVirtual({ options = [], placeholder = "Select an option", ...props }) {
    return (
        <ReactSelect
            {...props}
            options={options}
            placeholder={options.length === 0 ? "No options available" : placeholder}
            components={{
                Option: CustomOption,
                MenuList: CustomMenuList
            }}
            isDisabled={options.length === 0} // Disable when no options
        />
    );
}

const CustomOption = (props) => {
    const { data, innerRef, innerProps } = props;
    return (
        <div ref={innerRef} {...innerProps} style={{ padding: "10px", cursor: "pointer" }}>
            {data.label}
        </div>
    );
};

export class CustomMenuList extends React.Component {
    static propTypes = {
        options: PropTypes.array.isRequired,
        children: PropTypes.node.isRequired,
        maxHeight: PropTypes.number.isRequired,
        getValue: PropTypes.func.isRequired
    };

    static defaultProps = {
        options: [],
        maxHeight: 200
    };

    render() {
        const { options, children, maxHeight, getValue } = this.props;

        // Ensure we have a valid array of children
        const validChildren = React.Children.toArray(children);

        // Calculate the initial offset
        const [value] = getValue();
        const valueIndex = options.indexOf(value);
        const initialOffset = valueIndex > -1 ? valueIndex * DEF_ITEM_HEIGHT : 0;

        const createClassname = (row) => {
            let str = "virtual-select-list__item ";
            const val = (this.props?.getValue?.() || []).map((option) => option.value);

            if (val.includes(row.value)) {
                str += "virtual-select-list__item--is-selected ";
            }

            return str;
        };

        // Handle empty options gracefully
        if (validChildren.length === 0) {
            return <div style={{ padding: "10px", textAlign: "center" }}>No options available</div>;
        }

        return (
            <span className="virtual-select-list">
                <List
                    height={Math.min(maxHeight, validChildren.length * DEF_ITEM_HEIGHT)} // Prevent NaN height
                    itemCount={validChildren.length}
                    itemSize={DEF_ITEM_HEIGHT}
                    initialScrollOffset={initialOffset}
                >
                    {({ index, style }) => {
                        const curval = validChildren[index].props;
                        return (
                            <div className={`${createClassname(curval)}`} style={style}>
                                {validChildren[index]}
                            </div>
                        );
                    }}
                </List>
            </span>
        );
    }
}
export default SelectVirtual;

SelectVirtual.propTypes = {
    options: PropTypes.array,
    placeholder: PropTypes.string
};

CustomOption.propTypes = {
    data: PropTypes.object.isRequired,
    innerRef: PropTypes.func,
    innerProps: PropTypes.object
};
