import React, { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import isObject from "lodash/isObject";
import isUndefined from "lodash/isUndefined";
import {
    CONTRACT_STATUS,
    ROLE_LEVEL,
    ROLE_TYPE,
    SORT_ORDER,
    STANDARD_DATE_FORMAT,
    STANDARD_DATE_FORMAT_WITH_TIME
} from "../../../common/utilities/const";
import {
    TOAST_TYPE,
    assignDefaultToBase,
    createConfirmAlert,
    createFullName,
    createToast,
    isFileObject,
    isObjectEqualWithBase,
    isPhoneValid,
    sanitizeDateWithLocalDateToOtherTimezoneFromObject,
    sanitizeDatesWithProperTimeConversionFromObject,
    sanitizeObject,
    sanitizeWords,
    transformStringToObject
} from "../../../common/utilities/helper";
import {
    useCreateEmployeeMutation,
    useDeleteEmployeeMutation,
    useEmployeeDetailsMutation,
    useEmployeeUploadFilesMutation,
    useLoadEmployeeRolesMutation,
    useLoadEmployeesLazyMutation,
    useLoadEmployeesMutation,
    useUpdateEmployeeDeptMutation,
    useUpdateEmployeeFilesMutation,
    useUpdateEmployeeMutation
} from "./api";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser, selectUserSetting, updateUser } from "../../common/slice";
import { createDefaultCheckId } from "./helper";
import {
    ASSIGN_DEPARTMENT_FIELDS,
    ASSIGN_TYPE,
    DATE_FIELDS,
    DATE_TIME_FIELDS,
    DEFAULT_DEPARTMENT_FIELDS,
    DEFAULT_FIELDS,
    DEFAULT_FILE_FIELDS,
    LAZY_REQ_TYPE,
    PERSONAL_FIELDS
} from "./const";
import LazyTableHeaders from "./LazyTableHeaders";
import {
    defaultFilter,
    selectCurrent,
    selectData,
    selectEmployeeRoles,
    selectFilter,
    selectLoading,
    selectTableConfig,
    setCurrent,
    setEmployeeRoles,
    setState,
    updateData,
    defaultConfig,
    DEFAULT_SIZE,
    LOAD_MORE_OFFSET
} from "./slice";
import useFetchCountries from "../../../common/hooks/useFetchCountries";
import { useUpsertDepartments } from "../departments/hooks";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { useFetchRecord, useRefresh } from "../../common/hooks";
import Tooltip from "../../../common/components/extra/Tooltip";

export const usePaginateEmployees = ({ readOnly } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, onSort, onUpdate, resetTableConfig, tableConfig, ...rest }] = usePaginateFetch(
        useLoadEmployeesMutation,
        {
            redux: {
                dataSelector: selectData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const filter = useAppSelector(selectFilter) || {};
    const isFilterOnDefault = isEqual(defaultFilter, filter);

    const fetch = async (config = {}, option) => {
        try {
            const response = await load(config, option);
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [
        data,
        {
            fetch,
            update: onUpdate,
            isLoading,
            initializing,
            onSort,
            onFilter,
            onSearch,
            reset: resetTableConfig,
            isFilterOnDefault,
            tableConfig,
            ...rest
        }
    ];
};

export const useLazyEmployees = ({
    initializing,
    siteId,
    allowOnShift,
    excludeIds = [],
    withoutShift,
    allowSelectOnShift,
    defaultValue,
    isFilter,
    workShiftId,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    getExpiring,
    onMount = () => {},
    showDepartment,
    sortBy,
    sortOrder,
    allowInactiveContractSelection,
    isReadableSelected
} = {}) => {
    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: sortBy || "index1", order: sortOrder || SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter((item) => {
                      const currentlyOnShiftOnThisSite =
                          siteId && item.onShiftSiteId && !allowOnShift && item.isOnShift && item.onShiftSiteId == siteId;
                      return isReadableSelected || currentlyOnShiftOnThisSite;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const fullName = createFullName(temp?.first_name, temp?.last_name) || "";

        temp.value = temp.id || "";
        temp.id = !temp.id ? temp.value : temp.id;
        temp.index1 = temp?.index1 || "";

        if (isFilter) {
            temp.fullName = fullName || "";
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden small-font">
                    <Tooltip element="span" className="text-ellipsis bold" message={fullName} style={{ maxWidth: "10rem" }}>
                        {fullName}
                    </Tooltip>
                </div>
            );
        } else {
            const isOnShift = !allowOnShift && temp.isOnShift;
            const deptTitle = temp.CompanyDepartment && sanitizeWords(temp.CompanyDepartment.title);
            const currentlyOnShiftOnThisSite = siteId && temp.onShiftSiteId && !allowOnShift && temp.isOnShift && temp.onShiftSiteId == siteId;

            temp.isDisabled =
                (!allowSelectOnShift && currentlyOnShiftOnThisSite) ||
                (!isFilter && !allowInactiveContractSelection && row.contractStatus !== CONTRACT_STATUS.ACTIVE);

            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    {!allowOnShift && <Tag type={isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} maxContent matchCapsule />}
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={fullName} style={{ paddingRight: "4px" }}>
                        {fullName}
                    </Tooltip>
                    {showDepartment && deptTitle && (
                        <Tag style={{ whiteSpace: "nowrap" }} matchCapsule>
                            {deptTitle}
                        </Tag>
                    )}
                    {row.contractStatus && row.contractStatus !== CONTRACT_STATUS.ACTIVE && (
                        <Tag className="dark-gray" maxContent matchCapsule>
                            {sanitizeWords(row.contractStatus)}
                        </Tag>
                    )}
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }

        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        let extraPath = "";

        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        if (getExpiring) {
            extraPath = LAZY_REQ_TYPE.EXPIRING;
        }

        try {
            const response = await load({
                extraPath,
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    excludeIds,
                    withoutShift,
                    workShiftId,
                    excludeWorkShiftId,
                    excludeWorkDetailIds,
                    workDetailIds,
                    getExpiring,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data || [];
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = (config = {}) => fetch(config, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset()
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            initializing: fetching,
            isLoading,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            sort,
            createRowItem
        }
    ];
};

export const useLazyTableEmployees = ({
    allowOnShift,
    excludeIds = [],
    withoutShift,
    initializing,
    allowSelectOnShift,
    defaultValue,
    workShiftId,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    getExpiring,
    onMount,
    showDepartment,
    sortBy,
    sortOrder
} = {}) => {
    const LOAD_MORE_OFFSET = 10;
    const DEFAULT_SIZE = 20;

    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: sortBy || "index1", order: sortOrder || SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const isCurrentValue = (id) => (Array.isArray(defaultValue) ? defaultValue.includes(id) : defaultValue == id);

    const createRowItem = (row, defaultItems = []) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const fullName = createFullName(temp?.first_name, temp?.last_name) || "";

        temp.value = temp.id || "";
        temp.id = !temp.id ? temp.value : temp.id;
        temp.index1 = temp?.index1 || "";

        const isOnShift = !allowOnShift && temp.isOnShift;
        const deptTitle = temp.CompanyDepartment && sanitizeWords(temp.CompanyDepartment.title);

        temp.isFixed =
            allowSelectOnShift && !isCurrentValue(temp.id) ? false : Array.isArray(defaultItems) && defaultItems.includes(row.id) && isOnShift;
        temp.isDisabled = allowSelectOnShift ? false : Array.isArray(defaultItems) && defaultItems.includes(row.id) && isOnShift;

        temp.label = (
            <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                {!allowOnShift && <Tag type={isOnShift ? TAG_TYPE.ON_SHIFT : TAG_TYPE.AVAILABLE} />}
                <Tooltip element="span" className="text-ellipsis semi-bold" message={fullName} style={{ paddingRight: "4px" }}>
                    {fullName}
                </Tooltip>
                {showDepartment && deptTitle && <Tag>{deptTitle}</Tag>}
            </div>
        );

        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        let extraPath = "";

        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }

        if (getExpiring) {
            extraPath = LAZY_REQ_TYPE.EXPIRING;
        }
        try {
            const response = await load({
                extraPath,
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    excludeIds,
                    withoutShift,
                    workShiftId,
                    excludeWorkShiftId,
                    excludeWorkDetailIds,
                    workDetailIds,
                    getExpiring,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });

            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let resdata = response.data.data || [];
                let newdata = [];

                newdata = resdata.data.map(createRowItem);

                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });

    const reset = () => fetch({}, true);

    const search = (value = "") => {
        fetch({ search: value }, true);
        updateObject({ search: value });
    };

    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted && !initializing) {
            fetch().then(onMount);
        }
    }, [isMounted, initializing]);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort, createRowItem }];
};

export const useLazyEmployeeManager = ({
    excludeIds,
    withoutShift,
    enableUncheckedOnshift,
    uniqueKey,
    onChange,
    onMount,
    selected,
    readOnly,
    type = ASSIGN_TYPE.DEFAULT,
    workShiftId,
    hasInternalSelections,
    excludeWorkShiftId,
    excludeWorkDetailIds,
    workDetailIds,
    title,
    disableSupervisors,
    showSites,
    isExpiringWideUI
} = {}) => {
    const [object, setObject] = useState({
        selected: null,
        checked: [],
        unchecked: [],
        viewSites: false,
        viewRecord: false,
        viewFile: false
    });

    const [config, updateConfig, { loadMore, search, isLoading, sort, reset }] = useLazyTableEmployees({
        excludeIds,
        withoutShift,
        workShiftId,
        excludeWorkShiftId,
        excludeWorkDetailIds,
        workDetailIds,
        getExpiring: type == ASSIGN_TYPE.EXPIRING,
        onMount: (result) => result && onMount?.({ defaultCheckedIds: !hasInternalSelections ? createDefaultCheckId(result, selected, type) : [] })
    });

    const user = useAppSelector(selectUser);
    const setting = user.Setting || {};
    const timezone = setting.timezone;

    // can be used to determine which values can be unchecked
    const defaultCheckedId = !hasInternalSelections ? createDefaultCheckId(config.data, selected, type) : [];

    let { data: headers } = LazyTableHeaders({
        type,
        onViewRecord: (row) => updateObject({ selected: row, viewRecord: true }),
        onViewSites: (row) => updateObject({ selected: row, viewSites: true }),
        onViewFile: (row) => updateObject({ selected: row, viewFile: true }),
        readOnly,
        timezone,
        defaultCheckedId,
        enableUncheckedOnshift,
        title,
        workShiftId,
        disableSupervisors,
        showSites,
        selected,
        isExpiringWideUI
    });

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    const handleCheckChange = (val, key, keys, type, ids, removedKeys) => {
        let temp = { [uniqueKey]: [] };
        if (keys[uniqueKey]) {
            temp[uniqueKey] = keys[uniqueKey];
        } else {
            temp[uniqueKey] = keys;
        }
        updateObject({ checked: temp[uniqueKey], unchecked: removedKeys });
        typeof onChange == "function" && onChange({ checked: temp[uniqueKey], unchecked: removedKeys });
    };

    const handleSearch = (e) => {
        const value = typeof e == "string" ? e : e.target.value;
        search((value && value.toLowerCase().trim()) || "");
    };

    return {
        unique: uniqueKey,
        headers,
        data: config.data,
        onSort: sort,
        sort: config.sort,
        defaultChecked: defaultCheckedId,
        onLoadMore: loadMore,
        onCheckChange: handleCheckChange,
        isLoading: isLoading,
        onSearch: handleSearch,
        search: config.search,
        object,
        updateObject,
        totalCount: config.totalCount,
        updateConfig,
        reset
    };
};

export const useGetEmployee = (id, useCache = true) => {
    const dispatch = useAppDispatch();
    const countries = useFetchCountries();
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const [current, { isInitial, isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useEmployeeDetailsMutation,
                selectData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        {
            runOnMount: true,
            noCache: !useCache,
            onAfterFetch: async (result) => {
                dispatch(
                    updateData({
                        id,
                        data: {
                            supervisingDepts: result.data.data.supervisingDepts,
                            managingDepts: result.data.data.managingDepts
                        }
                    })
                );
            },
            transformResultAfterFetch: (result) => {
                return sanitizeDatesWithProperTimeConversionFromObject(result, timezone, DATE_TIME_FIELDS, STANDARD_DATE_FORMAT_WITH_TIME);
            }
        }
    );

    const createVars = (data) => {
        if (!data) {
            return {
                timezone,
                contract: {},
                bank: {},
                workshift: {},
                role: {},
                sites: [],
                countries: [],
                supervisingDepts: [],
                managingDepts: [],
                supervisingSites: [],
                managingSites: []
            };
        }
        const details = cloneDeep(data || {});
        const fullName = `${data.first_name || ""} ${data.last_name || ""}`.trim();
        const nationality = data.nationality ? countries.find((ctr) => ctr.cca2 == data.nationality) : "";
        const isSuperVisor = data.Role && data.Role?.type == ROLE_TYPE.EMPLOYEE && data.Role?.level == ROLE_LEVEL.HIGH;

        const supervisingDepts = (data?.supervisingDepts || []).map((dept) => dept.title);
        const managingDepts = (data?.managingDepts || []).map((dept) => dept.title);
        const supervisingSites = (data?.CompanySites || []).filter((site) => site.isSupervisor).map((site) => site.title);
        const managingSites = (data?.CompanySites || []).filter((site) => site.isManager).map((site) => site.title);

        const isDeptSupervisor = !!supervisingDepts?.length;
        const isDeptManager = !!managingDepts?.length;

        return {
            fullName,
            nationality,
            isSuperVisor,
            timezone,
            department: details.CompanyDepartment || {},
            designation: details.CompanyDesignation || {},
            contract: details.EmployeeContract || {},
            bank: details.EmployeeBankDetail || {},
            workshift: details.EmployeeWorkShift || {},
            role: details.Role || {},
            sites: (details.CompanySites || []).map((site) => site.id),
            countries,
            isDeptSupervisor,
            isDeptManager,
            supervisingDepts,
            managingDepts,
            supervisingSites,
            managingSites
        };
    };

    return [
        current || {},
        {
            isInitial,
            isLoading,
            update: updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch,
            config: createVars(current)
        }
    ];
};

export const useUpsertEmployee = (id) => {
    const [mobileCode, setMobileCode] = useState("");
    const [form, setForm] = useState(DEFAULT_FIELDS);

    const [refresh] = useRefresh();

    const [current, { isLoading: isGettingEmployee }] = useGetEmployee(id, true);
    const [createEmployee, { isLoading: createEmployeeLoading }] = useCreateEmployeeMutation();
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeMutation();
    const [upload, { isLoading: isUploading }] = useEmployeeUploadFilesMutation();

    const dispatch = useAppDispatch();

    const isLoading = createEmployeeLoading || updateEmployeeLoading || isUploading;
    const hasUploads = Object.values(form.uploads).some((file) => isFileObject(file));
    const isCreate = !id;
    const isUpdate = !isCreate;
    const user = useAppSelector(selectUser);
    const setting = user.Setting;
    const timezone = setting.timezone;
    const totalEmployees = user.totalEmployees;
    const country = user.country;

    const checkPhone = (object = {}) => {
        const mobileNumber = object[PERSONAL_FIELDS.MOBILE_NUMBER];
        if (!mobileCode || !mobileNumber) {
            return;
        }
        const phone = isPhoneValid(mobileNumber);
        let isValid = phone.isValid;
        if (!isValid && mobileCode) {
            const newMobileNumber = mobileCode + mobileNumber;
            isValid = isPhoneValid(newMobileNumber).isValid;
            if (!isValid) {
                throw new Error("Mobile number is not valid.");
            } else {
                object[PERSONAL_FIELDS.MOBILE_NUMBER] = newMobileNumber;
            }
        } else {
            const splitphone = phone.phoneNumber.split(phone.countryCode);
            splitphone[0] = mobileCode;
            const newphone = splitphone.join("");
            if (isPhoneValid(newphone).isValid) {
                object[PERSONAL_FIELDS.MOBILE_NUMBER] = newphone;
            } else {
                throw new Error("Mobile number is not valid.");
            }
        }
    };

    const validateSanitizeObject = (object = {}) => {
        try {
            // validate password key to use
            const passwordKeyToUse = form[PERSONAL_FIELDS.PASSWORD_KEY_TO_USE];
            const passwordKeyToUseValue = form[passwordKeyToUse];
            if (!passwordKeyToUseValue) {
                const message = `The key that you will use for the password should have a value. Found Empty for ${passwordKeyToUse.replace(
                    "ID",
                    " ID"
                )}.`;

                throw new Error(message);
            }

            checkPhone(object);

            // sanitize site ids; current object has CompanySites key but form does not have it
            const newSiteIds = (object?.CompanySites || []).map((cs) => cs.id);
            const oldSiteIds = (current?.CompanySites || []).map((cs) => cs.id);

            if (country.iso2Alpha === "QA") {
                object.labor_card_number && delete object.labor_card_number;
                object.labor_card_expiration && delete object.labor_card_expiration;
            }

            if (!isEqual(newSiteIds, oldSiteIds)) {
                object.site_ids = newSiteIds;
            }

            object.department_id = object.CompanyDepartment?.id || null;
            object.designation_id = object.CompanyDesignation?.id || null;
            object.work_shift_id = object.EmployeeWorkShift?.id || null;
            object.role_id = object.Role?.id || null;
            object.direct_manager_id = object[PERSONAL_FIELDS.DIRECT_MANAGER]?.id;
            object.direct_supervisor_id = object[PERSONAL_FIELDS.DIRECT_SUPERVISOR]?.id;

            delete object.CompanyDepartment;
            delete object.CompanyDesignation;
            delete object.EmployeeWorkShift;
            delete object.Role;
            delete object.CompanySites;
            delete object.uploads;
            delete object[PERSONAL_FIELDS.DIRECT_MANAGER];
            delete object[PERSONAL_FIELDS.DIRECT_SUPERVISOR];

            const toNullDate = (newObj = {}) => {
                for (const key in newObj) {
                    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
                        const value = newObj[key];
                        if (DATE_FIELDS.includes(key) && !value) {
                            newObj[key] = null;
                        }
                        if (!isNull(value) && !isUndefined(value) && isObject(value)) {
                            toNullDate(newObj[key]);
                        }
                    }
                }
            };

            toNullDate(object);

            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        if (name == "mobile_number") {
            setMobileCode(e.target.mobileCode);
        }
        const isChainedObject = name.split(".").length > 1;
        const isWOffDays = name === "number_weekly_off_days";

        // reset off days when weekly off days is changed
        if (isWOffDays && config["off_days"]?.length) {
            config["off_days"] = [];
        }
        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const upsert = async (newObject = {}) => {
        try {
            const func = id ? updateEmployee : createEmployee;

            const clonedForm = cloneDeep(form);
            const uploads = clonedForm.uploads;

            // we reuse this again here to sanitize the object
            validateSanitizeObject(clonedForm);

            let body = { ...clonedForm, ...newObject, toRemoveFile: {} };

            body = sanitizeDateWithLocalDateToOtherTimezoneFromObject(body, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);

            const formData = new FormData();

            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    isFileObject(file) && formData.append(field, file);
                    isNull(file) && (body.toRemoveFile[field] = true);
                }
            }

            if (isUpdate) {
                formData.append("others", JSON.stringify(body));
            }

            let result = await func({
                formData: hasUploads && isUpdate,
                body: hasUploads && isUpdate ? formData : body,
                extraPaths: [id, hasUploads ? "with-file" : "no-file"].filter(Boolean)
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to ${id ? "update" : "create"} employee, please try again later.`);
            }

            let data = result.data.data;

            if (isCreate && hasUploads) {
                const uploadres = await upload({ formData: true, body: formData, extraPath: data.id });
                if (uploadres.error) {
                    createToast("Something went wrong and Failed to upload employee files. Please reupload and try again.", TOAST_TYPE.ERROR);
                } else {
                    data = uploadres.data.data;
                }
            }

            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_TIME_FIELDS, STANDARD_DATE_FORMAT_WITH_TIME);

            if (isUpdate) {
                dispatch(
                    updateData({
                        id,
                        data
                    })
                );
            } else {
                if (totalEmployees <= 0) {
                    refresh();
                }
            }
            dispatch(
                setCurrent({
                    ...(current || {}),
                    ...data
                })
            );

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const upsertWithAlert = () =>
        new Promise((resolve, reject) => {
            const createConfirmAlertContent = () => {
                const isChangesAffectSalaryComputation = () => {
                    const keysAffectSalaryComputation = [PERSONAL_FIELDS.SCHEDULE_TYPE];
                    return keysAffectSalaryComputation.some((key) => !isEqual(form[key], current[key]));
                };

                let def = `Are you sure you want to ${isCreate ? "create" : "update"} this employee? This cannot be undone.`;
                if (!isCreate && isChangesAffectSalaryComputation()) {
                    def =
                        "Are you sure you want to update this employee? Changing the schedule type will affect the salary computation. This cannot be undone.";
                }
                return def;
            };

            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: !isCreate ? "Update Employee" : "Create Employee",
                content: createConfirmAlertContent(),
                onConfirm: async (close) => {
                    close();
                    const result = await upsert();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    const isMobileCodeChanges = useMemo(
        () => !!(mobileCode && current?.[PERSONAL_FIELDS.MOBILE_NUMBER] && !current[PERSONAL_FIELDS.MOBILE_NUMBER].includes(mobileCode)),
        [mobileCode, current?.[PERSONAL_FIELDS.MOBILE_NUMBER]]
    );

    return [
        upsertWithAlert,
        isLoading,
        {
            onFormChange: handleChange,
            isCreate,
            form,
            current,
            isGettingEmployee,
            noChanges:
                !form.first_name || // this is just a hack so that the initial flag for no changes is true when form is still not the same wth the initial data (current). Empty first name is not allowed anyway.
                (isObjectEqualWithBase(form, current) &&
                    isEqual(sanitizeObject(form.uploads), sanitizeObject(current.uploads)) &&
                    !isMobileCodeChanges)
        }
    ];
};

export const useUpdateEmployeeFiles = (id) => {
    const [form, setForm] = useState(DEFAULT_FILE_FIELDS);

    const [current, { isLoading: isGettingEmployee }] = useGetEmployee(id, true);
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeFilesMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);

    const isLoading = updateEmployeeLoading;
    const hasUploads = Object.values(form.uploads).some((file) => isFileObject(file));
    const timezone = setting.timezone;

    const validateSanitizeObject = (object = {}) => {
        try {
            delete object.CompanyDepartment;
            delete object.CompanyDesignation;
            delete object.EmployeeWorkShift;
            delete object.Role;
            delete object.CompanySites;
            delete object.uploads;

            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const updateFiles = async (newObject = {}) => {
        if (!id) return;
        try {
            const func = updateEmployee;

            const clonedForm = cloneDeep(form);
            const uploads = clonedForm.uploads;

            // we reuse this again here to sanitize the object
            validateSanitizeObject(clonedForm);

            let body = { ...clonedForm, ...newObject, toRemoveFile: {} };

            body = sanitizeDateWithLocalDateToOtherTimezoneFromObject(body, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);

            const formData = new FormData();

            for (const field in uploads) {
                if (Object.hasOwnProperty.call(uploads, field)) {
                    const file = uploads[field];
                    isFileObject(file) && formData.append(field, file);
                    isNull(file) && (body.toRemoveFile[field] = true);
                }
            }

            formData.append("others", JSON.stringify(body));

            const result = await func({
                formData: hasUploads,
                body: hasUploads ? formData : body,
                extraPaths: [id, hasUploads ? "with-file" : "no-file"].filter(Boolean)
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to update employee, please try again later.`);
            }

            let data = result.data.data;

            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_TIME_FIELDS, STANDARD_DATE_FORMAT_WITH_TIME);

            dispatch(updateUser({ employeeFilesValidity: data.summary }));
            dispatch(updateData({ id, data }));
            dispatch(setCurrent({ ...(current || {}), ...data }));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateFileWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Employee",
                content: "Are you sure you want to update this employee? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await updateFiles();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [updateFileWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee }];
};

export const useUpdateEmployeeDepartment = (id) => {
    const [form, setForm] = useState(DEFAULT_DEPARTMENT_FIELDS);

    const [current, { isLoading: isGettingEmployee, refetch, fetch }] = useGetEmployee(id, true);
    const [updateEmployee, { isLoading: updateEmployeeLoading }] = useUpdateEmployeeDeptMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isLoading = updateEmployeeLoading;

    const validateSanitizeObject = (object = {}) => {
        try {
            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (e) => {
        let name = e.target.name,
            value = e.target.value,
            config = { ...form };

        const isChainedObject = name.split(".").length > 1;

        if (e.target?.files) {
            value = e.target.files[0];
        }
        if (isChainedObject) {
            const parsedObject = transformStringToObject(name, value, form);
            config = parsedObject;
        } else {
            config[name] = value;
        }
        updateForm(config);
    };

    const updateDept = async (newObject = {}) => {
        if (!id) {
            return;
        }
        try {
            const func = updateEmployee;
            const clonedForm = cloneDeep(form);
            validateSanitizeObject(clonedForm);
            const body = {
                ...clonedForm,
                ...newObject
            };
            const result = await func({
                body: {
                    department_id: body.CompanyDepartment?.id || null,
                    designation_id: body.CompanyDesignation?.id || null,
                    direct_supervisor_id: body.directSupervisor?.id || null,
                    direct_manager_id: body.directManager?.id || null
                },
                extraPaths: [id]
            });

            if (result.error) {
                throw new Error(result?.error?.data?.message || `Failed to update employee, please try again later.`);
            }

            let data = result.data.data;
            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            data = sanitizeDatesWithProperTimeConversionFromObject(data, timezone, DATE_TIME_FIELDS, STANDARD_DATE_FORMAT_WITH_TIME);

            dispatch(updateData({ id, data }));
            dispatch(setCurrent({ ...(current || {}), ...data }));

            return data;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Update Employee",
                content: "Are you sure you want to update this employee? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await updateDept();
                    if (result.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    useEffect(() => {
        if (current?.id && current?.id == id) {
            setForm(assignDefaultToBase(form, current));
        }
    }, [current?.id]);

    return [updateWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee, refetch, fetch }];
};

export const useAssignEmployeePosition = (id) => {
    const [form, setForm] = useState(ASSIGN_DEPARTMENT_FIELDS);

    const [current, { isLoading: isGettingEmployee, refetch, fetch }] = useGetEmployee(id, true);
    const [, isLoading, { onSave }] = useUpsertDepartments();

    const validateSanitizeObject = (object = {}) => {
        try {
            return object;
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateForm = (config = {}) => setForm({ ...form, ...config });

    const handleChange = (value) => updateForm(value);

    const update = async () => {
        if (!id) {
            return;
        }
        try {
            const clonedForm = cloneDeep(form);
            validateSanitizeObject(clonedForm);
            return await onSave(clonedForm);
        } catch (error) {
            return { error: error.message };
        }
    };

    const updateWithAlert = () =>
        new Promise((resolve, reject) => {
            const clonedForm = cloneDeep(form);
            const result = validateSanitizeObject(clonedForm);

            if (result.error) {
                return reject(result.error);
            }

            createConfirmAlert({
                title: "Assign Employee",
                content: "Are you sure you want to assign this employee to the selected department? This cannot be undone.",
                onConfirm: async (close) => {
                    close();
                    const result = await update();
                    if (result?.error) {
                        reject(result.error);
                    } else {
                        resolve(result);
                    }
                }
            });
        });

    return [updateWithAlert, isLoading, { onFormChange: handleChange, form, current, isGettingEmployee, refetch, fetch }];
};

export const useLoadAppRoles = () => {
    const [isMounted, setMounted] = useState(false);
    const [isLoading, setLoading] = useState(true);
    const [loadEmployeeRoles] = useLoadEmployeeRolesMutation();

    const dispatch = useAppDispatch();
    const roles = useAppSelector(selectEmployeeRoles);

    useEffect(() => {
        setMounted(true);
    }, []);

    const fetch = async () => {
        try {
            if (!roles || (Array.isArray(roles) && !!roles.length)) {
                isLoading && setLoading(false);
                return roles;
            }
            const result = await loadEmployeeRoles();
            if (result.error) {
                throw new Error("Failed to get roles for employees. Please try again later");
            }
            dispatch(setEmployeeRoles(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (isMounted) {
            fetch();
        }
    }, [isMounted]);

    const toSelectOptions = () => {
        return roles.map((r) => ({
            ...r,
            value: r.id,
            label: (
                <div className="flex gap-05" style={{ alignItems: "center" }}>
                    <Tag>{sanitizeWords(r.name)}</Tag>
                </div>
            )
        }));
    };

    return [roles, isLoading, { options: toSelectOptions() }];
};

export const useDeleteEmployee = () => {
    const [deleteEmployee] = useDeleteEmployeeMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        try {
            const response = await deleteEmployee({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete Employee.");
            }
            createToast("Employee successfully deleted.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [remove, isLoading];
};
