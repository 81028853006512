import moment from "moment-timezone";
import cloneDeep from "lodash/cloneDeep";
import { ASSIGN_TYPE, CONTRACT_ALLOWANCE_FIELDS } from "./const";
import { isNumber } from "lodash";

export const computeTotalSalaryPackage = (basicAmount, allowance) => {
    let total = 0;
    const newAllowance = cloneDeep(allowance);
    const notIncludedAllowance = [CONTRACT_ALLOWANCE_FIELDS.AIR_TICKET];
    for (const key in newAllowance) {
        if (Object.prototype.hasOwnProperty.call(newAllowance, key)) {
            const allowanceValue = Number(newAllowance[key]);
            if (!notIncludedAllowance.includes(key)) {
                total += isNumber(allowanceValue) ? allowanceValue : 0;
            }
        }
    }
    return basicAmount + total;
};

export const customSortKeys = (a, b, priorityKeys = []) => {
    // Define the order of categories
    const order = ["priority", "ID", "contact", "personal", "work", "bank", "other"];

    // Function to get category of a key
    function getCategory(key) {
        if (priorityKeys.includes(key)) return "priority";
        if (key.endsWith("ID")) return "ID";
        if (key.includes("mobile_number") || key.includes("email_address")) return "contact";
        if (key.startsWith("employee.")) return "personal";
        if (key.startsWith("contract.")) return "work";
        if (key.startsWith("bank.")) return "bank";
        return "other";
    }

    // Get category of each key
    const categoryA = getCategory(a);
    const categoryB = getCategory(b);

    // Compare categories
    const indexA = order.indexOf(categoryA);
    const indexB = order.indexOf(categoryB);

    if (indexA !== indexB) {
        return indexA - indexB;
    }

    // If both keys belong to the same category, sort them alphabetically
    return a.localeCompare(b);
};

export const createDefaultCheckId = (arr = [], data, type) => {
    switch (type) {
        default:
        case ASSIGN_TYPE.DEFAULT: {
            return arr.filter((emp) => (data || []).includes(emp.id)).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.WORK_SHIFT: {
            return arr.filter((emp) => emp.work_shift_id === data.id).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.SITE: {
            return arr.filter((emp) => emp.CompanySites.map((cs) => cs.id).includes(data.id)).map((emp) => emp.id);
        }
        case ASSIGN_TYPE.EXPIRING: {
            return [];
        }
    }
};

export const getEmployeeFileValidity = (expiry, timezone) => {
    const warningPeriod = Number(process.env.REACT_APP_FILE_EXPIRY_TIME_REMAINING_WARNING || 5);
    const today = moment().tz(timezone);
    const todayWithWarningPeriod = moment(today).tz(timezone).add(warningPeriod, "days");
    const expiryDate = moment(expiry).tz(timezone);
    const isExpired = today.isAfter(expiryDate);
    const isWarning = !isExpired && todayWithWarningPeriod.isAfter(expiryDate);
    return {
        isExpired,
        isWarning,
        date: expiryDate.format(),
        fromNow: expiryDate.fromNow()
    };
};
