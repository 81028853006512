import React from "react";
import PropTypes from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import Button from "../../../common/components/extra/Button";
import { createConfirmAlert } from "../../../common/utilities/helper";
import MyTooltip from "../../../common/components/extra/Tooltip";
import { useDeleteEmployee } from "./hooks";

function RemoveButton({ id, onRemove, isIcon, style, disabled, withLabel }) {
    const [remove, { isLoading }] = useDeleteEmployee();

    const handleRemove = () => {
        onRemove("on-start");
        return id && remove(id).finally(() => onRemove("on-finish"));
    };

    const handleClick = () =>
        createConfirmAlert({
            title: "Remove Employee",
            content: "Are you sure you want to remove this employee? This cannot be undone.",
            onConfirm: async (close) => {
                close();
                await handleRemove();
            }
        });

    if (isIcon) {
        return (
            <MyTooltip
                message={withLabel ? "" : "Remove Employee"}
                className={disabled ? "disabled" : ""}
                style={{
                    display: "flex",
                    alignItems: "center",
                    gap: ".5rem",
                    ...style
                }}
                onClick={() => !disabled && handleClick()}
            >
                <DeleteIcon style={{ width: "1.3rem", height: "auto", color: "red" }} />
                {(withLabel && "Remove Employee") || ""}
            </MyTooltip>
        );
    }

    return (
        <Button
            onClick={() => !disabled && handleClick()}
            isLoading={isLoading}
            afterExtra={<DeleteIcon style={{ color: "red" }} />}
            className="danger"
            style={style}
            disabled={disabled}
            small
            mini
        >
            <span>Remove</span>
        </Button>
    );
}

export default RemoveButton;

RemoveButton.propTypes = {
    id: PropTypes.any,
    isIcon: PropTypes.bool,
    disabled: PropTypes.bool,
    style: PropTypes.object,
    onRemove: PropTypes.func,
    withLabel: PropTypes.bool
};
