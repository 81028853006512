import React, { useEffect, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import isNull from "lodash/isNull";
import { FORM_FIELDS } from "./const";
import { SORT_ORDER } from "../../../common/utilities/const";
import {
    useCreateSiteMutation,
    useDeleteSiteMutation,
    useGetSiteDetailsMutation,
    useLoadAllSiteEmployeesLazyMutation,
    useLoadAllSitesLazyMutation,
    useUpdateSiteMutation
} from "./api";
import { TOAST_TYPE, createEditingInfo, createToast, sanitizeWords } from "../../../common/utilities/helper";
import { selectUserSetting } from "../../common/slice";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import {
    DEFAULT_SIZE,
    LOAD_MORE_OFFSET,
    defaultConfig,
    selectLoading,
    selectSearching,
    selectTableConfig,
    selectWorkSiteData,
    setLoading,
    setSearching,
    setState,
    setTableConfig
} from "./slice";
import { isSiteIncomplete } from "./helper";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import Tag, { TAG_TYPE } from "../../../common/components/extra/Tag";
import Tooltip from "../../../common/components/extra/Tooltip";

export const useGetWorkSites = () => {
    const [data, setData] = useState({});
    const [getDetails, { isLoading }] = useGetSiteDetailsMutation();

    const setting = useAppSelector(selectUserSetting);

    const fetch = async (siteId) => {
        try {
            const result = await getDetails({ body: { siteId } });
            if (result.error) {
                throw new Error("Failed to fetch work sites. Please try again later");
            }
            setData(result.data.data);
            return result.data.data.result;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        }
    };

    const checkAllowEditTime = () => {
        const editInfo = createEditingInfo({
            start: data.before,
            end: data.after,
            timezone: setting.timezone
        });

        return {
            isAllowed: !data.hasEmployees || editInfo.isEditAllowed,
            allowedTime: {
                after: editInfo.afterTime,
                before: editInfo.beforeTime
            }
        };
    };

    return [fetch, { data, isLoading, allowEditInfo: checkAllowEditTime() }];
};

export const useGetWorkSitesByIds = (siteIds = []) => {
    const [object, setObject] = useState({
        data: [],
        mounted: false
    });

    const [getSites, { isLoading, status }] = useGetSiteDetailsMutation();

    const updateObject = (newObj = {}) =>
        setObject((prev) => ({
            ...prev,
            ...newObj
        }));

    const fetch = async () => {
        try {
            const response = await getSites({
                body: { siteIds }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                updateObject({ data: resdata, mounted: true });
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            updateObject({ data: [], mounted: true });
        }
    };

    useEffect(() => {
        fetch();
    }, []);

    return [object.data, (status == "uninitialized" && !object.data.length) || (status == "pending" && isLoading), { isMounted: object.mounted }];
};

export const useLazyWorkSites = ({
    initializing,
    startFrom = "",
    isReadableSelected,
    defaultValue,
    isFilter,
    onShiftSiteId,
    allowIncompleteSiteSelection,
    onMount = () => {}
} = {}) => {
    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [object, setObject] = useState({
        data: [],
        sort: { sortBy: defaultConfig.sortBy, order: defaultConfig.order },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const [load, { isLoading }] = useLoadAllSitesLazyMutation();

    const hasMore = object.totalCount > object.data?.length || 0;

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const createFixedValuesFromDefault = () =>
        Array.isArray(defaultValue)
            ? defaultValue
                  .filter((item) => {
                      return isReadableSelected || onShiftSiteId == item.id;
                  })
                  .map((item) => item.id)
            : [];

    const createRowItem = (row, isList) => {
        const temp = cloneDeep(row || {});

        if (!temp.id) {
            return null;
        }

        const title = sanitizeWords(temp.title);
        temp.value = temp.id;
        if (isFilter) {
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden small-font">
                    <Tooltip element="span" className="text-ellipsis bold" message={title} style={{ maxWidth: "10rem" }}>
                        {title}
                    </Tooltip>
                </div>
            );
        } else {
            const isDisabled = !allowIncompleteSiteSelection && isSiteIncomplete(temp);
            const isOnShiftSite = onShiftSiteId && onShiftSiteId == temp.id;
            temp.isDisabled = isDisabled;
            temp.label = (
                <div className="flex gap-05 align-center overflow-hidden" style={{ minHeight: "1.5rem" }}>
                    <Tooltip element="span" className="text-ellipsis semi-bold" message={title}>
                        {title}
                    </Tooltip>
                    {onShiftSiteId && (isOnShiftSite ? <Tag type={TAG_TYPE.ON_SHIFT} /> : !isList && <Tag type={TAG_TYPE.AVAILABLE} />)}
                    {isList && temp.isDisabled ? (
                        <WarningColorSwitcher
                            tooltip={{
                                message: (
                                    <div style={{ maxWidth: "15rem" }}>
                                        Site information is incomplete, must have a supervisor, manager and a work type to be able to use.
                                    </div>
                                ),
                                className: "flex"
                            }}
                            style={{ width: "1.1rem", marginTop: "-1px" }}
                            warning
                        />
                    ) : (
                        temp.location && <Tooltip message={`Location: ${temp.location}`} iconStyle={{ width: "1.15rem" }} isIcon />
                    )}
                </div>
            );
        }

        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                if (isNull(value)) {
                    temp[key] = "";
                }
            }
        }
        return temp;
    };

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }

            if (response.data && response.data.data) {
                const resdata = response.data.data || {};
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? [...object.data, ...newdata] : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                temp.data = [...(temp.data || [])].map((item) => createRowItem(item, true));
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = (config = {}) => hasMore && fetch({ cursor: object.cursor, ...(config || {}) });
    const reset = (config = {}) => fetch(config, true);
    const search = (value = "") => fetch({ search: value }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        isMounted &&
            !initializing &&
            reset({ startFrom })
                .then(onMount)
                .catch(() => {})
                .finally(() => setFetching(false));
    }, [isMounted, initializing]);

    return [
        object,
        updateObject,
        {
            fixedValues: createFixedValuesFromDefault(),
            isLoading,
            initializing: fetching,
            hasMore,
            fetch,
            reset,
            loadMore,
            search,
            createRowItem
        }
    ];
};

export const useLazyWorkSitesEmployees = ({ siteId, startFrom = "" } = {}) => {
    const LOAD_MORE_OFFSET = 5;
    const DEFAULT_SIZE = 15;

    const [object, setObject] = useState({
        data: [],
        selected: null,
        sort: { sortBy: "residenceID", order: SORT_ORDER.ASC },
        totalCount: 0,
        cursor: "",
        search: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllSiteEmployeesLazyMutation();

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    siteId,
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                const resdata = response.data.data;
                const temp = {
                    data: isReset ? resdata.data : object.data.concat(resdata.data),
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });
    const reset = () => fetch({}, true);
    const search = (value = "") => fetch({ search: value }, true);
    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        fetch({ startFrom });
    }, []);

    return [object, updateObject, { isLoading, hasMore, fetch, reset, loadMore, search, sort }];
};

export const useDeleteWorkSites = () => {
    const dispatch = useAppDispatch();

    const [deleteWorkSite] = useDeleteSiteMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        if (!isLoading) {
            dispatch(setLoading(true));
        }
        try {
            const response = await deleteWorkSite({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete work site.");
            }
            return true;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            dispatch(setLoading(false));
        }
    };

    return [remove, isLoading];
};

export const useUpsertWorkSites = (updateId) => {
    const isCreate = !updateId;

    const [old, setOld] = useState(FORM_FIELDS);
    const [form, setForm] = useState(FORM_FIELDS);
    const [fetching, setFetching] = useState(true);

    const [getDetails, { allowEditInfo }] = useGetWorkSites();
    const [create, { isLoading: createIsLoading }] = useCreateSiteMutation();
    const [update, { isLoading: updateIsLoading }] = useUpdateSiteMutation();

    const createVars = () => {
        const removedSupervisors = old?.supervisors?.filter((os) => !form?.supervisors?.find((fs) => fs.id == os.id));
        const removedManagers = old?.managers?.filter((os) => !form?.managers?.find((fs) => fs.id == os.id));
        return {
            removedSupervisors,
            removedManagers,
            hasRemovedSvMg: removedSupervisors.length > 0 || removedManagers.length > 0,
            isLoading: createIsLoading || updateIsLoading,
            coordinatesChanged: !isEqual(form?.coordinates, old?.coordinates),
            isIncomplete: !isCreate && (form.supervisors?.length <= 0 || form.managers?.length <= 0 || !form.work_detail_id)
        };
    };

    const vars = createVars();

    const updateForm = (config = {}) => setForm((prev) => ({ ...prev, ...config }));

    const upsert = async (thumbnail, toRemoveEmployeesFromSite = []) => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);
            clonedform.managers = (clonedform.managers || []).map((cm) => cm.id);
            clonedform.supervisors = (clonedform.supervisors || []).map((cm) => cm.id);
            clonedform.EmployeeWorkDetail && delete clonedform.EmployeeWorkDetail;
            if (thumbnail) {
                clonedform.thumbnail = thumbnail;
            }
            if (isCreate) {
                result = await create({ body: clonedform });
            } else {
                clonedform.toRemoveEmployeesFromSite = toRemoveEmployeesFromSite;
                result = await update({ body: clonedform, extraPath: updateId });
            }
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            return result.data.data;
        } catch (err) {
            throw new Error(err.message);
        }
    };

    useEffect(() => {
        if (updateId) {
            getDetails(updateId)
                .then((result) => {
                    setForm(result);
                    setOld(result);
                    setFetching(false);
                })
                .catch(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, []);

    return [
        form,
        updateForm,
        {
            upsert,
            isLoading: vars.isLoading,
            isGettingWorkSites: fetching,
            config: vars,
            hasChanges: isEqual(form, old),
            isEditAllowed: isCreate || allowEditInfo.isAllowed,
            allowedTime: allowEditInfo.allowedTime
        }
    ];
};

export const useLazyPageWorkSites = ({ readOnly, startFrom, onMount = () => {} } = {}) => {
    const [fetching, setFetching] = useState(true);
    const [isMounted, setMounted] = useState(false);

    const [load, { isLoading }] = useLoadAllSitesLazyMutation();

    const dispatch = useAppDispatch();
    const siteData = useAppSelector(selectWorkSiteData);
    const tableConfig = useAppSelector(selectTableConfig);
    const searching = useAppSelector(selectSearching);

    const hasMore = tableConfig.totalCount > siteData?.length || 0;

    const updateObject = (newObject = {}) => dispatch(setTableConfig(newObject));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        if (searching) {
            return;
        }
        if (isReset) {
            config.cursor = "";
        }
        try {
            const response = await load({
                body: {
                    ...tableConfig,
                    ...(sort || {}),
                    ...(config || {}),
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message);
            }

            if (response.data && response.data.data) {
                const resdata = response.data.data || {};
                const newdata = [...(resdata.data || [])];
                const sameCursor = isEqual(tableConfig.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? siteData.concat(newdata) : newdata,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                dispatch(
                    setState({
                        data: temp.data,
                        tableConfig: {
                            ...tableConfig,
                            ...(sort || {}),
                            ...(config || {}),
                            cursor: temp.cursor,
                            totalCount: temp.totalCount,
                            sort: temp.sort || sort
                        }
                    })
                );
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = (config = {}) => hasMore && fetch({ cursor: tableConfig.cursor, ...(config || {}) });
    const reset = (config = {}) => fetch(config, true);

    const search = async (value = "") => {
        try {
            dispatch(setSearching(true));
            await fetch({ search: (value || "").toLowerCase().trim() }, true);
        } finally {
            dispatch(setSearching(false));
        }
    };

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (readOnly) {
            isMounted &&
                reset({ startFrom })
                    .then(onMount)
                    .catch(() => {})
                    .finally(() => setFetching(false));
        } else {
            setFetching(false);
        }
    }, [isMounted]);

    return [
        siteData,
        updateObject,
        {
            searching,
            isLoading,
            initializing: fetching,
            hasMore,
            fetch,
            reset,
            loadMore,
            onSearch: search,
            tableConfig
        }
    ];
};
