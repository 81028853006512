import { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast, sanitizeDatesWithProperTimeConversionFromObject } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, setCurrent, setState, selectLoading, selectEmployeePenaltiesData, defaultConfig } from "./slice";
import {
    useCancelEmployeePenaltiesMutation,
    useCreateEmployeePenaltiesMutation,
    useDeleteEmployeePenaltiesMutation,
    useGetEmployeePenaltiesMutation,
    useLoadAllEmployeePenaltiesMutation,
    useUpdateEmployeePenaltiesMutation,
    useUpdateStatusEmployeePenaltiesMutation
} from "./api";
import { CUSTOM_ACTION, DATE_FIELDS, FIELD, PENALTY_STATUS } from "./const";
import { selectUserSetting } from "../../common/slice";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";

const { EMPLOYEE, COMPANY_PENALTY, DEDUCTION_TYPE: TYPE, OCCURENCE_DATE, EFFECTIVE_DATE, REASON, STATUS, CONFIRMED_DATE } = FIELD;

export const useGetEmployeePenalties = (id, callback) => {
    const [current, { isInitial, isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetEmployeePenaltiesMutation,
                selectData: selectEmployeePenaltiesData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        { runOnMount: true, onMount: callback }
    );

    const createVars = (data) => {
        if (!data) return {};

        return {
            [CONFIRMED_DATE]: data?.[CONFIRMED_DATE] || "",
            [OCCURENCE_DATE]: data?.[OCCURENCE_DATE] || "",
            [EFFECTIVE_DATE]: data?.[EFFECTIVE_DATE] || "",
            employee: data?.employee,
            companyPenalty: data?.CompanyPenalty,
            measure: data?.penalty_measure,
            hasConfirmedDate: !!data?.[CONFIRMED_DATE]
        };
    };

    return [
        current,
        {
            isInitial,
            isLoading,
            update: updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch,
            config: createVars(current)
        }
    ];
};

export const usePaginateEmployeePenalties = ({ readOnly } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, tableConfig, onSort, onUpdate, ...rest }] = usePaginateFetch(
        useLoadAllEmployeePenaltiesMutation,
        {
            redux: {
                dataSelector: selectEmployeePenaltiesData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config, option) => {
        try {
            const response = await load(config, option);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { initializing, isLoading, fetch, update: onUpdate, onSearch, onFilter, tableConfig, onSort, ...rest }];
};

export const useUpsertEmployeePenalties = (updateId, callback) => {
    const isCreate = !updateId;

    const [current, { isLoading: isGettingRecord }] = useGetEmployeePenalties(updateId, callback);
    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old, hasChanges }] = useUpsertRecord(
        {
            updateId,
            defaultForm: {
                [EMPLOYEE]: "",
                [COMPANY_PENALTY]: "",
                [TYPE]: "",
                [OCCURENCE_DATE]: "",
                [EFFECTIVE_DATE]: "",
                [REASON]: "",
                [STATUS]: PENALTY_STATUS.ACTIVE
            },
            current,
            isGettingRecord,
            rtk: {
                useCreateMutation: useCreateEmployeePenaltiesMutation,
                useUpdateMutation: useUpdateEmployeePenaltiesMutation,
                setCurrent
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        {
            onBeforeUpsert: async (obj = {}) => {
                let clonedform = cloneDeep(obj);
                clonedform[EMPLOYEE]?.id && (clonedform[EMPLOYEE] = clonedform[EMPLOYEE].id);
                if (clonedform[COMPANY_PENALTY] && typeof clonedform[COMPANY_PENALTY] == "object") {
                    if (isCreate) {
                        clonedform[TYPE] = clonedform[COMPANY_PENALTY][TYPE];
                    }
                    clonedform[COMPANY_PENALTY] = clonedform[COMPANY_PENALTY].id;
                }
                return clonedform;
            }
        }
    );

    const createVars = () => {
        return {
            [OCCURENCE_DATE]: form[OCCURENCE_DATE],
            [EFFECTIVE_DATE]: form[EFFECTIVE_DATE],
            employee: { ...(current?.employee || {}), id: form[EMPLOYEE] },
            [COMPANY_PENALTY]: {
                ...(current?.CompanyPenalty || {}),
                ...(form[COMPANY_PENALTY]?.id ? form[COMPANY_PENALTY] : { id: form[COMPANY_PENALTY] })
            },
            hasConfirmedDate: !isCreate && !!current?.[CONFIRMED_DATE]
        };
    };

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting,
            isCreating,
            isUpdating,
            config: createVars(),
            old,
            hasChanges
        }
    ];
};

export const useDeleteEmployeePenalties = () => {
    const [deleteRecord] = useDeleteEmployeePenaltiesMutation();

    const isLoading = useAppSelector(selectLoading);

    const remove = async (id) => {
        try {
            const response = await deleteRecord({ extraPath: id });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to delete penalty.");
            }
            createToast("Penalty successfully removed.", TOAST_TYPE.SUCCESS);
            return response.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [remove, isLoading];
};

export const useCancelEmployeePenalties = () => {
    const [notes, setNotes] = useState("");

    const dispatch = useAppDispatch();

    const [cancelRecord] = useCancelEmployeePenaltiesMutation();

    const isLoading = useAppSelector(selectLoading);
    const current = useAppSelector(selectCurrent);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const cancel = async (id) => {
        try {
            const response = await cancelRecord({ extraPath: id, body: { reason: notes } });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to cancel penalty.");
            }
            if (current) {
                dispatch(
                    setCurrent({
                        ...current,
                        ...response.data.data,
                        status: PENALTY_STATUS.CANCELED,
                        reason: notes
                    })
                );
            }
            createToast("Penalty successfully cancelled.", TOAST_TYPE.SUCCESS);
            return sanitizeDatesWithProperTimeConversionFromObject(response.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [cancel, { isLoading, setNotes, notes }];
};

export const useManageActions = ({ cb } = {}) => {
    const [cancelId, setCancelId] = useState(null);
    const [openViewModal, setOpenViewModal] = useState(false);

    const [, { fetch }] = usePaginateEmployeePenalties({ readOnly: true });
    const [remove] = useDeleteEmployeePenalties();

    const dispatch = useAppDispatch();

    const handleAction = async (row, actionType) => {
        switch (actionType) {
            case CUSTOM_ACTION.PREVIEW:
                setOpenViewModal(true);
                dispatch(setCurrent({ id: row.id }));
                return;
            case CUSTOM_ACTION.REMOVE:
                return await remove(row.id).then(async (result) => {
                    await fetch();
                    typeof cb == "function" && cb(result);
                });
            case CUSTOM_ACTION.CANCEL:
                setCancelId(row.id);
                return;
            default:
                return;
        }
    };

    return { onAction: handleAction, cancelId, setCancelId, openViewModal, setOpenViewModal };
};

export const useUpdateStatusEmployeePenalties = () => {
    const [updateStatus, { isLoading }] = useUpdateStatusEmployeePenaltiesMutation();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const method = async (id, newStatus) => {
        try {
            const response = await updateStatus({
                extraPath: id,
                body: {
                    [FIELD.STATUS]: newStatus
                }
            });
            if (response.error) {
                throw new Error(response.error?.data?.message || "Failed to update penalty.");
            }
            createToast("Penalty successfully updated.", TOAST_TYPE.SUCCESS);
            return sanitizeDatesWithProperTimeConversionFromObject(response.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [method, isLoading];
};
