import { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import { TOAST_TYPE, createToast, isFileObject, toTimeWithTimeZone } from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, selectEmployeeSettlementsData, setCurrent, setState, defaultConfig } from "./slice";
import {
    useCreateEmployeeSettlementsMutation,
    useGetEmployeeSettlementsMutation,
    useGetLastEmployeeSettlementsByTypeMutation,
    useLoadAllEmployeeSettlementsMutation,
    usePreviewEmployeeSettlementsMutation,
    usePreviewFinalEmployeeSettlementsMutation,
    useRemoveEmployeeSettlementsMutation,
    useUpdateAsPaidEmployeeSettlementsMutation,
    useUpdateEmployeeSettlementsMutation,
    useUploadEmployeeSettlementsMutation
} from "./api";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { FIELD, NEXT_DAY, SETTLEMENT_TYPE } from "./const";
import { selectUserSetting } from "../../common/slice";
import moment from "moment-timezone";
import { DATE_FORMAT_OPTION } from "../companyForms/const";

export const useGetSettlement = (id, { runOnMount } = {}) => {
    const [isMounted, setMounted] = useState(true);
    const [fetching, setFetching] = useState(true);
    const [getDetails] = useGetEmployeeSettlementsMutation();

    const dispatch = useAppDispatch();
    const current = useAppSelector(selectCurrent);
    const settlementData = useAppSelector(selectEmployeeSettlementsData);

    const fetch = async ({ force } = {}) => {
        try {
            if ((!force && current && current.id === id) || !id) {
                setFetching(false);
                return current;
            }
            if (!fetching) {
                setFetching(true);
            }
            const result = await getDetails({ extraPath: id });
            if (result.error) {
                throw new Error("Failed to fetch data. Please try again later");
            }
            setFetching(false);
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        } finally {
            setFetching(false);
        }
    };

    const reset = () => fetch({ force: true });

    const updateCurrent = (newCurrent = {}) => {
        const newObj = !current || (current && current.id !== newCurrent.id) ? newCurrent : { ...(current || {}), ...(newCurrent || {}) };
        dispatch(
            setState({
                current: newObj,
                data: settlementData.map((d) => (d.id == newObj.id ? { ...d, ...newObj } : d))
            })
        );
    };

    const clearCurrent = () => {
        dispatch(setCurrent(null));
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMounted) {
                fetch();
            }
        } else if (fetching) {
            setFetching(false);
        }
    }, [isMounted, id]);

    useEffect(() => {
        !isMounted && setMounted(true);
    }, []);

    return [
        current,
        {
            isLoading: fetching,
            updateCurrent,
            fetch,
            reset,
            clearCurrent
        }
    ];
};

export const useGetLastSettlementByType = () => {
    const [lastRecord, setLastRecord] = useState(null);

    const [getLastRecord, { isLoading }] = useGetLastEmployeeSettlementsByTypeMutation();

    const isSameRecord = (config = {}) => {
        return (
            lastRecord &&
            lastRecord[FIELD.CATEGORY] === config[FIELD.CATEGORY] &&
            lastRecord[FIELD.CLASSIFICATION] === config[FIELD.CLASSIFICATION] &&
            lastRecord[FIELD.TYPE] === config[FIELD.TYPE]
        );
    };

    const fetch = async (config = {}, option = {}) => {
        try {
            if (!config[FIELD.EMPLOYEE]?.id && !isLoading && !option.force && isSameRecord(config)) {
                return lastRecord;
            }

            const result = await getLastRecord({
                body: { [FIELD.TYPE]: config[FIELD.TYPE] },
                extraPath: config[FIELD.EMPLOYEE]?.id
            });

            if (result?.error) {
                throw new Error("Failed to fetch data. Please try again later");
            }
            setLastRecord(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const reset = () => {
        fetch({ force: true });
    };

    return [
        lastRecord,
        {
            isLoading,
            fetch,
            reset,
            setLastRecord
        }
    ];
};

export const usePaginateSettlements = ({ readOnly, type } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, onSort, onUpdate, tableConfig, resetTableConfig, ...rest }] = usePaginateFetch(
        useLoadAllEmployeeSettlementsMutation,
        {
            redux: {
                dataSelector: selectEmployeeSettlementsData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly,
            queryParams: { extraPath: type }
        }
    );

    const fetch = async (config, option, newType) => {
        try {
            const response = await load(config, {
                queryParams: { extraPath: newType || type },
                ...(option || {})
            });
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { initializing, isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch, resetTableConfig, ...rest }];
};

export const useCreateSettlement = (data = {}) => {
    const [form, setForm] = useState({
        [FIELD.EMPLOYEE]: null,
        [FIELD.DESCRIPTION]: null,
        [FIELD.CATEGORY]: null,
        [FIELD.TYPE]: null,
        [FIELD.CLASSIFICATION]: null,
        [FIELD.STATUS]: null,
        [FIELD.PROCESSED_DATE]: null,
        [FIELD.LAST_WORKING_DATE]: null,
        [FIELD.COVERAGE_START_DATE]: null,
        [FIELD.COVERAGE_END_DATE]: null,
        [FIELD.NOTICE_PAY_AMOUNT]: null,
        [FIELD.AIR_TICKET_AMOUNT]: null,
        [FIELD.PAID_DATE]: null,
        [FIELD.REMARKS]: null,
        [FIELD.DOCUMENT]: null,
        ...data
    });

    const [create, { isLoading: createIsLoading }] = useCreateEmployeeSettlementsMutation();
    const [uploadProof, { isLoading: isUploading }] = useUploadEmployeeSettlementsMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const method = async (newConfig = {}) => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            const proofFile = clonedform[FIELD.DOCUMENT];
            const employee = clonedform[FIELD.EMPLOYEE];

            clonedform.employee_id = employee?.id;
            clonedform.joining_date = toTimeWithTimeZone(employee?.joining_date, timezone).format();

            delete clonedform[FIELD.EMPLOYEE];
            delete clonedform[FIELD.DOCUMENT];

            result = await create({
                body: {
                    ...clonedform,
                    ...newConfig
                }
            });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (isFileObject(proofFile)) {
                const formData = new FormData();
                formData.append("settlement-doc", proofFile);
                formData.append(
                    "others",
                    JSON.stringify({
                        settlementId: result.data.data.id
                    })
                );
                const uploadres = await uploadProof({
                    formData: true,
                    body: formData,
                    extraPath: clonedform.employee_id
                });
                if (uploadres.error) {
                    throw new Error("Something went wrong and Failed to upload document. Please reupload and try again");
                }
                result = uploadres;
            }
            createToast("Record created succesfully", TOAST_TYPE.SUCCESS);
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(`Failed to create record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            create: method,
            isUpserting: createIsLoading || isUploading
        }
    ];
};

export const useGetPreviewFinalSettlement = () => {
    const [preview, { data, isLoading, reset }] = usePreviewFinalEmployeeSettlementsMutation();

    const method = async (form = {}) => {
        try {
            const result = await preview({
                extraPath: form[FIELD.EMPLOYEE].id
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            return result.data.data;
        } catch (error) {
            createToast(`Failed to generate preview. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [method, isLoading, { data: data?.data, reset }];
};

export const useGetPreview = () => {
    const [preview, { data, isLoading, reset }] = usePreviewEmployeeSettlementsMutation();

    const settings = useAppSelector(selectUserSetting);
    const salarySetting = settings.salary;
    const settlement = salarySetting.settlement;

    const method = async (form = {}) => {
        try {
            const result = await preview({
                body: {
                    [FIELD.TYPE]: form[FIELD.TYPE],
                    [FIELD.COVERAGE_START_DATE]: form[FIELD.COVERAGE_START_DATE],
                    [FIELD.COVERAGE_END_DATE]: form[FIELD.COVERAGE_END_DATE],
                    includeUnpaidLeaveDays: settlement.includeUnpaidLeaveDays
                },
                extraPath: form[FIELD.EMPLOYEE].id
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            return result.data.data;
        } catch (error) {
            createToast(`Failed to generate preview. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [method, isLoading, { data: data?.data, reset }];
};

export const useUpdateSettlement = (id, defaultObject = {}, isSetStatusToPaid) => {
    const [old] = useState({
        [FIELD.DESCRIPTION]: null,
        [FIELD.REMARKS]: null,
        [FIELD.DOCUMENT]: null,
        ...defaultObject
    });
    const [form, setForm] = useState({
        [FIELD.DESCRIPTION]: null,
        [FIELD.REMARKS]: null,
        [FIELD.DOCUMENT]: null,
        ...defaultObject
    });

    const [update, { isLoading: updateIsLoading }] = (
        isSetStatusToPaid ? useUpdateAsPaidEmployeeSettlementsMutation : useUpdateEmployeeSettlementsMutation
    )();

    const [uploadProof, { isLoading: isUploading }] = useUploadEmployeeSettlementsMutation();

    const dispatch = useAppDispatch();

    const method = async () => {
        let result = null;
        try {
            const clonedform = cloneDeep(form);

            const proofFile = clonedform[FIELD.DOCUMENT];

            result = await update({
                body: clonedform,
                extraPath: id
            });

            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            if (isFileObject(proofFile)) {
                const formData = new FormData();
                formData.append("settlement-proof", proofFile);
                formData.append(
                    "others",
                    JSON.stringify({
                        leaveId: result.data.data.id
                    })
                );
                const uploadres = await uploadProof({
                    formData: true,
                    body: formData,
                    extraPath: clonedform.employee_id
                });
                if (uploadres.error) {
                    throw new Error("Something went wrong and Failed to upload document. Please reupload and try again");
                }
                result = uploadres;
            }
            createToast("Record updated succesfully.", TOAST_TYPE.SUCCESS);
            dispatch(setCurrent(result.data.data));
            return result.data.data;
        } catch (error) {
            createToast(`Failed to update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            update: method,
            isUpserting: updateIsLoading || isUploading,
            old,
            hasChanges: !!(old && !isEqual(form, old))
        }
    ];
};

export const useRemoveSettlement = () => {
    const [remove, { isLoading }] = useRemoveEmployeeSettlementsMutation();

    const onRemove = async (id) => {
        if (!id) return;
        try {
            const result = await remove({ extraPath: id });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Record successfully deleted.", TOAST_TYPE.SUCCESS);
        } catch (error) {
            createToast(`Failed to remove record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    return [onRemove, isLoading];
};

export const useSettlementInfo = (form, lastRecord, internalForm) => {
    const setting = useAppSelector(selectUserSetting);

    const timezone = setting.timezone;
    const salarySetting = setting.salary;
    const eligibilities = salarySetting.eligibilities;
    const annualLeaveEligbilitiesInMonths = eligibilities.annualLeave;
    const gratuityEligibilitiesInMonths = eligibilities.gratuity;
    const hasNoLastRecord = lastRecord && lastRecord?.noRecord;

    const today = useMemo(() => toTimeWithTimeZone(new Date(), timezone).startOf("day").format(), [timezone]);

    const selectedSupportedType = internalForm.selectedSupportedType;
    const confirmedType = internalForm.confirmedType;

    const settlementInfo = useMemo(() => {
        const employee = form[FIELD.EMPLOYEE];
        const probationPeriod = employee?.probation_period;
        const isEmployeeOnProbation = employee?.isOnProbationPeriod;
        const probationEndDate = employee?.probationEndDate;
        const hasConfirmedButNoLastRecord = confirmedType && hasNoLastRecord;
        const unpaidLeaveMonths = (lastRecord && lastRecord.unpaidLeaveMonths) || 0;
        const unpaidLeaveDays = (lastRecord && lastRecord.unpaidLeaveDays) || 0;

        const employeeProbationDate =
            isEmployeeOnProbation && toTimeWithTimeZone(probationEndDate, timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format);
        const employeeJoiningDate = employee && employee.joining_date && toTimeWithTimeZone(employee.joining_date, timezone).format();

        const eligibility =
            selectedSupportedType && (SETTLEMENT_TYPE.GRATUITY == form[FIELD.TYPE] ? gratuityEligibilitiesInMonths : annualLeaveEligbilitiesInMonths);

        const toAddInMonths = eligibility + unpaidLeaveMonths;

        const coverageStartDateMinDate =
            (lastRecord?.[FIELD.COVERAGE_END_DATE] &&
                toTimeWithTimeZone(lastRecord[FIELD.COVERAGE_END_DATE], timezone).add(NEXT_DAY, "day").format()) ||
            employeeJoiningDate;
        const coverageEndDateMinDate =
            form[FIELD.COVERAGE_START_DATE] && toTimeWithTimeZone(form[FIELD.COVERAGE_START_DATE], timezone).add(toAddInMonths, "months").format();
        const coverageStartDateMaxDate = moment(today).subtract(toAddInMonths, "months").format();

        return {
            hasNoLastRecord,
            employee,
            eligibility,
            probationPeriod,
            isEmployeeOnProbation,
            employeeProbationDate,
            employeeJoiningDate,
            toAddInMonths,
            coverageStartDateMinDate,
            coverageEndDateMinDate,
            hasConfirmedButNoLastRecord,
            coverageStartDateMaxDate,
            unpaidLeaveMonths,
            unpaidLeaveDays
        };
    }, [form, internalForm, lastRecord]);

    const isNotAllowedToCreate = useMemo(() => {
        const lastRecordEndDate = lastRecord && !lastRecord?.noRecord ? lastRecord[FIELD.COVERAGE_END_DATE] : settlementInfo.employeeJoiningDate;
        if (!lastRecordEndDate) {
            return null;
        }
        const assumedEndDateMinDate = toTimeWithTimeZone(lastRecordEndDate, timezone).add(settlementInfo.toAddInMonths, "months");

        return {
            endDate: toTimeWithTimeZone(lastRecordEndDate, timezone).format(DATE_FORMAT_OPTION.FULL_DATE.format),
            value: assumedEndDateMinDate.isAfter(today),
            assumedEndDateMinDate: assumedEndDateMinDate.format(DATE_FORMAT_OPTION.FULL_DATE.format)
        };
    }, [setting, selectedSupportedType, lastRecord, form[FIELD.COVERAGE_START_DATE], form[FIELD.COVERAGE_END_DATE], settlementInfo.toAddInMonths]);

    return {
        ...settlementInfo,
        isNotAllowedToCreate
    };
};
