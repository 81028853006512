import React, { useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { PAGE_OPTIONS } from "./const";
import Pagination from "./Pagination";
import Table from "./Table";
import { useAppSelector } from "../../../hooks/reduxHooks";
import { selectSidebarCollapse, selectViewport } from "../../../../features/common/slice";
import { selectDate } from "../../../../features/company/employeesSalary/slice";
import { LOADING_TYPE } from "../../../hooks/usePaginateFetch";

const COLLAPSED_SIDEBAR_WIDTH = 128;
const DEFAULT_SIDEBAR_WIDTH = 256;
const AUTH_LAYOUT_WIDTH_SCALE = 0.987;
const LEFT_SIDE_MARGIN = 53;

function TableView({
    headers,
    data,
    refetch,
    height,
    isLoading,
    tableConfig = {},
    styles = {},
    isLazy,
    scale = AUTH_LAYOUT_WIDTH_SCALE,
    beforePaginationExtra,
    ...rest
}) {
    const tableRef = useRef(null);
    const viewport = useAppSelector(selectViewport);

    const isSidebarCollapse = useAppSelector(selectSidebarCollapse);
    const selectedSalaryDate = useAppSelector(selectDate); // only  use if current is on salary page we need to autoadjust when this changes
    const styleBody = { ...(styles?.table?.body || {}) };
    const screenWidth = viewport.width;

    const autoAdjust = () => {
        if (tableRef.current) {
            const sidebarWidth = isSidebarCollapse ? COLLAPSED_SIDEBAR_WIDTH : DEFAULT_SIDEBAR_WIDTH;
            if (sidebarWidth) {
                const responsiveWidth = screenWidth - sidebarWidth - LEFT_SIDE_MARGIN;
                const offEdgeValue = (1 - scale) * responsiveWidth;
                const scaledResponsiveWidth = responsiveWidth - offEdgeValue;
                tableRef.current.style.width = `${scaledResponsiveWidth}px`;
            }
        }
    };

    useEffect(() => {
        autoAdjust();
    }, [tableRef.current, isLoading]);

    useEffect(() => {
        autoAdjust();
    }, [isSidebarCollapse, viewport, tableRef, selectedSalaryDate]);

    const updateTableConfig = (conf, option) => {
        typeof refetch === "function" && refetch(conf, option);
    };

    const handleSort = (sortBy, order) => updateTableConfig({ sortBy, order });

    if (height) {
        styleBody.maxHeight = `calc(100vh - ${height}rem)`;
    }

    return (
        <>
            <Table
                ref={tableRef}
                styles={{
                    ...(styles.table || {}),
                    body: styleBody,
                    parent: {
                        height: "100%",
                        margin: "0 auto"
                    }
                }}
                headers={headers}
                data={data}
                onSort={handleSort}
                activeSort={tableConfig}
                isLoading={isLoading}
                {...rest}
                emptyProps={{
                    ...(rest.emptyProps || {}),
                    style: {
                        ...(rest.emptyProps?.style || {}),
                        ...(tableRef.current ? { width: tableRef.current.style.width } : {})
                    }
                }}
            />
            {!isLoading && !!data.length && (
                <Pagination
                    page={{
                        current: data.length ? tableConfig.page : 0,
                        options: PAGE_OPTIONS,
                        total: tableConfig.totalPage,
                        pageSize: tableConfig.pageSize,
                        totalCount: tableConfig.totalCount
                    }}
                    onRowsChange={(value) => updateTableConfig({ pageSize: value, page: 1 }, { [LOADING_TYPE.CHANGING_ROWS]: true })}
                    onChange={(conf) => updateTableConfig({ page: conf.page.current }, { [LOADING_TYPE.CHANGING_PAGE]: true })}
                    isLoading={isLoading}
                    styles={styles.pagination}
                    isLazy={isLazy}
                    beforeExtra={beforePaginationExtra}
                />
            )}
        </>
    );
}

TableView.propTypes = {
    headers: PropTypes.array,
    data: PropTypes.array,
    refetch: PropTypes.func,
    height: PropTypes.number,
    isLoading: PropTypes.bool,
    isLazy: PropTypes.bool,
    tableConfig: PropTypes.object,
    scale: PropTypes.number,
    beforePaginationExtra: PropTypes.any,
    styles: PropTypes.shape({
        pagination: PropTypes.shape({
            parent: PropTypes.object
        }),
        table: PropTypes.shape({
            parent: PropTypes.object,
            body: PropTypes.object
        })
    })
};

export default TableView;
