import { useEffect, useMemo, useState } from "react";
import isEqual from "lodash/isEqual";
import cloneDeep from "lodash/cloneDeep";
import {
    TOAST_TYPE,
    createToast,
    isFileObject,
    isObjectEqualWithBase,
    sanitizeDateWithLocalDateToOtherTimezoneFromObject,
    sanitizeDatesWithProperTimeConversionFromObject,
    toProperTimezoneConversion,
    toTimeWithTimeZone
} from "../../../common/utilities/helper";
import { useAppDispatch, useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCurrent, selectTableConfig, selectEmployeeLeaveData, setCurrent, setState, defaultConfig } from "./slice";
import {
    useCreateEmployeeLeavesMutation,
    useGetEmployeeLeavesMutation,
    useGetEmployeeLeaveSummaryMutation,
    useLoadAllEmployeeLeavesMutation,
    useLoadAllUnpaidEmployeeLeaveslazyMutation,
    useUpdateEmployeeLeavesMutation,
    useUpdateRejoinedDetailsEmployeeLeavesMutation,
    useUpdateStatusEmployeeLeavesMutation,
    useUploadProofEmployeeLeavesMutation
} from "./api";
import usePaginateFetch from "../../../common/hooks/usePaginateFetch";
import { DATE_FIELDS, FIELD, LEAVE_TYPE } from "./const";
import { selectUserSetting } from "../../common/slice";
import { STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import { useFetchRecord, useUpsertRecord } from "../../common/hooks";

export const useGetLeaveExtraInfo = (form) => {
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isStarted = useMemo(() => {
        if (!form?.[FIELD.START_DATE]) {
            return false;
        }
        const startDate = toProperTimezoneConversion(form?.[FIELD.START_DATE], timezone);
        const today = toProperTimezoneConversion(new Date(), timezone);
        return today.isAfter(startDate);
    }, [form?.[FIELD.START_DATE]]);

    const isEnded = useMemo(() => {
        if (!form?.[FIELD.END_DATE]) {
            return false;
        }
        const endDate = toProperTimezoneConversion(form?.[FIELD.END_DATE], timezone);
        const today = toProperTimezoneConversion(new Date(), timezone);
        return today.isAfter(endDate);
    }, [form?.[FIELD.END_DATE]]);

    return { isStarted, isEnded };
};

export const useGetLeave = (id, { runOnMount, onMount } = {}) => {
    const [current, { isLoading, updateCurrent, fetch, reset, clearCurrent, refetch }] = useFetchRecord(
        {
            id,
            rtk: {
                useGetMutation: useGetEmployeeLeavesMutation,
                selectData: selectEmployeeLeaveData,
                selectCurrent,
                setCurrent,
                setState
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        { runOnMount: !!runOnMount, onMount }
    );

    return [
        current,
        {
            isLoading,
            updateCurrent,
            fetch,
            reset,
            clearCurrent,
            refetch
        }
    ];
};

export const usePaginateLeaves = ({ readOnly } = {}) => {
    const [load, isLoading, { initializing, onFilter, onSearch, data, onSort, onUpdate, tableConfig }] = usePaginateFetch(
        useLoadAllEmployeeLeavesMutation,
        {
            redux: {
                dataSelector: selectEmployeeLeaveData,
                tableConfigSelector: selectTableConfig,
                currentSelector: selectCurrent,
                setState
            },
            defaultConfig,
            onMountConfig: {},
            runOnMount: !readOnly
        }
    );

    const fetch = async (config) => {
        try {
            const response = await load(config);
            if (response.error) {
                throw new Error("Failed to fetch data. Please try again later.");
            }
            return response;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        }
    };

    return [data, { initializing, isLoading, fetch, update: onUpdate, onSort, onFilter, tableConfig, onSearch }];
};

export const useUpsertLeave = (updateId, defaultObject = {}) => {
    const isCreate = !updateId;
    const isUpdate = !!updateId;

    const [uploadProof, { isLoading: isUploading }] = useUploadProofEmployeeLeavesMutation();
    const [current, { isLoading: isGettingRecord }] = useGetLeave(updateId, { runOnMount: true });
    const [form, updateForm, { upsert, isUpserting, isCreating, isUpdating, old, hasChanges }] = useUpsertRecord(
        {
            updateId,
            defaultForm: {
                [FIELD.EMPLOYEE]: null,
                [FIELD.START_DATE]: null,
                [FIELD.END_DATE]: null,
                [FIELD.REJOINED_DATE]: null,
                [FIELD.STATUS]: null,
                [FIELD.PROOF_FILE]: null,
                [FIELD.REASON]: null,
                [FIELD.REJOINED_NOTES]: null,
                [FIELD.OFFSET_HOURS]: null,
                [FIELD.PAYMENT_TYPE]: null,
                [FIELD.LEAVE_TYPE]: null,
                ...defaultObject
            },
            current,
            isGettingRecord,
            rtk: {
                useCreateMutation: useCreateEmployeeLeavesMutation,
                useUpdateMutation: useUpdateEmployeeLeavesMutation,
                setCurrent
            },
            dateFields: DATE_FIELDS,
            dateFormat: STANDARD_DATE_FORMAT
        },
        {
            onBeforeUpsert: async (obj = {}) => {
                let clonedform = cloneDeep(obj);
                clonedform.employee_id = clonedform[FIELD.EMPLOYEE]?.id;
                delete clonedform[FIELD.EMPLOYEE];
                delete clonedform[FIELD.RECORDED_DATE];
                delete clonedform[FIELD.REJOINED_DATE];
                delete clonedform[FIELD.STATUS];
                delete clonedform[FIELD.REJOINED_NOTES];
                delete clonedform[FIELD.PROOF_FILE];
                if (clonedform[FIELD.LEAVE_TYPE] !== LEAVE_TYPE.COMPENSATORY_LEAVE) {
                    delete clonedform[FIELD.OFFSET_HOURS];
                }
                return clonedform;
            },
            onAfterUpsert: async (result, obj = {}) => {
                const clonedform = cloneDeep(obj);
                const proofFile = clonedform[FIELD.PROOF_FILE];
                if (isFileObject(proofFile) && clonedform[FIELD.EMPLOYEE]?.id) {
                    const formData = new FormData();
                    formData.append("leave-proof", proofFile);
                    formData.append(
                        "others",
                        JSON.stringify({
                            leaveId: result.data.data.id
                        })
                    );
                    const uploadres = await uploadProof({
                        formData: true,
                        body: formData,
                        extraPath: clonedform[FIELD.EMPLOYEE]?.id
                    });
                    return uploadres;
                }
            }
        }
    );

    return [
        form,
        updateForm,
        {
            upsert,
            isGettingRecord,
            isUpserting: isUpserting || isUploading,
            isCreating,
            isUpdating,
            old,
            hasChanges,
            isCreate,
            isUpdate
        }
    ];
};

export const useGetLeaveSummary = (employeeId, { runOnMount } = {}) => {
    const [isMounted, setMounted] = useState(true);

    const [data, setData] = useState(null);
    const [fetching, setFetching] = useState(true);
    const [getSummary] = useGetEmployeeLeaveSummaryMutation();

    const fetch = async (newEmployeeId) => {
        try {
            let toFetchId = newEmployeeId || employeeId;
            if (!toFetchId) {
                return data;
            }
            if (!fetching) {
                setFetching(true);
            }
            const result = await getSummary({
                extraPath: toFetchId
            });
            if (result.error) {
                throw new Error("Failed to fetch leave summary for employee. Please try again later");
            }
            setData(result.data.data);
            return result.data.data;
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
            return {};
        } finally {
            setFetching(false);
        }
    };

    useEffect(() => {
        if (runOnMount) {
            if (isMounted) {
                fetch();
            }
        } else if (fetching) {
            setFetching(false);
        }
    }, [isMounted, employeeId]);

    useEffect(() => {
        !isMounted && setMounted(true);
    }, []);

    return [
        data,
        {
            isLoading: fetching,
            fetch
        }
    ];
};

export const useUpdateRejoinedDetails = (updateId, details = {}) => {
    const [old, setOld] = useState({
        [FIELD.REJOINED_DATE]: details[FIELD.REJOINED_DATE],
        [FIELD.REJOINED_NOTES]: details[FIELD.REJOINED_NOTES]
    });
    const [form, setForm] = useState({
        [FIELD.REJOINED_DATE]: details[FIELD.REJOINED_DATE],
        [FIELD.REJOINED_NOTES]: details[FIELD.REJOINED_NOTES]
    });

    const [update, { isLoading: updateIsLoading }] = useUpdateRejoinedDetailsEmployeeLeavesMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const updateRejoinedDetails = async () => {
        try {
            let clonedform = cloneDeep(form);
            // make sure the timezone of the selected dates is inline with the company timezone
            clonedform = sanitizeDateWithLocalDateToOtherTimezoneFromObject(clonedform, timezone, DATE_FIELDS);
            const result = await update({
                body: clonedform,
                extraPath: updateId
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Record updated succesfully.", TOAST_TYPE.SUCCESS);
            const res = sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            dispatch(setCurrent(res));
            setOld(form);
            return res;
        } catch (error) {
            createToast(`Failed to update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            update: updateRejoinedDetails,
            updateIsLoading,
            old,
            hasChanges: !!(old && !isObjectEqualWithBase(form, old))
        }
    ];
};

export const useUpdateStatus = (updateId, details = {}) => {
    const [old, setOld] = useState({
        [FIELD.STATUS]: details[FIELD.STATUS]
    });
    const [form, setForm] = useState({
        [FIELD.STATUS]: details[FIELD.STATUS]
    });

    const [update, { isLoading: updateIsLoading }] = useUpdateStatusEmployeeLeavesMutation();

    const dispatch = useAppDispatch();
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const updateStatus = async (newConfig = {}) => {
        try {
            const clonedForm = cloneDeep({
                ...form,
                ...newConfig
            });
            const result = await update({
                body: clonedForm,
                extraPath: updateId
            });
            if (result.error) {
                throw new Error(result.error?.data?.message);
            }
            createToast("Record updated succesfully.", TOAST_TYPE.SUCCESS);
            const res = sanitizeDatesWithProperTimeConversionFromObject(result.data.data, timezone, DATE_FIELDS, STANDARD_DATE_FORMAT);
            dispatch(setCurrent(res));
            setOld(clonedForm);
            return res;
        } catch (error) {
            createToast(`Failed to update record. ${error?.message || "Please try again later or contact support."} `, TOAST_TYPE.ERROR);
            return { error };
        }
    };

    const updateForm = (config = {}) =>
        setForm({
            ...form,
            ...config
        });

    return [
        form,
        updateForm,
        {
            update: updateStatus,
            updateIsLoading,
            old,
            hasChanges: !!(old && !isObjectEqualWithBase(form, old))
        }
    ];
};

export const useLazyUnpaidLeaves = ({ onMount, employee_id, coverage_start_date, coverage_end_date, isRawResult } = {}) => {
    const LOAD_MORE_OFFSET = 10;
    const DEFAULT_SIZE = 20;

    const [initializing, setInitializing] = useState(true);
    const [isMounted, setMounted] = useState(false);
    const [object, setObject] = useState({
        data: [],
        totalCount: 0,
        cursor: ""
    });

    const hasMore = object.totalCount > object.data?.length || 0;

    const [load, { isLoading }] = useLoadAllUnpaidEmployeeLeaveslazyMutation();

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const updateObject = (newObject = {}) => setObject((prev) => ({ ...prev, ...newObject }));

    const fetch = async ({ sort, ...config } = {}, isReset) => {
        let extraPath = "";

        if (!sort) {
            sort = object.sort;
        }
        if (isReset) {
            config.cursor = "";
        }

        try {
            const response = await load({
                extraPath,
                body: {
                    employee_id,
                    coverage_start_date: toTimeWithTimeZone(coverage_start_date, timezone).format(),
                    coverage_end_date: toTimeWithTimeZone(coverage_end_date, timezone).format(),
                    isRawResult,
                    pageSize: DEFAULT_SIZE,
                    more: isReset ? DEFAULT_SIZE : LOAD_MORE_OFFSET,
                    ...object.sort,
                    ...sort,
                    ...(config || {})
                }
            });

            if (response.error) {
                throw new Error(response.error?.data?.message);
            }
            if (response.data && response.data.data) {
                let resdata = response.data.data || [];
                let newdata = [...resdata.data];
                const sameCursor = isEqual(object.cursor, resdata.cursor);
                const temp = {
                    data: isReset ? newdata : !sameCursor ? object.data.concat(newdata) : object.data,
                    cursor: resdata.cursor,
                    totalCount: resdata.totalCount
                };
                sort && (temp.sort = sort);
                updateObject(temp);
                return temp.data;
            }
        } catch (error) {
            createToast(error.message || "Something went wrong with the server. Please contact support.", TOAST_TYPE.ERROR);
            sort && updateObject({ sort, data: [], totalCount: 0 });
        }
    };

    const loadMore = () => hasMore && fetch({ cursor: object.cursor });

    const reset = () => fetch({}, true);

    const search = (value = "") => {
        fetch({ search: value }, true);
        updateObject({ search: value });
    };

    const sort = ({ sortBy, order }) => fetch({ sort: { sortBy, order } }, true);

    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (isMounted) {
            fetch()
                .then(onMount)
                .finally(() => setInitializing(false));
        }
    }, [isMounted]);

    return [object, updateObject, { initializing, isLoading, hasMore, fetch, reset, loadMore, search, sort }];
};
