import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import TableView from "../../../common/components/extra/table/TableView";
import { ASSIGN_TYPE } from "../employees/const";
import { selectUser } from "../../common/slice";
import CreateTableHeaders from "./CreateTableHeaders";
import UpdateModal from "./UpdateModal";
import { useDeleteWorkShift, usePaginateWorkShift } from "./hooks";
import TransferAssignEmployeesModal from "../employees/TransferAssignEmployeesModal";
import ViewModal from "./ViewModal";

function WorkShift({ openAddModal, onAddModalClose, isHoliday, parentIsDoingActions }) {
    const [object, setObject] = useState({
        openViewModal: false,
        openEditModal: openAddModal,
        selected: null,
        openAssignEmployeesModal: false
    });

    const [data, { initializing, fetch, tableConfig, isDoingActions }] = usePaginateWorkShift({ isHoliday });
    const [remove] = useDeleteWorkShift();

    const user = useAppSelector(selectUser);

    const departments = (user.Setting && user.Setting.departments) || [];
    const showViewModal = !!object.openViewModal;
    const showUpdateModal = object.openEditModal;
    const isPaginating = initializing || parentIsDoingActions || isDoingActions;

    const updateObject = (newObj = {}) => setObject((prev) => ({ ...prev, ...newObj }));

    useEffect(() => {
        if (openAddModal && openAddModal !== object.openEditModal) {
            updateObject({ selected: null, openEditModal: openAddModal });
        }
    }, [openAddModal]);

    const handleModalBack = () => {
        updateObject({
            openEditModal: false,
            openViewModal: true
        });
    };

    const handleModalEdit = () => {
        updateObject({
            openEditModal: true,
            openViewModal: false
        });
    };

    const handleFinish = async (res) => {
        fetch({}, isHoliday);
        updateObject({ selected: res, viewSites: false, openEditModal: false, openViewModal: true });
        typeof onAddModalClose == "function" && onAddModalClose();
    };

    const handleViewModalClose = () =>
        updateObject({
            openViewModal: false
        });

    const handleUpdateModalClose = () => {
        typeof onAddModalClose === "function" && onAddModalClose();
        updateObject({
            openEditModal: false
        });
    };

    return (
        <>
            <TableView
                data={data}
                headers={CreateTableHeaders({
                    departments,
                    onView: (row) => updateObject({ openViewModal: true, selected: row }),
                    onDelete: (row) => remove(row.id).then(fetch),
                    onAssignEmployees: (row) => updateObject({ openAssignEmployeesModal: true, selected: row })
                })}
                refetch={(conf, option) => fetch(conf, option, isHoliday)}
                isLoading={isPaginating}
                tableConfig={tableConfig}
            />

            {showViewModal && <ViewModal id={object?.selected?.id} open={showViewModal} onClose={handleViewModalClose} onEdit={handleModalEdit} />}
            {showUpdateModal && (
                <UpdateModal
                    onBack={handleModalBack}
                    open={showUpdateModal}
                    onClose={handleUpdateModalClose}
                    onFinish={handleFinish}
                    id={object?.selected?.id}
                />
            )}
            {object.openAssignEmployeesModal && (
                <TransferAssignEmployeesModal
                    open={object.openAssignEmployeesModal}
                    onClose={() => updateObject({ selected: null, openAssignEmployeesModal: false })}
                    data={object.selected}
                    type={ASSIGN_TYPE.WORK_SHIFT}
                />
            )}
        </>
    );
}

WorkShift.propTypes = {
    parentIsDoingActions: PropTypes.bool,
    openAddModal: PropTypes.bool,
    onAddModalClose: PropTypes.func,
    isHoliday: PropTypes.bool,
    isRegular: PropTypes.bool
};

export default WorkShift;
