import React from "react";
import PropTypes from "prop-types";
import { isScrollAtBottom } from "../../utilities/helper";
import Empty from "./Empty";

function GridView({ renderItem, items, onLoadMore, isLoading, skeleton, styles = {}, initializing }) {
    const hasItems = !!items.length;

    const handleScroll = (e) => {
        const isBottom = isScrollAtBottom(e.target, 100);
        if (isBottom) {
            typeof onLoadMore === "function" && onLoadMore();
        }
    };

    const createSiteCard = ({ idx, item = {} } = {}) => {
        return (
            <li key={(item && item.id) || idx} className="tk-grid-view__item">
                {(typeof renderItem === "function" && renderItem(item)) || renderItem}
            </li>
        );
    };

    return (
        <div className="tk-grid-view" onScroll={handleScroll} style={styles.parent}>
            <ul className="tk-grid-view__list" style={styles.body}>
                {hasItems ? (
                    items.map((item, idx) => createSiteCard({ idx, item }))
                ) : (
                    <Empty className="abs-center" style={{ margin: "auto" }} isLoading={initializing} />
                )}
                {hasItems && !initializing && isLoading && skeleton && <>{skeleton}</>}
            </ul>
        </div>
    );
}

GridView.propTypes = {
    renderItem: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func]),
    skeleton: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node, PropTypes.func]),
    items: PropTypes.array,
    onLoadMore: PropTypes.func,
    isLoading: PropTypes.bool,
    initializing: PropTypes.bool,
    totalCount: PropTypes.number,
    styles: PropTypes.shape({
        parent: PropTypes.object,
        body: PropTypes.number
    })
};
export default GridView;
