import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";

function InputRadio({ options = [], name, value: propValue, onChange, ...inputProps }) {
    const [selectedValue, setSelectedValue] = useState("");

    useEffect(() => {
        if (!isEqual(selectedValue, propValue)) {
            setSelectedValue(propValue);
        }
    }, [propValue]);

    const handleChange = (e) => {
        const newValue = e.target.value;
        setSelectedValue(newValue);
        if (typeof onChange === "function") {
            onChange(e);
        }
    };

    return (
        <div className="radio flex gap-05 align-center">
            {options.map((option, index) => (
                <label key={index} className="flex gap-05 align-center semi-bold">
                    <input
                        {...inputProps}
                        type="radio"
                        name={name}
                        value={option.value}
                        checked={selectedValue === option.value}
                        onChange={handleChange}
                    />
                    {option.label}
                </label>
            ))}
        </div>
    );
}

InputRadio.propTypes = {
    options: PropTypes.arrayOf(
        PropTypes.shape({
            label: PropTypes.any.isRequired,
            value: PropTypes.any.isRequired
        })
    ).isRequired,
    name: PropTypes.string.isRequired,
    value: PropTypes.string,
    onChange: PropTypes.func
};

export default InputRadio;
