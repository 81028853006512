import React, { useCallback, useEffect, useRef, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import isEqual from "lodash/isEqual";
import isUndefined from "lodash/isUndefined";
import useDetectOutsideClick from "../../hooks/useDetectOutsideClick";

export const POPOVER_POSITION = {
    TOP: "top",
    TOP_LEFT: "top-left",
    TOP_RIGHT: "top-right",
    LEFT: "left",
    RIGHT: "right",
    BOTTOM: "bottom",
    BOTTOM_LEFT: "bottom-left",
    BOTTOM_RIGHT: "bottom-right"
};

export const EVENT_TYPE = {
    DEFAULT: "DEFAULT",
    OUTSIDE_CLICK: "OUTSIDE_CLICK"
};

export const getFixedElementPositionRelativeToParent = ({ parentElement, fixedElement, offset, position = POPOVER_POSITION.LEFT } = {}) => {
    const rect = parentElement?.getBoundingClientRect();
    const contentRect = fixedElement?.getBoundingClientRect() || rect;

    const scrollX = window.scrollX || window.pageXOffset;
    const scrollY = window.scrollY || window.pageYOffset;

    if (!rect) {
        return null;
    }

    let top = rect.top + scrollY;
    let left = rect.left + scrollX;

    switch (position) {
        case POPOVER_POSITION.BOTTOM: {
            top = rect.bottom + scrollY + offset;
            left = rect.left + scrollX - contentRect.width / 2 + rect.width / 2;
            break;
        }
        case POPOVER_POSITION.TOP: {
            top = rect.top + scrollY - contentRect.height - offset;
            left = rect.left + scrollX - contentRect.width / 2 + rect.width / 2;
            break;
        }
        case POPOVER_POSITION.RIGHT: {
            top = rect.top + scrollY - contentRect.height / 2 + rect.height / 2;
            left = rect.right + scrollX + offset;
            break;
        }
        case POPOVER_POSITION.LEFT: {
            top = rect.top + scrollY - contentRect.height / 2 + rect.height / 2;
            left = rect.left + scrollX - contentRect.width - offset;
            break;
        }
        case POPOVER_POSITION.BOTTOM_RIGHT: {
            top = rect.bottom + scrollY + offset;
            left = rect.right + scrollX - rect.width;
            break;
        }
        case POPOVER_POSITION.BOTTOM_LEFT: {
            top = rect.bottom + scrollY + offset;
            left = rect.right + scrollX - contentRect.width;
            break;
        }
        case POPOVER_POSITION.TOP_RIGHT: {
            top = rect.top + scrollY - contentRect.height - offset;
            left = rect.left + scrollX - contentRect.width + rect.width;
            break;
        }
        case POPOVER_POSITION.TOP_LEFT: {
            top = rect.top + scrollY - contentRect.height - offset;
            left = rect.left + scrollX;
            break;
        }
        default:
            break;
    }

    return { top, left };
};

function Popover({
    open = false,
    controlled = false,
    children,
    content,
    position = POPOVER_POSITION.BOTTOM_LEFT,
    styles = {},
    onChange,
    noArrow,
    unCentered,
    staticParent,
    onClose,
    onPreventOutsideTrigger,
    onPreventOpenTrigger,
    onOutsideClick,
    isPortal,
    offset = 5 // offset away from element
}) {
    const ref = useRef(null);
    const contentRef = useRef(null);

    const [object, setObject] = useState({ open });
    const [isClickedOutside] = useDetectOutsideClick(ref, onOutsideClick, onPreventOutsideTrigger);
    const [portalStyle, setPortalStyle] = useState({});

    const isActive = controlled ? open : object.open;

    const updateObject = (newObj = {}, type) => {
        if (!controlled) {
            if (!newObj.open) onClose?.(type);
            setObject((prev) => ({ ...prev, ...newObj }));
        }
        typeof onChange == "function" && onChange(newObj.open, type);
    };

    useEffect(() => {
        const handleScroll = () => {
            if (controlled) {
                onOutsideClick?.(EVENT_TYPE.OUTSIDE_CLICK);
            } else if (object.open) {
                updateObject({ open: false }, EVENT_TYPE.OUTSIDE_CLICK);
            }
        };
        window.addEventListener("scroll", handleScroll, true);
        return () => {
            window.removeEventListener("scroll", handleScroll, true);
        };
    }, [object.open, controlled, onChange]);

    useEffect(() => {
        if (isClickedOutside) {
            if (controlled) {
                onOutsideClick?.(EVENT_TYPE.OUTSIDE_CLICK);
            } else if (object.open) {
                updateObject({ open: false }, EVENT_TYPE.OUTSIDE_CLICK);
            }
        }
    }, [isClickedOutside]);

    useEffect(() => {
        if (!controlled && !isUndefined(open) && !isEqual(open, object.open)) {
            setObject({ open });
        }
    }, [open]);

    const updatePortalStyle = useCallback(() => {
        if (isPortal && isActive && contentRef.current && ref.current) {
            setTimeout(() => {
                const coordinates = getFixedElementPositionRelativeToParent({
                    parentElement: ref.current,
                    fixedElement: contentRef.current,
                    position,
                    offset
                });

                if (coordinates) {
                    setPortalStyle({
                        position: "absolute",
                        top: `${coordinates.top}px`,
                        left: `${coordinates.left}px`,
                        transform: "unset",
                        bottom: "unset",
                        ...styles.content
                    });
                }
            }, 0);
        }
    }, [isActive, isPortal, position, styles.content]);

    useEffect(() => {
        updatePortalStyle();
    }, [isActive, isPortal, position, styles.content, updatePortalStyle, contentRef?.current?.getBoundingClientRect?.()]);

    const createClassName = () => {
        let className = "tk-popover ";
        if (isActive) className += "active ";
        if (unCentered) className += "no-center ";
        return className.trim();
    };

    const createContent = () => {
        return (
            <div
                ref={contentRef}
                className={`tk-popover__content tk-popover--${position} ${noArrow ? "no-arrow" : ""}`.trim()}
                style={
                    isPortal
                        ? portalStyle
                        : {
                              ...portalStyle,
                              ...styles.content,
                              ...(unCentered ? { left: "unset", right: "unset", transform: "unset" } : {})
                          }
                }
            >
                {content}
            </div>
        );
    };

    return (
        <div ref={ref} className={createClassName()} style={{ ...styles.parent, position: staticParent ? "static" : "relative" }}>
            {React.Children.map(children, (child) => {
                if (React.isValidElement(child)) {
                    return React.cloneElement(child, {
                        onClick: (e) => {
                            const isPreventOpen = onPreventOpenTrigger?.(e);
                            if (isPreventOpen) return;
                            updateObject({ open: !isActive }, EVENT_TYPE.DEFAULT);
                        }
                    });
                }
                return child;
            })}
            {isActive && (isPortal ? createPortal(createContent(), document.body) : createContent())}
        </div>
    );
}

Popover.propTypes = {
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    content: PropTypes.oneOfType([PropTypes.node, PropTypes.element, PropTypes.string]),
    position: PropTypes.oneOf(Object.values(POPOVER_POSITION)),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        content: PropTypes.object
    }),
    onChange: PropTypes.func,
    onOutsideClick: PropTypes.func,
    onClose: PropTypes.func,
    onPreventOutsideTrigger: PropTypes.func,
    onPreventOpenTrigger: PropTypes.func,
    noArrow: PropTypes.bool,
    unCentered: PropTypes.bool,
    staticParent: PropTypes.bool,
    open: PropTypes.bool,
    isPortal: PropTypes.bool,
    controlled: PropTypes.bool,
    offset: PropTypes.number
};

export default Popover;
