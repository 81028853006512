import React, { useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetEmployeeOtherIncomes } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";
import { createClass, sanitizeTimestamp, sanitizeWords, toProperTimezoneConversion, toReadableFromDate } from "../../../common/utilities/helper";
import { BASE_CLASS, FIELD } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewEmployeeModal from "../employees/ViewModal";
import SectionCollapseInfo from "../../../common/components/extra/section/SectionCollapseInfo";
import MarkAsPaid from "./MarkAsPaid";

const { INCOME_TYPE, LABEL, AMOUNT, START_DATE, STATUS, CONFIRMED_DATE, NOTES } = FIELD;

function ViewModal({ open, onClose, onEdit, id }) {
    const [viewEmployee, setViewEmployee] = useState(false);
    const [current, { isInitial, isLoading, config, update }] = useGetEmployeeOtherIncomes(id);

    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;
    const currency = setting.currency;

    const COMMON_PROPS = { readOnly: true, isLoading };

    const handleViewEmployee = () => setViewEmployee(true);

    const renderEmployee = () => {
        return (
            <div className="flex" style={{ alignItems: "center" }}>
                <span>{sanitizeWords(current?.employee?.fullName)}</span>
                <Button
                    options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                    onClick={handleViewEmployee}
                    transparent
                    small
                >
                    View
                </Button>
            </div>
        );
    };

    return (
        <BaseViewModal
            open={open}
            onClose={onClose}
            onEdit={onEdit}
            disableEdit={isInitial || !current || !!current?.[CONFIRMED_DATE]}
            headExtra={
                <div className="flex align-center">
                    <MarkAsPaid id={id} onFinish={update} current={current} />
                </div>
            }
            styles={{
                content: {
                    width: "50vw",
                    maxWidth: "45rem",
                    minWidth: "35rem",
                    height: "min-content"
                }
            }}
        >
            <div className={createClass("__modal-content-view flex column gap-1", BASE_CLASS)}>
                {!!current?.[CONFIRMED_DATE] && (
                    <SectionCollapseInfo title="Reminder" show>
                        Edit and delete options are unavailable once the record is confirmed or included in the salary computation.
                    </SectionCollapseInfo>
                )}
                <FormSection header="Other Income Details">
                    <Input label="Employee" renderValue={renderEmployee()} {...COMMON_PROPS} />
                    <Input
                        label="Starts In"
                        renderValue={toProperTimezoneConversion(current?.[START_DATE], timezone)?.format?.("MMM YYYY")}
                        {...COMMON_PROPS}
                    />
                    <Input label="Amount" renderValue={sanitizeWords(config?.[AMOUNT])} {...COMMON_PROPS} afterExtra={<span>{currency}</span>} />
                    <Input label="Label" renderValue={sanitizeWords(current?.[LABEL])} {...COMMON_PROPS} />
                    <Input label="Type" renderValue={sanitizeWords(current?.[INCOME_TYPE])} {...COMMON_PROPS} />
                    <Input
                        label="Notes/Description"
                        renderValue={<p style={{ marginLeft: "2rem" }}>{current && sanitizeWords(current?.[NOTES])}</p>}
                        {...COMMON_PROPS}
                    />
                </FormSection>
                <FormSection header="Record Details">
                    <Input label="Status" renderValue={sanitizeWords(current?.[STATUS])} {...COMMON_PROPS} />
                    <Input label="Confirmed Date" renderValue={toReadableFromDate(current?.[CONFIRMED_DATE], timezone)} {...COMMON_PROPS} />
                    <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                    <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                </FormSection>
            </div>
            {!!viewEmployee && current?.employee?.id && (
                <ViewEmployeeModal id={current.employee.id} open={viewEmployee} onClose={() => setViewEmployee(false)} readOnly />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
