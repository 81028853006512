import React, { useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input, { INPUT_TYPE } from "../../../common/components/extra/form/Input";
import {
    createTimeFromDate,
    renderNA,
    sanitizeTimestamp,
    sanitizeWords,
    toProperTimezoneConversion,
    toReadableFromDate,
    toReadablePhone
} from "../../../common/utilities/helper";
import Button from "../../../common/components/extra/Button";
import ViewModalSub from "./ViewModalSub";
import { VIEW_MODAL_TYPE } from "./const";
import ViewGeozonesModal from "../companySites/ViewGeozonesModal";
import ViewWorkDetailsModal from "../employeeWorkDetails/ViewWorkDetailsModal";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useGetEmployee } from "./hooks";
import ResetDeviceButton from "./ResetDeviceButton";
import InputEmployeeFile from "./InputEmployeeFile";
import { EMPLOYEE_FILES, STANDARD_DATE_FORMAT } from "../../../common/utilities/const";
import UpdateFiles from "./UpdateFiles";
import WarningColorSwitcher from "../../../common/components/extra/WarningColorSwitcher";
import UpdateDepartmentButton from "./UpdateDepartmentButton";
import Overview from "./Overview";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectCountry } from "../../common/slice";
// import ManagingEmployeesButton from "./ManagingEmployeesButton";

const { PROJECT, SALARY, GEOZONE } = VIEW_MODAL_TYPE;

function ViewModal({ open, onChange, onClose, onEdit, id, cachedCurrent = true, readOnly }) {
    const [viewObject, setViewObject] = useState({ type: null, data: null });

    const [row, { isLoading, config }] = useGetEmployee(id, cachedCurrent);

    const country = useAppSelector(selectCountry);
    const isInUAE = country === "ae";

    const {
        fullName,
        nationality,
        timezone,
        department,
        designation,
        contract,
        bank,
        workshift,
        sites,
        countries,
        supervisingSites,
        managingSites,
        supervisingDepts,
        managingDepts
    } = config || {};

    const showModalSub = viewObject.type && ![GEOZONE, PROJECT].includes(viewObject.type);
    const showGeo = viewObject.type === GEOZONE;
    const showWorkTypes = viewObject.type === PROJECT;

    const validity = row?.validity || {};
    const contractValidity = validity?.contract || {};
    const passportValidity = validity?.passportID || {};
    const residenceValidity = validity?.residenceID || {};
    const visaValidity = validity?.visaID || {};
    const laborCardValidity = validity?.labor_card || {};

    const handleViewChange = (newObject = {}) => setViewObject({ ...viewObject, ...newObject });

    const renderViewButton = (type, data) => (
        <div>
            <Button
                options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                onClick={() => handleViewChange({ type, data })}
                transparent
                small
            >
                View
            </Button>
        </div>
    );

    const headExtra = (
        <>
            {!readOnly && <UpdateFiles id={id} disabled={isLoading} />}
            <UpdateDepartmentButton id={id} readOnly={readOnly} disabled={isLoading} />
            {/* <ManagingEmployeesButton id={id} readOnly={readOnly} disabled={isLoading} /> */}
            {!readOnly && row.device ? (
                <ResetDeviceButton id={row.id} device={{ name: row.device?.device_name, model: row.device?.model }} disabled={isLoading} />
            ) : (
                <Button className="secondary" disabled small mini>
                    <span>No Registered Device</span>
                </Button>
            )}
        </>
    );

    const COMMON_INPUT_PROPS = { type: INPUT_TYPE.TEXT, isLoading, readOnly: true };

    const renderExpiryDate = (obj = {}, label, value) => {
        return (
            <Input
                label={label}
                renderValue={
                    value ? (
                        <div className="flex gap-05 align-center">
                            {(obj.isExpired || obj.isWarning) && (
                                <WarningColorSwitcher
                                    style={{ width: "1rem" }}
                                    tooltip={{
                                        message: obj.isWarning ? "Is Expiring" : "Is Expired",
                                        className: "flex align-center"
                                    }}
                                    danger={obj.isExpired}
                                    warning={obj.isWarning}
                                />
                            )}
                            <span className={obj.isExpired ? "danger-color" : obj.isWarning ? "warning-color" : ""} style={{ marginTop: "2px" }}>
                                {toProperTimezoneConversion(value).format(STANDARD_DATE_FORMAT)}
                            </span>
                        </div>
                    ) : (
                        renderNA()
                    )
                }
                {...COMMON_INPUT_PROPS}
            />
        );
    };

    return (
        <BaseViewModal
            open={open}
            onChange={onChange}
            onClose={onClose}
            onEdit={readOnly || isLoading ? null : onEdit}
            headExtra={headExtra}
            styles={{
                content: {
                    width: "70vw",
                    minWidth: "35rem",
                    maxWidth: "50rem"
                }
            }}
        >
            <div className="tk-employees__modal-content-view">
                <Overview
                    fullName={fullName}
                    row={row}
                    isLoading={isLoading}
                    supervisingDepts={supervisingDepts}
                    managingDepts={managingDepts}
                    supervisingSites={supervisingSites}
                    managingSites={managingSites}
                    department={department}
                    designation={designation}
                />
                <FormSection header="Personal Information">
                    <Input
                        label="Employee ID"
                        renderValue={row.generatedID && <span className="fade">{row.generatedID}</span>}
                        tooltip="System Generated ID"
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Company ID" renderValue={row.companyID} {...COMMON_INPUT_PROPS} />
                    <Input label="Name" renderValue={sanitizeWords(fullName)} {...COMMON_INPUT_PROPS} />
                    <Input label="Gender" renderValue={sanitizeWords(row.gender)} {...COMMON_INPUT_PROPS} />
                    <Input label="Nationality" renderValue={nationality?.demonyms?.eng?.m || nationality?.name?.common} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Birthdate"
                        renderValue={row.birthdate && toProperTimezoneConversion(row.birthdate, timezone).format(STANDARD_DATE_FORMAT)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Marital Status" renderValue={sanitizeWords(row.marital_status)} {...COMMON_INPUT_PROPS} />
                    <Input label="Mobile No." renderValue={toReadablePhone(row.mobile_number)} {...COMMON_INPUT_PROPS} />
                    <Input label="Email Address" renderValue={row.email_address} {...COMMON_INPUT_PROPS} />
                    <Input label="Home Address" renderValue={row.home_address} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Documents or IDs">
                    <InputEmployeeFile
                        keyName={EMPLOYEE_FILES.CONTRACT.key}
                        id={row.id}
                        style={{ marginTop: ".5rem" }}
                        value={row.uploads?.[EMPLOYEE_FILES.CONTRACT.key]}
                        label={<span className="bold">Contract Expiry Date</span>}
                        view={{
                            extra: (
                                <div className="flex gap-05 align-center">
                                    {(contractValidity.isExpired || contractValidity.isWarning) && (
                                        <WarningColorSwitcher
                                            style={{ width: "1rem" }}
                                            tooltip={{
                                                message: contractValidity.isWarning ? "Is Expiring" : "Is Expired",
                                                className: "flex align-center"
                                            }}
                                            danger={contractValidity.isExpired}
                                            warning={contractValidity.isWarning}
                                        />
                                    )}
                                    <span
                                        className={(
                                            (contractValidity.isExpired ? "danger-color" : contractValidity.isWarning ? "warning-color" : "") +
                                            " bold"
                                        ).trim()}
                                        style={{ marginTop: "2px" }}
                                    >
                                        {contract?.end_date
                                            ? toProperTimezoneConversion(contract?.end_date, timezone).format(STANDARD_DATE_FORMAT)
                                            : renderNA()}
                                    </span>
                                </div>
                            )
                        }}
                        isLoading={isLoading}
                    />

                    <InputEmployeeFile
                        keyName={EMPLOYEE_FILES.PASSPORT.key}
                        id={row.id}
                        style={{ marginTop: ".5rem" }}
                        value={row.uploads?.[EMPLOYEE_FILES.PASSPORT.key]}
                        label="Passport ID"
                        view={{ extra: row?.passportID }}
                        isLoading={isLoading}
                    />

                    {renderExpiryDate(passportValidity, "Passport ID Exp. Date", row.passportID_expiration)}

                    <InputEmployeeFile
                        keyName={EMPLOYEE_FILES.RESIDENCE.key}
                        id={row.id}
                        style={{ marginTop: ".5rem" }}
                        value={row.uploads?.[EMPLOYEE_FILES.RESIDENCE.key]}
                        label="Residence ID"
                        view={{ extra: row?.residenceID || renderNA() }}
                        isLoading={isLoading}
                    />

                    {renderExpiryDate(residenceValidity, "Residence ID Exp. Date", row.residenceID_expiration)}

                    {/* Render Labor Card fields only if country is UAE */}
                    {isInUAE && (
                        <>
                            <InputEmployeeFile
                                keyName={EMPLOYEE_FILES.LABOR_CARD.key}
                                id={row.id}
                                style={{ marginTop: ".5rem" }}
                                value={row.uploads?.[EMPLOYEE_FILES.LABOR_CARD.key]}
                                label="Labor Card Number"
                                view={{ extra: row?.labor_card_number || renderNA() }}
                                isLoading={isLoading}
                            />

                            {renderExpiryDate(laborCardValidity, "Labor Card Exp. Date", row.labor_card_expiration)}
                        </>
                    )}

                    <InputEmployeeFile
                        keyName={EMPLOYEE_FILES.VISA.key}
                        id={row.id}
                        style={{ marginTop: ".5rem" }}
                        value={row.uploads?.[EMPLOYEE_FILES.VISA.key]}
                        label="Visa ID"
                        view={{ extra: row?.visaID || renderNA() }}
                        isLoading={isLoading}
                    />

                    {renderExpiryDate(visaValidity, "Visa ID Exp. Date", row.visaID_expiration)}
                </FormSection>
                <FormSection header="Work Details">
                    <Input label="Grade" renderValue={row.grade && row.grade.toUpperCase()} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Direct Supervisor"
                        renderValue={row.directSupervisor && sanitizeWords(`${row.directSupervisor.first_name} ${row.directSupervisor.last_name}`)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="Direct Manager"
                        renderValue={row.directManager && sanitizeWords(`${row.directManager.first_name} ${row.directManager.last_name}`)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Work Types" renderValue={renderViewButton(PROJECT, row?.work_detail_ids || [])} {...COMMON_INPUT_PROPS} />
                    <Input label="Geozones" renderValue={renderViewButton(GEOZONE, sites)} {...COMMON_INPUT_PROPS} />
                    <Input label="Department" renderValue={sanitizeWords(department?.title)} {...COMMON_INPUT_PROPS} />
                    <Input label="Designation" renderValue={sanitizeWords(designation?.title)} {...COMMON_INPUT_PROPS} />
                    <Input label="Total Days Worked" renderValue={row.totalWorkingDays} afterExtra={renderNA("Day(s)")} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Work Shift">
                    <Input
                        label="Daily Work Hours"
                        renderValue={workshift?.required_shift_time}
                        afterExtra={renderNA("Hour(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Max OT hours/day" renderValue={workshift?.max_overtime} afterExtra={renderNA("Hour(s)")} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Number of Weekly Off Days"
                        renderValue={row.number_weekly_off_days}
                        afterExtra={renderNA("Day(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="Off Days"
                        renderValue={Array.isArray(row.off_days) && row.off_days.map((off) => sanitizeWords(off))}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Shift" renderValue={createTimeFromDate(workshift?.start_time, workshift?.end_time)} {...COMMON_INPUT_PROPS} />
                    <Input label="Break" renderValue={createTimeFromDate(workshift?.break_time, workshift?.break_end_time)} {...COMMON_INPUT_PROPS} />
                    <Input label="Schedule" renderValue={sanitizeWords(row.schedule_type)} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Contract Details">
                    <Input label="Contract Status" renderValue={sanitizeWords(contract.status)} {...COMMON_INPUT_PROPS} />
                    <Input label="Contract Type" renderValue={sanitizeWords(contract.contract_type)} {...COMMON_INPUT_PROPS} />
                    <Input label="Contract Term" renderValue={sanitizeWords(contract.contract_term)} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Hiring Date"
                        renderValue={contract.hiring_date && toProperTimezoneConversion(contract.hiring_date).format(STANDARD_DATE_FORMAT)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="Joining Date"
                        renderValue={contract.joining_date && toProperTimezoneConversion(contract.joining_date).format(STANDARD_DATE_FORMAT)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="End Date"
                        renderValue={contract.end_date && toProperTimezoneConversion(contract.end_date).format(STANDARD_DATE_FORMAT)}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input
                        label="Probation Period"
                        renderValue={contract.probation_period}
                        afterExtra={renderNA("Month(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Salary Frequency" renderValue={sanitizeWords(contract?.salary_frequency)} {...COMMON_INPUT_PROPS} />
                    <Input label="Salary Payment Mode" renderValue={sanitizeWords(row.salary_payment_mode)} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Annual Leave"
                        renderValue={row.annualLeave <= 0 ? renderNA("Not Eligible") : row.annualLeave}
                        afterExtra={row.annualLeave > 0 && renderNA("Day(s)")}
                        {...COMMON_INPUT_PROPS}
                    />
                    <Input label="Air Ticket & Salary Details" renderValue={renderViewButton(SALARY, row)} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Job Description"
                        renderValue={contract.job_description && <p style={{ marginLeft: "1rem" }}>{contract.job_description}</p>}
                        {...COMMON_INPUT_PROPS}
                    />
                </FormSection>
                <FormSection header="Bank Details">
                    <Input label="Short Name" renderValue={bank?.short_name} {...COMMON_INPUT_PROPS} />
                    <Input label="Account #" renderValue={bank?.account_number} {...COMMON_INPUT_PROPS} />
                    <Input label="IBAN" renderValue={bank?.iban} {...COMMON_INPUT_PROPS} />
                </FormSection>
                <FormSection header="Paycard Details">
                    <Input label="Account #" renderValue={bank?.paycard_number} {...COMMON_INPUT_PROPS} />
                    <Input
                        label="Expiration Date"
                        renderValue={bank?.paycard_expiration && toProperTimezoneConversion(bank?.paycard_expiration).format(STANDARD_DATE_FORMAT)}
                        {...COMMON_INPUT_PROPS}
                    />
                </FormSection>
                {row?.device && (
                    <FormSection header="Device">
                        <Input label="Name" renderValue={row.device?.device_name} {...COMMON_INPUT_PROPS} />
                        <Input label="Model" renderValue={row.device?.model} {...COMMON_INPUT_PROPS} />
                        <Input label="IP" renderValue={row.device?.device_ip} {...COMMON_INPUT_PROPS} />
                        <Input label="MAC Address" renderValue={row.device?.mac_address} {...COMMON_INPUT_PROPS} />
                    </FormSection>
                )}
                <FormSection header="Other Details">
                    <Input label="Created" renderValue={toReadableFromDate(row?.createdAt, timezone)} {...COMMON_INPUT_PROPS} />
                    <Input label="Updated" renderValue={sanitizeTimestamp(row?.updatedAt, row?.createdAt, timezone)} {...COMMON_INPUT_PROPS} />
                </FormSection>
            </div>
            {!!showModalSub && (
                <ViewModalSub
                    open={!!viewObject.type}
                    onChange={(bool) => handleViewChange({ type: bool ? viewObject.type : null })}
                    type={viewObject.type}
                    data={viewObject.data}
                    countries={countries}
                />
            )}
            {!!showGeo && (
                <ViewGeozonesModal open={showGeo} onChange={(bool) => handleViewChange({ type: bool ? GEOZONE : null })} data={viewObject.data} />
            )}
            {!!showWorkTypes && (
                <ViewWorkDetailsModal
                    open={showWorkTypes}
                    onChange={(bool) => handleViewChange({ type: bool ? PROJECT : null })}
                    data={viewObject.data}
                />
            )}
        </BaseViewModal>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    onClose: PropTypes.func,
    id: PropTypes.any,
    onChange: PropTypes.func,
    onEdit: PropTypes.func,
    cachedCurrent: PropTypes.bool,
    readOnly: PropTypes.bool
};

export default ViewModal;
