import React, { useState } from "react";
import PropTypes from "prop-types";
import FormSection from "../../../common/components/extra/FormSection";
import Input from "../../../common/components/extra/form/Input";
import { useGetCompanyAnnouncements } from "./hooks";
import BaseViewModal from "../../../common/components/layout/modalViewUpdateLayout/BaseViewModal";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUser } from "../../common/slice";
import { createClass, sanitizeTimestamp, sanitizeWords, toReadableFromDate } from "../../../common/utilities/helper";
import { VIEW_MODAL_BASE_CLASS, FIELD } from "./const";
import Button from "../../../common/components/extra/Button";
import ViewContentModal from "./ViewContentModal";
import NotifyButton from "./NotifyButton";

function ViewModal({ open, onClose, onEdit, id }) {
    const [openViewContent, setOpenViewContent] = useState(false);
    const [current, { isLoading }] = useGetCompanyAnnouncements(id);

    const user = useAppSelector(selectUser);
    const timezone = user.Setting.timezone;

    const COMMON_PROPS = { readOnly: true, isLoading };

    return (
        <>
            <BaseViewModal
                styles={{
                    content: {
                        width: "50vw",
                        maxWidth: "45rem",
                        minWidth: "35rem",
                        height: "min-content"
                    }
                }}
                open={open}
                onClose={onClose}
                onEdit={onEdit}
                disableEdit={!current}
                headExtra={<NotifyButton id={current.id} lastPublishedDate={current.last_published_date} />}
            >
                <div className={createClass(" flex column gap-1", VIEW_MODAL_BASE_CLASS)}>
                    <FormSection header="Announcement Details">
                        <Input label="Title" renderValue={sanitizeWords(current?.[FIELD.TITLE])} {...COMMON_PROPS} />
                        <Input label="Type" renderValue={sanitizeWords(current?.[FIELD.TYPE])} {...COMMON_PROPS} />
                        <Input
                            label="Content"
                            renderValue={
                                <>
                                    <Button
                                        onClick={() => setOpenViewContent(true)}
                                        options={{ style: { textAlign: "right", paddingRight: 0, textDecoration: "underline" } }}
                                        mini
                                        small
                                        transparent
                                    >
                                        View
                                    </Button>
                                </>
                            }
                            {...COMMON_PROPS}
                        />
                        <Input
                            label="Description"
                            renderValue={<p style={{ marginLeft: "2rem" }}>{current && sanitizeWords(current?.[FIELD.DESCRIPTION])}</p>}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                    <FormSection header="Record Details">
                        <Input label="Created" renderValue={current && toReadableFromDate(current?.createdAt, timezone)} {...COMMON_PROPS} />
                        <Input label="Updated" renderValue={sanitizeTimestamp(current?.updatedAt, current?.createdAt, timezone)} {...COMMON_PROPS} />
                        <Input
                            tooltip="Last date when employees were notified."
                            label="Last Published"
                            renderValue={toReadableFromDate(current?.last_published_date, timezone)}
                            {...COMMON_PROPS}
                        />
                    </FormSection>
                </div>
            </BaseViewModal>
            {openViewContent && <ViewContentModal open={openViewContent} onClose={() => setOpenViewContent(false)} noOverlay />}
        </>
    );
}

ViewModal.propTypes = {
    open: PropTypes.bool,
    id: PropTypes.any,
    onClose: PropTypes.func,
    onEdit: PropTypes.func
};

export default ViewModal;
