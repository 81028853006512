import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { createPortal } from "react-dom";
import PropTypes from "prop-types";
import ArrowDown from "@mui/icons-material/KeyboardArrowDown";
import ArrowUp from "@mui/icons-material/KeyboardArrowUp";
import ClearIcon from "@mui/icons-material/Clear";
import ClearWithCircle from "@mui/icons-material/Cancel";
import debounce from "lodash/debounce";
import ConditionWrapper from "../form/ConditionWrapper";
import Loader from "../Loader";
import Tag from "../Tag";
import { isScrollAtBottom } from "../../../utilities/helper";
import MiniLoader from "../MiniLoader";
import Tooltip from "../Tooltip";
import Empty from "../Empty";
import { getFixedElementPositionRelativeToParent } from "../Popover";
import useDetectOutsideClick from "../../../hooks/useDetectOutsideClick";

const DEBOUNCE = 300;
const SCROLL_OFFSET = 100;

export const MENU_POSITION = {
    TOP: "top",
    BOTTOM: "bottom"
};

const Options = ({ isSearching, filterOption, searchInput, isOptionsLoading, initializing, options, isSearchable, render, isFilter }) => {
    const newOptions = useMemo(() => {
        if (isSearching) {
            return [];
        }
        const arr = isSearchable
            ? options.filter((option) => {
                  if (filterOption) {
                      return filterOption(option, searchInput);
                  } else {
                      return option.value.toLowerCase().includes(searchInput.toLowerCase());
                  }
              })
            : options;

        return arr.concat(
            isOptionsLoading || initializing
                ? {
                      value: "loading",
                      label: (
                          <div className="flex center w100">
                              <Loader relative style={{ width: "2rem" }} />
                              <span className="fade small-font">Fetching data...</span>
                          </div>
                      ),
                      isDisabled: true
                  }
                : []
        );
    }, [isSearching, isSearchable, options.map((op) => op.value), filterOption, searchInput, isOptionsLoading, initializing]);

    if (!newOptions.length || isSearching) {
        return (
            <Empty
                isLoading={initializing || isOptionsLoading || isSearching}
                style={{ margin: "auto", padding: ".5rem" }}
                iconStyle={{ height: "6rem" }}
                loaderStyle={{ width: "5rem" }}
                loadingMessage={isFilter ? "Fetching..." : "Fetching data..."}
                messageStyle={{ fontSize: "12px" }}
            />
        );
    }
    return newOptions.map(render);
};

const Value = ({ initializing, value, placeholder, onClear, isFilter, filterNoSelectedPlaceholder, fixedValues, isClearable }) => {
    const isFixedValue = (option) => (fixedValues || []).includes(option.value);

    if (initializing) {
        return <></>;
    }

    const createValueClass = (obj = {}) => {
        let str = "";
        if (isFixedValue(obj)) {
            str += "fixed ";
        }
        return str.trim();
    };

    if (value) {
        if (Array.isArray(value)) {
            if (!value.length) {
                return <div className="custom-select__value flex align-center fade semi-bold">{placeholder}</div>;
            }
            return (
                <div className="flex gap-03 custom-select__multi-value wrap" style={(!isClearable && !initializing && { marginRight: "0" }) || {}}>
                    {value.map((v) => (
                        <div key={v.value} className={`custom-select__multi-value__item ${createValueClass(v)}`.trim()}>
                            <Tag className="overflow-hidden" style={{ border: "unset", height: "1.5rem" }} noTooltipIcon>
                                <div className="flex align-center">
                                    <div className="text-ellipsis" style={{ maxWidth: "20rem" }}>
                                        {v.label}
                                    </div>
                                    {!isFixedValue(v) && (
                                        <div className="clear icon-box" onClick={() => onClear(value.filter((opt) => opt.value !== v.value))}>
                                            <ClearIcon className="clear hover-svg" style={{ width: ".8rem" }} />
                                        </div>
                                    )}
                                </div>
                            </Tag>
                        </div>
                    ))}
                </div>
            );
        }
        return (
            <div
                className={`custom-select__value flex align-center semi-bold ${createValueClass(value)}`.trim()}
                style={(!isClearable && !initializing && { marginRight: "0" }) || {}}
            >
                <div className="flex align-center">
                    <div className="text-ellipsis" style={{ whiteSpace: "normal" }}>
                        {value?.label || placeholder}
                    </div>
                </div>
            </div>
        );
    }
    return (
        <div className={`custom-select__value flex align-center ${isFilter ? "small-font bold" : "fade semi-bold"}`.trim()}>
            {(isFilter && filterNoSelectedPlaceholder) || placeholder}
        </div>
    );
};

const Component = ({
    options = [],
    isMulti = false,
    value = isMulti ? [] : null,
    onChange,
    placeholder = "Select...",
    filterOption,
    isSearchable = true,
    isOptionsLoading,
    onMenuScrollToBottom,
    style,
    initializing,
    isDisabled,
    onSearch,
    isFilter,
    filterNoSelectedPlaceholder,
    isClearable,
    fixedValues,
    isPortal,
    label,
    position = MENU_POSITION.BOTTOM,
    offset = 0 // offset away from element
}) => {
    const dropdownRef = useRef(null);
    const dropdownRefContent = useRef(null);

    const [isOpen, setIsOpen] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [focusedIndex, setFocusedIndex] = useState(0);
    const [isMainInputFocused, setIsMainInputFocused] = useState(false);
    const [isSearching, setIsSearching] = useState(false);
    const [portalStyle, setPortalStyle] = useState({});

    const [isClickedOutside] = useDetectOutsideClick(
        dropdownRef,
        () => {},
        (e) => {
            const classList = e.target.classList;
            return ["custom-select-search", "custom-select-option", "arrow-control"].some((className) => classList.contains(className));
        }
    );

    const parentElement = dropdownRef?.current;
    const dropdownContentElement = dropdownRefContent?.current;

    const isFixedValue = (option) => (fixedValues || []).includes(option.value);

    const closeDropdown = () => {
        if (searchInput) {
            setSearchInput("");
            onSearch?.("");
        }
        setIsOpen(false);
    };

    const toggleDropdown = (e) => {
        const target = e.target;
        const classList = target.classList;
        if (!classList.contains("clear")) {
            if (!isDisabled) {
                if (isOpen) {
                    closeDropdown();
                } else setIsOpen(true);
            }
        }
    };

    const handleChange = (options) => {
        // including label causes the UI to lag so we set it to null
        onChange?.(isMulti ? options.map((option) => ({ ...option, label: null })) : options ? { ...options, label: null } : null);
    };

    const handleOptionClick = (option) => {
        if (isMulti) {
            const newValue = (value || []).some((v) => v.value === option.value)
                ? value.filter((v) => v.value !== option.value)
                : [...(value || []), option];
            handleChange(newValue);
        } else {
            handleChange(option);
            closeDropdown();
        }
    };

    const isSelected = (option) => {
        return isMulti ? (value || []).some((v) => v.value === option.value) : value?.value === option.value;
    };

    const handleSearchChange = useCallback(
        (e) => {
            setSearchInput(e.target.value);
            if (isSearching) {
                return;
            }
            if (onSearch) {
                setIsSearching(true);
                debounce(async () => {
                    await onSearch?.(e.target.value);
                    setIsSearching(false);
                }, DEBOUNCE)();
            }
        },
        [onSearch, searchInput]
    );

    const handleKeyDown = (e) => {
        if (!isOpen || isDisabled) {
            return;
        }
        switch (e.key) {
            case "ArrowDown": {
                setFocusedIndex((prev) => Math.min(prev + 1, options.length - 1));
                break;
            }
            case "ArrowUp": {
                setFocusedIndex((prev) => Math.max(prev - 1, 0));
                break;
            }
            case "Enter": {
                const option = options?.[focusedIndex];
                option && !isFixedValue(option) && !option.isDisabled && handleOptionClick(option);
                break;
            }
            case "Escape": {
                closeDropdown();
                break;
            }
            default:
                break;
        }
    };

    const handleClearAll = () => {
        handleChange(isMulti ? [] : null);
    };

    const handleScroll = (e) => {
        if (isScrollAtBottom(e.target, SCROLL_OFFSET) && !isOptionsLoading) {
            onMenuScrollToBottom?.();
        }
    };

    useEffect(() => {
        if (isClickedOutside) {
            closeDropdown();
        }
    }, [isClickedOutside]);

    const updatePortalStyle = useCallback(() => {
        if (!isPortal) {
            return;
        }
        const coordinates = getFixedElementPositionRelativeToParent({
            parentElement,
            fixedElement: dropdownContentElement,
            position,
            offset
        });

        if (coordinates) {
            setPortalStyle({
                position: "absolute",
                top: `${coordinates.top}px`,
                left: `${coordinates.left}px`,
                transform: "unset",
                bottom: "unset",
                width: parentElement.getBoundingClientRect().width
            });
        }
    }, [isOpen, isPortal, position, parentElement]);

    useEffect(() => {
        parentElement && isPortal && updatePortalStyle();
    }, [parentElement]);

    useEffect(() => {
        if (isPortal && isOpen && parentElement) {
            setTimeout(() => {
                updatePortalStyle();
            }, 0);
        }
    }, [isOpen, isPortal, position, updatePortalStyle, dropdownContentElement?.getBoundingClientRect()]);

    const showClearableAll = useMemo(() => {
        if (!isClearable || isDisabled) {
            return false;
        }
        if (Array.isArray(value)) {
            if (value.some(isFixedValue)) {
                return false;
            }
            return !!value.length;
        }
        return !!value;
    }, [value, isClearable, isDisabled]);

    const parentClass = useMemo(() => {
        let str = "custom-select ";
        if (isMainInputFocused) {
            str += "custom-select--focused ";
        }
        if (isDisabled) {
            str += "custom-select--disabled ";
        }
        return str.trim();
    }, [isMainInputFocused]);

    const createItemClass = (index, option) => {
        let str = "custom-select-option ";
        if (isSelected(option)) {
            str += "custom-select-option--selected ";
        }
        if (index === focusedIndex) {
            str += "custom-select-option--focused ";
        }
        if (option.isDisabled) {
            str += "custom-select-option--disabled ";
        }
        if (value && (isFixedValue(option) && Array.isArray(value) ? value.some((opt) => opt.value == option.value) : value.value == option.value)) {
            str += "custom-select-option--fixed ";
        }
        return str.trim();
    };

    const createDropdownElement = () => {
        if (!isOpen) {
            return null;
        }
        return (
            <div
                ref={dropdownRefContent}
                className={`custom-select-dropdown ${!isPortal ? `custom-select-dropdown--${position}` : ""}`.trim()}
                style={isPortal ? { width: dropdownRef?.current?.getBoundingClientRect()?.width || "auto", ...portalStyle } : {}}
            >
                {isSearchable && (
                    <input
                        type="text"
                        className={`custom-select-search ${!label ? "small-font" : ""}`}
                        value={searchInput}
                        onChange={handleSearchChange}
                        onKeyDown={handleKeyDown}
                        placeholder="Search..."
                        autoFocus
                    />
                )}
                <ul className="custom-select-options" onKeyDown={handleKeyDown} onScroll={handleScroll}>
                    <Options
                        isSearching={isSearching}
                        filterOption={filterOption}
                        searchInput={searchInput}
                        isOptionsLoading={isOptionsLoading}
                        initializing={initializing}
                        options={options}
                        isSearchable={isSearchable}
                        isFilter={isFilter}
                        render={(option, index) => (
                            <li
                                key={option.value}
                                className={createItemClass(index, option)}
                                onClick={() => !option.isDisabled && !isFixedValue(option) && handleOptionClick(option)}
                                onMouseEnter={() => setFocusedIndex(index)}
                            >
                                {option.label}
                            </li>
                        )}
                    />
                </ul>
            </div>
        );
    };

    return (
        <div ref={dropdownRef} className={parentClass} style={{ ...style, position: "relative" }}>
            <div
                className="custom-select-control"
                onClick={toggleDropdown}
                tabIndex={0}
                onKeyDown={handleKeyDown}
                onFocus={() => setIsMainInputFocused(true)}
                onBlur={() => setIsMainInputFocused(false)}
            >
                <Value
                    value={value}
                    placeholder={placeholder}
                    onClear={handleChange}
                    initializing={initializing}
                    isFilter={isFilter}
                    filterNoSelectedPlaceholder={filterNoSelectedPlaceholder}
                    fixedValues={fixedValues}
                    isClearable={isClearable}
                />
                {showClearableAll && (
                    <div className="indicator-clear clear" onClick={handleClearAll}>
                        <ClearWithCircle className="danger-color hover-svg clear" />
                    </div>
                )}
                {initializing && (
                    <div className="indicator-loading">
                        <MiniLoader show />
                    </div>
                )}
                <div className="after-extra">
                    <span className="indicator-separator" style={!isFilter ? { height: ".9rem" } : {}}></span>
                    <span className="dropdown-arrow">{isOpen ? <ArrowUp className="arrow-control" /> : <ArrowDown className="arrow-control" />}</span>
                </div>
            </div>
            {isPortal ? createPortal(createDropdownElement(), document.body) : createDropdownElement()}
        </div>
    );
};

const CustomSelect = ({
    warning,
    error,
    subtext,
    useSubTextStyle,
    errorProps,
    warningProps,
    wrapperStyle,
    parentStyle,
    readOnly,
    noRequiredInput,
    label,
    tooltip,
    isDisabled,
    noborder,
    ...rest
}) => {
    const classname = useMemo(() => {
        let cn = "custom-select-wrapper w100 ";
        if (label) {
            cn += "outlined right ";
        }
        if (noborder) {
            cn += "noborder ";
        }
        if (readOnly) {
            cn += "readonly disabled ";
        }
        if (isDisabled) {
            cn += "disabled ";
        }
        if (rest.isFilter) {
            cn += "filter ";
        }
        return cn.trim();
    }, [isDisabled, readOnly, noborder, label]);

    return (
        <ConditionWrapper
            warnings={warning}
            errors={error}
            subtext={subtext}
            useSubTextStyle={useSubTextStyle}
            errorProps={errorProps}
            warningProps={warningProps}
            style={{ ...(wrapperStyle || {}), width: "100%" }}
        >
            <div className={classname} style={{ ...(parentStyle || {}) }}>
                {label && (
                    <div className="label" style={{ gap: ".2rem", whiteSpace: "nowrap" }}>
                        <span>
                            {label}
                            {rest.required && !readOnly && !isDisabled ? <span className="danger-color bold">*</span> : ""}
                        </span>
                        {tooltip && <Tooltip message={tooltip} className="flex" isIcon />}
                    </div>
                )}

                <Component {...rest} label={label} isDisabled={isDisabled} isOptionsLoading={rest.isOptionsLoading} />
                {!noRequiredInput && !isDisabled && (
                    <input
                        className="hidden-input"
                        required={rest.required}
                        onChange={rest.onChange}
                        value={Array.isArray(rest.value) ? (rest.value.length ? "sample" : "") : rest.value?.value || ""}
                    />
                )}
            </div>
        </ConditionWrapper>
    );
};

export default CustomSelect;

const ComponentProps = {
    label: PropTypes.any,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        })
    ),
    isMulti: PropTypes.bool,
    value: PropTypes.oneOfType([
        PropTypes.arrayOf(
            PropTypes.shape({
                value: PropTypes.any.isRequired,
                label: PropTypes.any.isRequired
            })
        ),
        PropTypes.shape({
            value: PropTypes.any.isRequired,
            label: PropTypes.any.isRequired
        })
    ]),
    onChange: PropTypes.func.isRequired,
    placeholder: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    isSearchable: PropTypes.bool,
    isOptionsLoading: PropTypes.bool,
    initializing: PropTypes.bool,
    filterOption: PropTypes.func,
    style: PropTypes.object,
    onMenuScrollToBottom: PropTypes.func,
    noRequiredInput: PropTypes.bool,
    isDisabled: PropTypes.bool,
    required: PropTypes.bool,
    onSearch: PropTypes.func,
    isFilter: PropTypes.bool,
    filterNoSelectedPlaceholder: PropTypes.any,
    isClearable: PropTypes.bool,
    isPortal: PropTypes.bool,
    fixedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])),
    position: PropTypes.oneOf(Object.values(MENU_POSITION)),
    offset: PropTypes.number
};

const WrapperProps = {
    subtext: PropTypes.shape({
        style: PropTypes.object,
        className: PropTypes.string,
        message: PropTypes.any,
        hide: PropTypes.bool
    }),
    warning: PropTypes.array,
    error: PropTypes.array,
    errorProps: PropTypes.object,
    warningProps: PropTypes.object,
    wrapperStyle: PropTypes.object,
    useSubTextStyle: PropTypes.bool
};

Component.propTypes = ComponentProps;

CustomSelect.propTypes = {
    ...WrapperProps,
    ...ComponentProps
};

Value.propTypes = {
    initializing: PropTypes.bool,
    isClearable: PropTypes.bool,
    value: PropTypes.any,
    placeholder: PropTypes.any,
    onClear: PropTypes.func,
    isFilter: PropTypes.bool,
    filterNoSelectedPlaceholder: PropTypes.any,
    fixedValues: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number]))
};

Options.propTypes = {
    isSearching: PropTypes.bool,
    searchInput: PropTypes.string,
    render: PropTypes.func,
    filterOption: PropTypes.func,
    isOptionsLoading: PropTypes.bool,
    initializing: PropTypes.bool,
    options: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.any,
            label: PropTypes.any
        })
    ),
    isSearchable: PropTypes.bool,
    isFilter: PropTypes.bool
};
