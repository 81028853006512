import React from "react";
import PropTypes from "prop-types";
import { ReactComponent as CheckCircleIcon } from "../../../assets/images/check-circle-fill.svg";
import { CURRENCIES, PAYMENT_STATUS, PAYMENT_TYPE, RECURRENCE } from "../../../common/utilities/const";
import { sanitizeWords, toProperMoneyFormat, toTimeWithTimeZone } from "../../../common/utilities/helper";

function Receipt({ data = { subscription: {}, payment: {}, companySubscription: {} }, user }) {
    const createRow = (label, value) => (
        <div className="flex gap-05" style={{ justifyContent: "space-between" }}>
            <span className="fade">{label}:</span>
            <span
                className="bold"
                style={{
                    maxWidth: "10rem",
                    textAlign: "right",
                    overflowWrap: "break-word"
                }}
            >
                {value}
            </span>
        </div>
    );

    const toProperDate = (date) => toTimeWithTimeZone(date, user.Setting.timezone);

    return (
        <div className="tk-receipt flex column gap-1 center w100">
            <div className="flex column gap-1 center">
                <CheckCircleIcon className="check" />
                <h3>Payment Successful</h3>
                <h2>{toProperMoneyFormat(data.payment?.amount || 0, data.payment?.currency)}</h2>
                <div className="current-plan">
                    <span>Your current plan is</span>
                    <h2 className="primary-color" style={{ textTransform: "uppercase" }}>
                        {data.subscription?.title}
                    </h2>
                </div>
                <p className="bold" style={{ marginTop: 0 }}>
                    You can now add up to {data.subscription?.employee_limit} users. You may need to manually renew your subscription.
                </p>
            </div>
            <div className="line"></div>
            <div className="flex column gap-1 center w100">
                <div className="flex column gap-05 w100">
                    {createRow("Ref #", data.payment?.ref_number)}
                    {createRow("Transaction Ref #", data.payment?.trans_ref_number)}
                    {createRow("Date", toProperDate(data.payment?.paid_date).format("YYYY-MM-DD"))}
                    {createRow("Time", toProperDate(data.payment?.paid_date).format("hh:mm A"))}
                    {createRow("Payment Method", sanitizeWords(data.payment?.type))}
                </div>
            </div>
            <div className="line"></div>
            <div className="flex column gap-1 center w100">
                <div className="flex column gap-05 w100">
                    {createRow("Name", sanitizeWords(user.name))}
                    {createRow("Email", user.email)}
                    {createRow("Expires On", toProperDate(data.companySubscription?.expiry_date).format("YYYY-MM-DD hh:mm A"))}
                </div>
            </div>
        </div>
    );
}

export default Receipt;

Receipt.propTypes = {
    id: PropTypes.string,
    data: PropTypes.shape({
        subscription: PropTypes.shape({
            employee_limit: PropTypes.number,
            title: PropTypes.string,
            recurrence: PropTypes.oneOf(Object.values(RECURRENCE)),
            amount: PropTypes.number,
            currency: PropTypes.oneOf(Object.keys(CURRENCIES))
        }),
        payment: PropTypes.shape({
            ref_number: PropTypes.string,
            trans_ref_number: PropTypes.string,
            paid_date: PropTypes.string,
            type: PropTypes.oneOf(Object.values(PAYMENT_TYPE)),
            status: PropTypes.oneOf(Object.values(PAYMENT_STATUS))
        }),
        companySubscription: PropTypes.shape({
            expiry_date: PropTypes.string
        })
    }),
    user: PropTypes.shape({
        email: PropTypes.string,
        name: PropTypes.string,
        Setting: PropTypes.shape({
            timezone: PropTypes.string
        })
    })
};
