import { useEffect, useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { createToast, TOAST_TYPE, toLocalDateToOtherTimezone } from "../utilities/helper";
import { useAppDispatch, useAppSelector } from "./reduxHooks";
import { selectUserSetting } from "../../features/common/slice";

export const LOADING_TYPE = {
    FILTERING: "filtering",
    SORTING: "sorting",
    SEARCHING: "searching",
    CHANGING_ROWS: "changingRows",
    CHANGING_PAGE: "changingPage"
};

const DEFAULT_LOADING_ACTIONS = {
    [LOADING_TYPE.FILTERING]: false,
    [LOADING_TYPE.SORTING]: false,
    [LOADING_TYPE.SEARCHING]: false,
    [LOADING_TYPE.CHANGING_ROWS]: false,
    [LOADING_TYPE.CHANGING_PAGE]: false
};

const usePaginateFetch = (hook, { defaultConfig, config = {}, redux = {}, runOnMount, onMountConfig, queryParams = {}, newBodyConfig = {} }) => {
    const [initializing, setInitializing] = useState(runOnMount);
    const [loading, setLoading] = useState(runOnMount);
    const [isMounted, setMounted] = useState(false);

    const [loadingActions, setLoadingActions] = useState(DEFAULT_LOADING_ACTIONS);

    const [func, { status }] = hook(config);

    const dispatch = useAppDispatch();

    const data = useAppSelector(redux.dataSelector);
    const tableConfig = useAppSelector(redux.tableConfigSelector);
    const current = useAppSelector(redux.currentSelector);
    const setting = useAppSelector(selectUserSetting);
    const timezone = setting.timezone;

    const isDoingActions = Object.values(loadingActions).some(Boolean);

    const updateLoadingActions = (newConfig = {}) =>
        setLoadingActions((prev) => ({
            ...prev,
            ...newConfig
        }));

    const resetLoadingActions = () => setLoadingActions(DEFAULT_LOADING_ACTIONS);

    /**
     * @description
     * We need to sanitize the filter dates to exclude time and timezone when sending to backend
     */
    const transformDateFilters = (filterObject = {}) => {
        let temp = cloneDeep(filterObject);
        for (const key in temp) {
            if (Object.prototype.hasOwnProperty.call(temp, key)) {
                const value = temp[key];
                // handle filter that has config key as a date key
                if (value?.config) {
                    value?.config?.to && (temp[key].config.to = toLocalDateToOtherTimezone(value?.config?.to, timezone).format());
                    value?.config?.from && (temp[key].config.from = toLocalDateToOtherTimezone(value?.config?.from, timezone).format());
                }
                // handle filter that has a direct key to
                value?.to && (temp[key].to = toLocalDateToOtherTimezone(value?.to, timezone).format());
                // handle filter that has a direct key from
                value?.from && (temp[key].from = toLocalDateToOtherTimezone(value?.from, timezone).format());
            }
        }
        return temp;
    };

    const fetch = async (fetchConfig = {}, option = {}) => {
        let retval = null;
        if (option[LOADING_TYPE.CHANGING_ROWS] || option[LOADING_TYPE.CHANGING_PAGE]) {
            updateLoadingActions({
                [LOADING_TYPE.CHANGING_ROWS]: option[LOADING_TYPE.CHANGING_ROWS],
                [LOADING_TYPE.CHANGING_PAGE]: option[LOADING_TYPE.CHANGING_PAGE]
            });
        }
        try {
            const body = cloneDeep({
                ...tableConfig,
                ...(newBodyConfig || {}),
                ...(fetchConfig || {}),
                filter: {
                    ...(tableConfig.filter || {}),
                    ...(newBodyConfig.filter || {}),
                    ...(fetchConfig.filter || {})
                }
            });
            let newConfig = body;
            // we need to reset table controls
            if (option.resetTable) {
                newConfig = cloneDeep(defaultConfig);
                newConfig.search = body.search || fetchConfig.search;
                newConfig.filter = { ...body.filter, ...fetchConfig.filter };
                // since we are reseting the table we need this if we have custom keys that we want to send together to the backend
                (option?.includeBodyKeysOnReset || []).forEach((key) => (newConfig[key] = body[key]));
            }

            // remember the pagesize and page
            newConfig.pageSize = body.pageSize;
            newConfig.page <= newConfig.totalPage && (newConfig.page = body.page);
            newConfig.filter = transformDateFilters(body.filter);

            const response = await func({
                body: newConfig,
                ...queryParams,
                ...(option?.queryParams || {})
            });

            if (response.data && response.data.data) {
                const result = response.data.data;
                retval = {
                    data: result.data,
                    tableConfig: {
                        ...newConfig,
                        totalPage: result.totalPage,
                        totalCount: result.totalCount
                    }
                };
                dispatch(redux.setState(retval));
            }
            if (response.error) {
                throw new Error("Failed to fetch data Please try again later.");
            }
        } catch (error) {
            createToast(error.message, TOAST_TYPE.ERROR);
        } finally {
            setLoading(false);
            setInitializing(false);
            updateLoadingActions({
                [LOADING_TYPE.CHANGING_ROWS]: false,
                [LOADING_TYPE.CHANGING_PAGE]: false
            });
        }
        return retval;
    };

    const handleFilter = async (filter, extra = {}, option = {}) => {
        updateLoadingActions({ [LOADING_TYPE.FILTERING]: true });
        return fetch({ filter: filter || {}, ...extra }, { resetTable: true, ...option }).finally(() =>
            updateLoadingActions({ [LOADING_TYPE.FILTERING]: false })
        );
    };

    const handleSearch = async (value = "", extra = {}, option = {}) => {
        updateLoadingActions({ [LOADING_TYPE.SEARCHING]: true });
        return fetch({ search: (value || "").toLowerCase().trim(), ...extra }, { resetTable: true, ...option }).finally(() =>
            updateLoadingActions({ [LOADING_TYPE.SEARCHING]: false })
        );
    };

    const handleSort = async ({ sortBy, order }, extra = {}, option = {}) => {
        updateLoadingActions({ [LOADING_TYPE.SORTING]: true });
        return fetch({ sortBy, order, ...extra }, { resetTable: true, ...option }).finally(() =>
            updateLoadingActions({ [LOADING_TYPE.SORTING]: false })
        );
    };

    const handleResetTableConfig = (extra = {}, option = {}) => {
        return fetch(extra, { resetTable: true, ...option });
    };

    const handleUpdate = (id, newObject = {}) => {
        const newdata = data.map((record) => {
            if (record.id == id) {
                record = {
                    ...record,
                    ...(newObject || {})
                };
            }
            return record;
        });
        const temp = { data: newdata };
        if (current && current.id == newObject.id) {
            temp.current = { ...current, ...newObject };
        }
        dispatch(redux.setState(temp));
    };

    useEffect(() => {
        if (runOnMount && isMounted) {
            if (!data.length) {
                fetch(onMountConfig);
            } else {
                setLoading(false);
                setInitializing(false);
            }
        }
    }, [isMounted]);

    useEffect(() => {
        setMounted(true);
    }, []);

    return [
        fetch,
        (runOnMount && status == "uninitialized") || status == "pending" || loading,
        {
            initializing,
            onFilter: handleFilter,
            onSearch: handleSearch,
            onSort: handleSort,
            onUpdate: handleUpdate,
            resetTableConfig: handleResetTableConfig,
            setLoading,
            data,
            tableConfig,
            updateLoadingActions,
            resetLoadingActions,
            loadingActions,
            isDoingActions
        }
    ];
};

export default usePaginateFetch;
