import React from "react";
import moment from "moment-timezone";
import { EDIT_TYPE, FIELDS, TIME_TYPE } from "./const";
import {
    convertTimeToMs,
    getHoursDiffByMs,
    getTimeDuration,
    isTimeWithinRangeMs,
    msToTimeString,
    normalizeEndTimeMs,
    toProperTimezoneConversion
} from "../../../common/utilities/helper";
import { SCHEDULE_TYPE, SHIFT_TYPE, WORK_HISTORY_SHIFT_STATUS, WORK_HISTORY_TYPE } from "../../../common/utilities/const";
import Tag from "../../../common/components/extra/Tag";
import { isNextDay } from "../employeeWorkShift/helper";

const {
    LATE,
    ON_TIME,
    LATE_SECOND_SHIFT,
    ON_TIME_SECOND_SHIFT,
    UNDERTIME,
    OVERTIME,
    FIRST_SHIFT_DONE,
    SECOND_SHIFT_DONE,
    OVERTIME_DONE,
    OVER_BREAK,
    HALF_DAY
} = WORK_HISTORY_SHIFT_STATUS;

/**
 * @param {import("./types").Form} form
 * @param {String} timezone
 * @returns {Object}
 */
export const getTimeRangeValues = (form = {}) => {
    const formatTime = (time) => moment(time);

    const shiftOne = {
        range: {
            start: (form.timeInOne && formatTime(form.timeInOne)) || null,
            end: (form.timeOutOne && formatTime(form.timeOutOne)) || null
        },
        constraint: {}
    };

    const isShiftOneNextDay = isNextDay({
        startTimeOne: shiftOne.range.start,
        endTimeOne: shiftOne.range.end
    });

    const shiftTwo = {
        range: {
            start: (form.timeInTwo && formatTime(form.timeInTwo)) || null,
            end: (form.timeOutTwo && formatTime(form.timeOutTwo)) || null
        },
        constraint: {
            start: {
                min: (isShiftOneNextDay && shiftOne.range.end) || null
            },
            end: {
                min: (isShiftOneNextDay && form.timeInTwo && formatTime(form.timeInTwo)) || null
            }
        }
    };

    const breakTimeOne = {
        range: {
            start: (form.breakStartOne && formatTime(form.breakStartOne)) || null,
            end: (form.breakEndOne && formatTime(form.breakEndOne)) || null
        },
        constraint: {
            start: {
                min: shiftOne.range.start || null,
                max: shiftOne.range.end || null
            },
            end: {
                min: (form.breakStartOne && formatTime(form.breakStartOne)) || null,
                max: shiftOne.range.end || null
            }
        }
    };

    const breakTimeTwo = {
        range: {
            start: (form.breakStartTwo && formatTime(form.breakStartTwo)) || null,
            end: (form.breakEndTwo && formatTime(form.breakEndTwo)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.start || null,
                max: shiftTwo.range.end || null
            },
            end: {
                min: (form.breakStartTwo && formatTime(form.breakStartTwo)) || null,
                max: shiftTwo.range.end || null
            }
        }
    };

    const overtime = {
        range: {
            start: (form.overTimeStart && formatTime(form.overTimeStart)) || null,
            end: (form.overTimeEnd && formatTime(form.overTimeEnd)) || null
        },
        constraint: {
            start: {
                min: shiftTwo.range.end || shiftOne.range.end || null,
                max: null
            }
        }
    };

    return {
        [TIME_TYPE.SHIFT_ONE]: shiftOne,
        [TIME_TYPE.SHIFT_TWO]: shiftTwo,
        [TIME_TYPE.BREAK_ONE]: breakTimeOne,
        [TIME_TYPE.BREAK_TWO]: breakTimeTwo,
        [TIME_TYPE.OVERTIME]: overtime
    };
};

export const chekIncStatus = (start, end, tz, identifier) => {
    if (!start || !end) return true;
    const currentDay = toProperTimezoneConversion(moment(), tz);
    return !end && start && Math.abs(toProperTimezoneConversion(start, tz).diff(currentDay, "day")) >= identifier;
};

export const createHalfDayInfo = ({ baseShift, newShift, maxBreakDuration, reqShiftHours } = {}) => {
    const REQ_SHIFT_HOURS_MS = convertTimeToMs(reqShiftHours, "hours");
    const HALF_REQ_SHIFT_HOURS_MS = REQ_SHIFT_HOURS_MS / 2;

    if (!baseShift.startTime || !baseShift.endTime) {
        return null;
    }

    // convert all to ms
    const baseShiftBreakStart = getTimeDuration(baseShift.breakStart);
    const baseShiftBreakEnd = getTimeDuration(baseShift.breakEnd);

    const newShiftStart = getTimeDuration(newShift.startTime);
    const newShiftEnd = getTimeDuration(newShift.endTime);

    const MAX_BREAK_DURATION_MS = (maxBreakDuration && convertTimeToMs(maxBreakDuration, "hours")) || 0;

    const baseTotalBreakDuration = () => {
        return (
            (baseShiftBreakStart && baseShiftBreakEnd && getHoursDiffByMs(baseShiftBreakStart, baseShiftBreakEnd).msDiff) ||
            MAX_BREAK_DURATION_MS ||
            0
        );
    };
    // get the range between first half and second half
    const isInBetweenHalfDayRange = (durationMs) => {
        // add the half of req hours
        const baseShiftStartWithReqHours = toProperTimezoneConversion(baseShift.startTime).add(HALF_REQ_SHIFT_HOURS_MS, "milliseconds").format();
        const start = getTimeDuration(baseShiftStartWithReqHours);
        // get the end range by adding the start with half req hours to the break duration
        const end = start + baseTotalBreakDuration();
        return durationMs <= end && durationMs >= start;
    };

    const baseShiftStartWithReqHours = toProperTimezoneConversion(baseShift.startTime).add(HALF_REQ_SHIFT_HOURS_MS, "milliseconds").format();
    const start = getTimeDuration(baseShiftStartWithReqHours);
    // get the end range by adding the start with half req hours to the break duration
    const end = start + baseTotalBreakDuration();

    const isTimedInOnHalfDay = isInBetweenHalfDayRange(newShiftStart);
    const isTimedOutOnHalfDay = isInBetweenHalfDayRange(newShiftEnd);

    return {
        isTimedInOnHalfDay,
        isTimedOutOnHalfDay,
        start,
        end,
        readableStart: msToTimeString(start),
        readableEnd: msToTimeString(end),
        isHalfDay: isTimedInOnHalfDay || isTimedOutOnHalfDay
    };
};

/**
 * @param {import("./types").Config} config
 * @param {SHIFT_STATUS} SHIFT_STATUS
 * @returns {Array<string>}
 */
export const calculateShiftStatus = (config, SHIFT_STATUS) => {
    const status = [];
    const { baseShift, newShift, gracePeriod, maxBreakDuration, includeHalfDay, reqShiftHours } = config || {};

    // convert all to ms
    const baseShiftStart = getTimeDuration(baseShift.startTime);
    const baseShiftEnd = getTimeDuration(baseShift.endTime);
    const baseShiftBreakStart = getTimeDuration(baseShift.breakStart);
    const baseShiftBreakEnd = getTimeDuration(baseShift.breakEnd);

    const newShiftStart = getTimeDuration(newShift.startTime);
    const newShiftEnd = getTimeDuration(newShift.endTime);
    const newShiftBreakStart = getTimeDuration(newShift.breakStart);
    const newShiftBreakEnd = getTimeDuration(newShift.breakEnd);

    if (!baseShiftStart && !baseShiftEnd && !baseShiftBreakStart && !baseShiftBreakEnd) {
        return status;
    }

    if (!newShiftStart && !newShiftEnd && !newShiftBreakStart && !newShiftBreakEnd) {
        return status;
    }

    const MAX_BREAK_DURATION_MS = (maxBreakDuration && convertTimeToMs(maxBreakDuration, "hours")) || 0;

    // adjust for nightshift by adding the range when end is less than start
    const normalizedBaseShiftEnd = normalizeEndTimeMs(baseShiftStart, baseShiftEnd);
    const normalizedBaseShiftBreakEnd = normalizeEndTimeMs(baseShiftBreakStart, baseShiftBreakEnd);

    // no abnormalities for flexible time only on time and done
    if (config.isFlexibleTime) {
        status.push(SHIFT_STATUS.ON_TIME);
    } else {
        const isWithBreak = !!baseShift.breakStart && !!baseShift.breakEnd && !maxBreakDuration;
        const isFlexibleBreak = !baseShift.breakStart && !baseShift.breakEnd && !!maxBreakDuration;

        // check if ON_TIME or LATE
        if (newShift.startTime && baseShift.startTime) {
            const offset = 1;
            const lateGPms = convertTimeToMs(gracePeriod.lateGP.value + offset, gracePeriod.lateGP.format);
            const baseShiftStartWithLateGPms = baseShiftStart + lateGPms;

            if (isTimeWithinRangeMs(newShiftStart, baseShiftStartWithLateGPms, baseShiftEnd)) {
                status.push(SHIFT_STATUS.LATE);
            } else {
                status.push(SHIFT_STATUS.ON_TIME);
            }
        }

        // check if UNDERTIME (Early Timeout)
        if (newShift.endTime && baseShift.endTime) {
            const earlyTimeoutGPms = convertTimeToMs(gracePeriod.earlyTimeOutGP.value, gracePeriod.earlyTimeOutGP.format);
            const baseShiftEndWithEarlyTimeOutGPms = normalizedBaseShiftEnd - earlyTimeoutGPms;

            // we use the base start instead with the new end since we dont want the end changing
            const newShiftEndForTimeout = normalizeEndTimeMs(baseShiftStart, newShiftEnd);

            if (
                isTimeWithinRangeMs(newShiftEnd, baseShiftStart, baseShiftEnd) &&
                ((baseShiftEnd > baseShiftStart && newShiftEnd < baseShiftEndWithEarlyTimeOutGPms) ||
                    newShiftEndForTimeout < baseShiftEndWithEarlyTimeOutGPms)
            ) {
                status.push(SHIFT_STATUS.UNDERTIME);
            }
        }

        // check if OVER_BREAK
        if (isWithBreak || isFlexibleBreak) {
            const actualTotalBreakDuration = () => {
                const totalBreakHoursMs =
                    newShiftBreakStart &&
                    newShiftBreakEnd &&
                    (newShiftBreakEnd > newShiftBreakStart ? newShiftBreakEnd - newShiftBreakStart : newShiftBreakEnd + newShiftBreakStart); // bigger start means next day
                return totalBreakHoursMs || 0;
            };

            // for time restricted break
            if (isWithBreak) {
                const overBreakGPms = convertTimeToMs(gracePeriod.overBreakGP.value, gracePeriod.overBreakGP.format);
                const baseShiftBreakEndWithOverBreakGPms = normalizedBaseShiftBreakEnd + overBreakGPms;
                // we use the base break start instead with the new break end since we dont want the end changing
                const newBreakEndForBreak = normalizeEndTimeMs(baseShiftBreakStart, getTimeDuration(newShift.breakEnd));
                if (newBreakEndForBreak > baseShiftBreakEndWithOverBreakGPms) {
                    status.push(SHIFT_STATUS.OVER_BREAK);
                }
            }
            // for flexbility break
            else if (isFlexibleBreak) {
                if (actualTotalBreakDuration() > MAX_BREAK_DURATION_MS) {
                    status.push(SHIFT_STATUS.OVER_BREAK);
                }
            }
        }

        // check if HALF_DAY
        if (includeHalfDay && (status.includes(LATE) || status.includes(UNDERTIME))) {
            const halfdayInfo = createHalfDayInfo({ baseShift, newShift, maxBreakDuration, reqShiftHours });
            if (halfdayInfo?.isHalfDay) {
                status.push(SHIFT_STATUS.HALF_DAY);
            }
        }
    }

    // check if SHIFT is COMPLETED
    if (newShift.startTime && newShift.endTime) {
        status.push(SHIFT_STATUS.COMPLETE);
    }

    return status;
};

/**
 * @param {import("./types").CreateShiftStatus} param
 * @param {String} timezone
 * @returns {Array<string>}
 */
export const createShiftStatus = ({ shiftOne, shiftTwo, overtime }, workShift, gracePeriod) => {
    let statuses = [];
    if (shiftOne) {
        statuses = calculateShiftStatus(
            {
                baseShift: {
                    startTime: workShift.start_time,
                    endTime: workShift.end_time,
                    breakStart: workShift.break_time,
                    breakEnd: workShift.break_end_time
                },
                newShift: {
                    startTime: shiftOne.timeIn,
                    endTime: shiftOne.timeOut,
                    breakStart: shiftOne.breakStart,
                    breakEnd: shiftOne.breakEnd
                },
                gracePeriod: {
                    maxOT: gracePeriod.maxOT,
                    lateGP: gracePeriod.lateGP,
                    overBreakGP: gracePeriod.breakGP,
                    earlyTimeOutGP: gracePeriod.earlyTimeOutGP
                },
                isFlexibleTime: workShift.scheduleType == SCHEDULE_TYPE.FLEXIBLE_TIME,
                maxBreakDuration: workShift.max_break_duration,
                reqShiftHours: workShift.required_shift_time,
                includeHalfDay: workShift.shift_type == SHIFT_TYPE.NORMAL
            },
            {
                COMPLETE: FIRST_SHIFT_DONE,
                ON_TIME: ON_TIME,
                LATE: LATE,
                UNDERTIME: UNDERTIME,
                OVER_BREAK: OVER_BREAK,
                HALF_DAY: HALF_DAY
            }
        );
    }

    if (shiftTwo) {
        statuses = statuses.concat(
            calculateShiftStatus(
                {
                    baseShift: {
                        startTime: workShift.start_time_2,
                        endTime: workShift.end_time_2,
                        breakStart: workShift.break_time_2,
                        breakEnd: workShift.break_end_time_2
                    },
                    newShift: {
                        startTime: shiftTwo.timeIn,
                        endTime: shiftTwo.timeOut,
                        breakStart: shiftTwo.breakStart,
                        breakEnd: shiftTwo.breakEnd
                    },
                    gracePeriod: {
                        maxOT: gracePeriod.maxOT,
                        lateGP: gracePeriod.lateGP,
                        overBreakGP: gracePeriod.breakGP,
                        earlyTimeOutGP: gracePeriod.earlyTimeOutGP
                    },
                    isFlexibleTime: workShift.scheduleType == SCHEDULE_TYPE.FLEXIBLE_TIME,
                    maxBreakDuration: workShift.max_break_duration,
                    reqShiftHours: workShift.required_shift_time
                },
                {
                    COMPLETE: SECOND_SHIFT_DONE,
                    ON_TIME: ON_TIME_SECOND_SHIFT,
                    LATE: LATE_SECOND_SHIFT,
                    UNDERTIME: UNDERTIME,
                    OVER_BREAK: OVER_BREAK,
                    HALF_DAY: HALF_DAY
                }
            )
        );
    }

    if (overtime) {
        if (overtime.start) {
            statuses.push(OVERTIME);
            if (overtime.end) {
                statuses.push(OVERTIME_DONE);
            }
        }
    }

    return statuses;
};

export const getShiftStatus = (shiftStatus, isSplit) => {
    if (!shiftStatus || !Array.isArray(shiftStatus))
        return {
            isOnGoing: false
        };

    const { ON_TIME, LATE, ON_TIME_SECOND_SHIFT, LATE_SECOND_SHIFT, FIRST_SHIFT_DONE, SECOND_SHIFT_DONE, OVERTIME, OVERTIME_DONE } =
        WORK_HISTORY_SHIFT_STATUS;

    const onGoingShiftOne = (shiftStatus.includes(ON_TIME) || shiftStatus.includes(LATE)) && !shiftStatus.includes(FIRST_SHIFT_DONE);
    const onGoingShiftTwo =
        isSplit &&
        (shiftStatus.includes(ON_TIME_SECOND_SHIFT) || shiftStatus.includes(LATE_SECOND_SHIFT)) &&
        !shiftStatus.includes(SECOND_SHIFT_DONE);
    const onGoingOverTime = shiftStatus.includes(OVERTIME) && !shiftStatus.includes(OVERTIME_DONE);

    return {
        isOnGoing: !!(onGoingShiftOne || onGoingShiftTwo || onGoingOverTime)
    };
};

export const createdUpdateStatus = (row, { isRaw = false } = {}) => {
    const isCreatedByAdmin = row.isCreatedByAdmin;
    const isModifiedByAdmin = row.isModifiedByAdmin;
    const submittedForm = row.submittedForm;

    let text = "";
    if (!submittedForm) {
        if (!isCreatedByAdmin && isModifiedByAdmin) {
            text = "Modified";
        }
        if (isCreatedByAdmin) {
            text = "Created";
        }
    } else {
        if (submittedForm.isCreated) {
            text = "Creation Requested";
        } else {
            text = "Update Requested";
        }
    }
    if (isRaw) {
        return text;
    }
    return text && <Tag className="yellow">{text}</Tag>;
};

export const createRecordStatus = (recordStatus = {}) => {
    const temp = {
        label: "",
        color: "",
        tag: "",
        className: ""
    };
    if (recordStatus.isRejected) {
        temp.label = "REJECTED";
        temp.color = "#ffafaf";
        temp.className = "red";
    } else if (!recordStatus.isApproved && recordStatus.isPending) {
        temp.label = "PENDING";
        temp.color = "#FFF4B9";
        temp.className = "yellow";
    } else {
        temp.label = "VERIFIED";
        temp.className = "green";
    }
    temp.tag = createdUpdateStatus(recordStatus, { isRaw: true }).toUpperCase();
    return temp;
};

/**
 * @description
 * Can be use in the future for determining which field is available for update or creation
 */
export const getHistoryUpdateInfo = (editType) => {
    const info = {
        allowAllFields: false,
        allowedToEditFields: [],
        fixedValueFields: []
    };

    switch (editType) {
        case EDIT_TYPE.TIMING:
            info.allowedToEditFields = [
                FIELDS.START_SHIFT_TIME.name,
                FIELDS.END_SHIFT_TIME.name,
                FIELDS.START_BREAK_TIME,
                FIELDS.END_BREAK_TIME,
                FIELDS.START_SHIFT_TIME_TWO.name,
                FIELDS.END_SHIFT_TIME_TWO.name,
                FIELDS.START_BREAK_TIME_TWO,
                FIELDS.END_BREAK_TIME_TWO,
                FIELDS.START_OVERTIME,
                FIELDS.END_OVERTIME
            ];
            break;
        case EDIT_TYPE.OVERTIME:
            info.allowedToEditFields = [FIELDS.START_OVERTIME, FIELDS.END_OVERTIME];
            break;
        default:
            break;
    }

    if (editType == EDIT_TYPE.OT_OFF_DAY) {
        info.fixedValueFields = [{ [FIELDS.TYPE.name]: WORK_HISTORY_TYPE.OT_OFF_DAY }];
    } else if (editType == EDIT_TYPE.TIMING) {
        info.fixedValueFields = [{ [FIELDS.TYPE.name]: WORK_HISTORY_TYPE.NORMAL }];
    } else if (editType == EDIT_TYPE.DEFAULT) {
        info.allowAllFields = true;
    } else if (editType == EDIT_TYPE.READ_ONLY) {
        info.allowAllFields = false;
        info.allowedToEditFields = [];
        info.fixedValueFields = [];
    }
    return info;
};
