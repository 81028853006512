import React, { useMemo } from "react";
import PropTypes from "prop-types";
import Capsule from "../../../common/components/extra/Capsule";
import { WORK_HISTORY_SHIFT_STATUS } from "../../../common/utilities/const";
import { useGetWorkHistoryStatusByRangeAndStatus } from "./hooks";
import { PROGRESS_TYPE } from "./const";

function OvertimeStatus({ statuses, styles = {}, range = {} }) {
    const status = useGetWorkHistoryStatusByRangeAndStatus({ overtime: range, statuses }).overtime;

    const contentClassname = useMemo(() => {
        if (status == PROGRESS_TYPE.ONGOING) {
            return "dark-yellow";
        }
        if (status == PROGRESS_TYPE.INCOMPLETE) {
            return "red";
        }
        if (status == PROGRESS_TYPE.COMPLETED) {
            return "green";
        }
        return "";
    }, [status]);

    if (!status || (!range.start && !range.end)) {
        return "";
    }

    return (
        <Capsule
            title="OT"
            classNames={{
                content: contentClassname
            }}
            styles={{
                parent: styles.parent || {},
                title: {
                    background: "orange",
                    color: "white",
                    minWidth: "2rem",
                    ...(styles.title || {})
                },
                content: styles.content || {}
            }}
            noShadow
            small
        >
            {status}
        </Capsule>
    );
}

export default OvertimeStatus;

OvertimeStatus.propTypes = {
    range: PropTypes.shape({
        start: PropTypes.string,
        end: PropTypes.string
    }),
    styles: PropTypes.shape({
        parent: PropTypes.object,
        title: PropTypes.object,
        content: PropTypes.object
    }),
    statuses: PropTypes.arrayOf(PropTypes.oneOf(Object.values(WORK_HISTORY_SHIFT_STATUS)))
};
