import React, { useState } from "react";
import PropTypes from "prop-types";
import Modal from "../../../common/components/extra/Modal";
import FileRenderer, { FILE_FETCH_TYPE } from "../../../common/components/extra/FileRenderer";
import { UPLOAD_FIELDS, VIEW_MODAL_TYPE } from "./const";
import FormSection from "../../../common/components/extra/FormSection";
import { renderNA, sanitizeWords, toReadablePhone } from "../../../common/utilities/helper";
import Input from "../../../common/components/extra/Input";
import Empty from "../../../common/components/extra/Empty";

const { LICENSE_TRADE_COPY, LICENSE_COMMERCIAL_COPY, CONTACT_DETAILS, ESTABLISHMENT_ID_COPY, CONTRACT_COPY } = VIEW_MODAL_TYPE;

function ViewModalSub({ open, onChange, data, type, nofetch }) {
    const files = [LICENSE_TRADE_COPY, LICENSE_COMMERCIAL_COPY, ESTABLISHMENT_ID_COPY, CONTRACT_COPY];
    const isFileType = files.includes(type);
    const [loading, setLoading] = useState(!!isFileType);

    return (() => {
        let children,
            title,
            style = { minWidth: "20rem" };
        switch (type) {
            case LICENSE_TRADE_COPY: {
                title = UPLOAD_FIELDS.LICENSE_TRADE.label;
                children = (
                    <div className="tk-companies__modal-content-view__file">
                        <FileRenderer
                            title={title}
                            onLoading={(bool) => bool != loading && setLoading(bool)}
                            filename={data.filename}
                            src={data.src}
                            fetchType={FILE_FETCH_TYPE.COMPANY}
                            paths={[data.id].filter(Boolean)}
                            nofetch={nofetch}
                            emptyRender={
                                <Empty
                                    style={{ margin: "auto" }}
                                    messageStyle={{ textAlign: "center", whiteSpace: "normal" }}
                                    loadingMessage="Getting File..."
                                    isLoading={loading}
                                    noicon={!loading}
                                    message={<p className="fade">No {title} uploaded.</p>}
                                />
                            }
                            useViewer
                        />
                    </div>
                );
                break;
            }
            case LICENSE_COMMERCIAL_COPY: {
                title = UPLOAD_FIELDS.LICENSE_COMMERCIAL.label;
                children = (
                    <div className="tk-companies__modal-content-view__file">
                        <FileRenderer
                            title={title}
                            onLoading={(bool) => bool != loading && setLoading(bool)}
                            filename={data.filename}
                            src={data.src}
                            fetchType={FILE_FETCH_TYPE.COMPANY}
                            paths={[data.id].filter(Boolean)}
                            nofetch={nofetch}
                            emptyRender={
                                <Empty
                                    style={{ margin: "auto" }}
                                    messageStyle={{ textAlign: "center", whiteSpace: "normal" }}
                                    loadingMessage="Getting File..."
                                    isLoading={loading}
                                    noicon={!loading}
                                    message={<p className="fade">No {title} uploaded.</p>}
                                />
                            }
                            useViewer
                        />
                    </div>
                );
                break;
            }
            case ESTABLISHMENT_ID_COPY: {
                title = UPLOAD_FIELDS.ESTABLISHMENT_ID.label;
                children = (
                    <div className="tk-companies__modal-content-view__file">
                        <FileRenderer
                            title={title}
                            onLoading={(bool) => bool != loading && setLoading(bool)}
                            filename={data.filename}
                            src={data.src}
                            fetchType={FILE_FETCH_TYPE.COMPANY}
                            paths={[data.id].filter(Boolean)}
                            nofetch={nofetch}
                            emptyRender={
                                <Empty
                                    style={{ margin: "auto" }}
                                    messageStyle={{ textAlign: "center", whiteSpace: "normal" }}
                                    loadingMessage="Getting File..."
                                    isLoading={loading}
                                    noicon={!loading}
                                    message={<p className="fade">No {title} uploaded.</p>}
                                />
                            }
                            useViewer
                        />
                    </div>
                );
                break;
            }
            case CONTRACT_COPY: {
                title = UPLOAD_FIELDS.COMPANY_CONTRACT.label;
                children = (
                    <div className="tk-companies__modal-content-view__file">
                        <FileRenderer
                            title={title}
                            onLoading={(bool) => bool != loading && setLoading(bool)}
                            filename={data.filename}
                            src={data.src}
                            fetchType={FILE_FETCH_TYPE.COMPANY}
                            paths={[data.id].filter(Boolean)}
                            nofetch={nofetch}
                            emptyRender={
                                <Empty
                                    style={{ margin: "auto" }}
                                    messageStyle={{ textAlign: "center", whiteSpace: "normal" }}
                                    loadingMessage="Getting File..."
                                    isLoading={loading}
                                    noicon={!loading}
                                    message={<p className="fade">No {title} uploaded.</p>}
                                />
                            }
                            useViewer
                        />
                    </div>
                );
                break;
            }
            case CONTACT_DETAILS: {
                title = "Contact Details";
                children = (
                    <FormSection className="tk-companies__modal-content-view__contact-details" style={{ minWidth: "20rem" }} nohead>
                        <Input
                            label="Name"
                            renderValue={<span className="semi-bold">{sanitizeWords(data.contact_name) || renderNA()}</span>}
                            readOnly
                        />
                        <Input
                            label="Email"
                            renderValue={<span className="semi-bold">{sanitizeWords(data.contact_email) || renderNA()}</span>}
                            readOnly
                        />
                        <Input
                            label="Number"
                            renderValue={<span className="semi-bold">{toReadablePhone(data.contact_number) || renderNA()}</span>}
                            readOnly
                        />
                        <Input
                            label="Position"
                            renderValue={<span className="semi-bold">{sanitizeWords(data.contact_position) || renderNA()}</span>}
                            readOnly
                        />
                    </FormSection>
                );
                break;
            }
            default:
                break;
        }
        return (
            <Modal title={title} open={open} onChange={onChange} styles={{ title: { textAlign: "left" }, content: style }} small>
                <div className="tk-companies__modal-content-view" style={{ padding: "1rem", overflow: "auto" }}>
                    {children}
                </div>
            </Modal>
        );
    })();
}

ViewModalSub.propTypes = {
    open: PropTypes.bool,
    data: PropTypes.shape({
        filename: PropTypes.string,
        src: PropTypes.string,
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        title: PropTypes.string
    }),
    onChange: PropTypes.func,
    type: PropTypes.oneOf(Object.values(VIEW_MODAL_TYPE)),
    nofetch: PropTypes.bool
};

export default ViewModalSub;
