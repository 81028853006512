import React from "react";
import PropTypes from "prop-types";
import { SCHEDULE_TYPE } from "../../../common/utilities/const";
import { useAppSelector } from "../../../common/hooks/reduxHooks";
import { selectUserSetting } from "../../common/slice";

function WorkshiftReference({ info }) {
    const setting = useAppSelector(selectUserSetting);
    const mobileSetting = setting.mobile_app;
    const lateGP = mobileSetting.defaultLateGracePeriod;
    const undertimeGP = mobileSetting.earlyTimeOut;
    const overBreakGP = mobileSetting.breakGracePeriod;

    return (
        <>
            <div className="flex gap-05 small-font wrap">
                <ul className="flex column gap-05 small-font">
                    <li className="flex gap-05 wrap">
                        Name:
                        <span className="bold primary-color">{info.name}</span>
                    </li>
                    <li className="flex gap-05 wrap">
                        Shift Type: <span className="primary-color bold">{info.type}</span>
                    </li>
                    <li className="flex gap-05 wrap">
                        Required Shift: <span className="primary-color bold">{info.reqShiftHours}</span>
                    </li>
                    {!info.hasBreakOne && !info.hasBreakTwo && (
                        <li className="flex gap-05 wrap">
                            Max Break Duration: <span className="primary-color bold">{info.maxBreakDuration}</span>
                        </li>
                    )}
                    <li className="flex gap-05 wrap">
                        Max OT: <span className="primary-color bold">{info.maxOverTime}</span>
                    </li>
                    <li className="flex gap-05 wrap">
                        Schedule Type: <span className="primary-color bold">{info.schedType}</span>
                    </li>
                </ul>
                <ul className="flex column gap-05 small-font">
                    <li className="flex column gap-05">
                        {info.isSplit ? "Shift One" : "Base Shift"}
                        <ul className="indent-1 flex column gap-03">
                            <li className="has-list-style indent-1">
                                Time : <span className="primary-color bold">{info.shiftOneTime}</span>
                            </li>
                            <li className="has-list-style indent-1">
                                Break: <span className="primary-color bold">{info.shiftOneBreak}</span>
                            </li>
                        </ul>
                    </li>
                    {info.isSplit && (
                        <li className="flex column gap-05">
                            Shift Two
                            <ul className="indent-1 flex column gap-03">
                                <li className="has-list-style indent-1">
                                    Time: <span className="primary-color bold">{info.shiftTwoTime}</span>
                                </li>
                                <li className="has-list-style indent-1">
                                    Break: <span className="primary-color bold">{info.shiftTwoBreak}</span>
                                </li>
                            </ul>
                        </li>
                    )}

                    {!info.isSplit && info.halfdayInfo && (
                        <li className="flex column gap-05">
                            <span>Half Day Range</span>
                            <ul className="indent-1">
                                <li>
                                    Between{" "}
                                    <strong>
                                        {info.halfdayInfo.readableStart} - {info.halfdayInfo.readableEnd}
                                    </strong>
                                </li>
                            </ul>
                        </li>
                    )}
                </ul>
                <ul className="flex column gap-05 small-font">
                    <li className="flex column gap-05">
                        <span>Grace Period</span>
                        <ul className="indent-1 flex column gap-03">
                            <li className="has-list-style indent-1">
                                Late : <span className="primary-color bold">{lateGP} min</span>
                            </li>
                            <li className="has-list-style indent-1">
                                Undertime: <span className="primary-color bold">{undertimeGP} min</span>
                            </li>
                            <li className="has-list-style indent-1">
                                Over Break: <span className="primary-color bold">{overBreakGP} min</span>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
            {info.schedType == SCHEDULE_TYPE.FLEXIBLE_TIME && (
                <p className="small-font" style={{ marginBottom: 0, marginTop: "1rem" }}>
                    Notice: Shift status will always be <strong className="xs-font">ON-TIME</strong> with no abnormalities{" "}
                    <strong className="xs-font">(HD, OB, UT)</strong> because the {`record's`} schedule type is{" "}
                    <strong className="xs-font">FLEXIBLE TIME</strong>.
                </p>
            )}
        </>
    );
}

export default WorkshiftReference;

WorkshiftReference.propTypes = {
    info: PropTypes.shape({
        name: PropTypes.string,
        type: PropTypes.any,
        reqShiftHours: PropTypes.any,
        hasBreakOne: PropTypes.string,
        hasBreakTwo: PropTypes.string,
        maxBreakDuration: PropTypes.any,
        maxOverTime: PropTypes.any,
        schedType: PropTypes.any,
        isSplit: PropTypes.bool,
        shiftOneTime: PropTypes.any,
        shiftOneBreak: PropTypes.any,
        shiftTwoTime: PropTypes.any,
        shiftTwoBreak: PropTypes.any,
        halfdayInfo: PropTypes.shape({
            readableStart: PropTypes.string,
            readableEnd: PropTypes.string
        })
    })
};
